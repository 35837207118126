


<div class="modal-header">
    <h4 class="modal-title pull-left">Unsync</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.close(false);">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <div class="form-group mb-0">
                <p class="mb-1">Select a unsync strategy</p>
                <input class="pr-2" type="radio" name="objectiveSelect" [value]="2" [(ngModel)]="undo.option"
                    (change)="onChangeRollBackOption()" /> All Transactions
                <input class="pr-4 ml-2 pl-2" type="radio" name="objectiveSelect" [value]="1"
                    [(ngModel)]="undo.option" (change)="onChangeRollBackOption()" /> Date Range
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="undo.option == 1">
        <div class="col">
            <label> Start Date </label>
            <input class="form-control" type="date" [disabled]="undo.all" name="Start Date"
                [(ngModel)]="undo.startDate" />
        </div>
        <div class="col">
            <label> End Date </label>
            <input class="form-control" type="date" [disabled]="undo.all" name="Start Date"
                [(ngModel)]="undo.endDate" />
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <div class="alert alert-danger" *ngIf="undo.option === 1">
                This option will unsync all the transactions pushed via the app sync for the selected date
                range. This action is not reversible. The changes will affect {{global._appName}}  the
                app.
            </div>
            <div class="alert alert-danger" *ngIf="undo.option === 2">
                This option will unsync all the transactions pushed via the app sync. This action is not
                reversible. This action is not reversible. The changes will affect {{global._appName}}  the
                app.
            </div>
            <br />
            <span class="text-danger" *ngIf="undoError !== null">{{undoError}}</span>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <p>Please enter memo in the text box below.</p>
        <div class="form-group">
            <input class="form-control" placeholder="memo" type="text" [(ngModel)]="undo.memo">
        </div>
        </div>

    </div>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-primary" (click)="undoSync(warningBeforUndo)"
        *ngIf="!isDisableUndoButton">Undo</button>
    <button type="button" class="btn btn-hr-min btn-lg " (click)="activeModal.close(false);">close</button>
</div>