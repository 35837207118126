import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { GlobalService } from '../../_services/global.service';
@Injectable({
  providedIn: 'root'
})


export class BalanceHistoryService {

  private walletTypes = {
    0 : 'not classified',
    1 : 'spot',
    2 : 'margin',
    3 : 'futures'
  };
    constructor(private global: GlobalService) { }

    public makeJobRequest(portfolio_id: number) {
      return this.global.httpPOST('make_job_request', {
        portfolio_id,
        job_id : 1
      })
    }

    public getMoreInfo(portfolio_id: number, date: string, time: string, cryptoId: number, filter= null) {

      const params = {
        'cryptoId': cryptoId,
        'portfolioId': portfolio_id,
        'date': date,
        time
      };

      if (filter) {

        if (filter.wallets.length > 0) {
          params['wallets'] = filter.wallets;
        }

        if (filter.exchanges.length > 0) {
          params['exchanges'] = filter.exchanges;
        }

        if (filter.virtualWallets.length > 0) {
          params['virtualWallets'] = filter.virtualWallets;
        }
      }

        return this.global.httpPOST('get_balance_history_more_info', params);
    }

    public getBalanceConnectionBywallet(portfolioId: number, date: string, time: string, type: number, source: number) {
      return this.global.httpGET('get_balance_history_details_by_connection_wallets', {
        portfolioId: portfolioId,
        date: date,
        time,
        type,
        source
      });
    }

    public removeBalanceHistory(portfolioId: number, date: string, time: string) {

      return this.global.httpPOST('remove_balance_history', {
        portfolioId: portfolioId,
        date: date,
        time
      });
    }

    public getBalanceHistoryCount(portfolioId: number,  filter) {

      const params = {
        portfolioId,
      };

      if (filter) {
        if (filter.fromDate !== null) {
          params['fromDate'] = filter.fromDate;
        }

        if (filter.toDate !== null) {
          params['toDate'] = filter.toDate;
        }

        if (filter.wallets.length > 0) {
          params['wallets'] = filter.wallets;
        }

        if (filter.exchanges.length > 0) {
          params['exchanges'] = filter.exchanges;
        }

        if (filter.virtualWallets.length > 0) {
          params['virtualWallets'] = filter.virtualWallets;
        }
      }

      return this.global.httpPOST('get_balance_history_count', params);
    }
    public getBalanceHistory(portfolioId: number, page: number = 1, filter= null) {
      const params = {
        portfolioId,
        page
      };

      if (filter) {
        if (filter.fromDate !== null) {
          params['fromDate'] = filter.fromDate;
        }

        if (filter.toDate !== null) {
          params['toDate'] = filter.toDate;
        }

        if (filter.wallets.length > 0) {
          params['wallets'] = filter.wallets;
        }

        if (filter.exchanges.length > 0) {
          params['exchanges'] = filter.exchanges;
        }

        if (filter.virtualWallets.length > 0) {
          params['virtualWallets'] = filter.virtualWallets;
        }
      }


      return this.global.httpPOST('get_balance_history', params);
    }

    public getDerivatives(portfolioId: number, date: string, time: string, type, sourceId) {
      return this.global.httpGET('get_balance_history_derivatives', {
        portfolioId: portfolioId,
        date: date,
        time,
        type,
        source: sourceId
      });
    }

    public getBalanceByConnection(portfolioId: number, date: string, time: string, type: number, source: number) {
      return this.global.httpGET('get_balance_history_details_by_connection', {
        portfolioId: portfolioId,
        date: date,
        time,
        type,
        source
      });
    }

    public getBalanceHistoryDetails(portfolioId: number, date: string, time, page: number = 1, filter= null) {

      const params = {
        portfolioId: portfolioId,
        date: date,
        time,
        page: page
      };

      if (filter) {

        if (filter.wallets.length > 0) {
          params['wallets'] = filter.wallets;
        }

        if (filter.exchanges.length > 0) {
          params['exchanges'] = filter.exchanges;
        }

        if (filter.virtualWallets.length > 0) {
          params['virtualWallets'] = filter.virtualWallets;
        }
      }

      return this.global.httpPOST('get_balance_history_details', params);
    }


    public getBalanceHistoryIssues(portfolioId: number, date: string, time: string) {
      return this.global.httpGET('get_balance_history_issues', {
        portfolioId: portfolioId,
        date: date,
        time: time
      });
    }

    public getConnections(portfolioId: number, date: string, time: string, filter) {

      const params = {
        portfolioId: portfolioId,
        date: date,
        time
      };

      if (filter) {

        if (filter.wallets.length > 0) {
          params['wallets'] = filter.wallets;
        }

        if (filter.exchanges.length > 0) {
          params['exchanges'] = filter.exchanges;
        }

        if (filter.virtualWallets.length > 0) {
          params['virtualWallets'] = filter.virtualWallets;
        }
      }

      return this.global.httpPOST('get_balance_history_by_connection', params);
    }

    public fetchDerivatives(snapshotDate, derivatives, meta) {
      const derivativesArr = [];
      for (const i in derivatives) {
        const cryptoSymbol = meta.cryptoSymbols [ derivatives[i].crypto_id ].symbol;
        const cryptoName = meta.cryptoSymbols [ derivatives[i].crypto_id ].name;
        const displaySymbol = meta.cryptoSymbols [ derivatives[i].crypto_id ].display_symbol;
        const d_cryptoSymbol = meta.cryptoSymbols [ derivatives[i].d_crypto_id ];
        const d_cryptoImage = meta.cryptoSymbols [ derivatives[i].d_crypto_id ].image;

        if (d_cryptoSymbol === undefined) {

        }
        const currencyInfo = (derivatives[i].fmv_market_code.split('__'));

        let peggedCryptoInfo = null;
        if ( derivatives[i].is_pegged == 1 &&  meta.cryptoSymbols [ derivatives[i].pegged_crypto_id ] ) {
          peggedCryptoInfo = {
            'id' : derivatives[i].pegged_crypto_id,
            'name': meta.cryptoSymbols [ derivatives[i].pegged_crypto_id ].name,
            'symbol': meta.cryptoSymbols [ derivatives[i].pegged_crypto_id ].symbol,
            'image': meta.cryptoSymbols [ derivatives[i].pegged_crypto_id ].image
          }
        }

        const obj = {
          'snapshotDate': snapshotDate,
          'currency': {
            'fiat_symbol': currencyInfo[0],
            'fiat_code' : currencyInfo[1]
          },
          'cryptoInfo': {
            'id' : derivatives[i].crypto_id,
            'name': cryptoName,
            'symbol': cryptoSymbol,
            'image': d_cryptoImage
          },
          'd_cryptoInfo': {
            'id' : derivatives[i].crypto_id,
            'name': d_cryptoSymbol ? d_cryptoSymbol.name : '',
            'symbol': d_cryptoSymbol ? d_cryptoSymbol.symbol : '',
            'image': d_cryptoSymbol ? d_cryptoImage : ''
          },
          'd_side' :  derivatives[i].d_side,
          'd_long_order_size' :  derivatives[i].d_long_order_size,
          'd_short_order_size' :  derivatives[i].d_short_order_size,
          'd_realized_pnl' :  derivatives[i].d_realized_pnl,
          'd_unrealized_pnl' :  derivatives[i].d_unrealized_pnl,
          'd_instrument' :  derivatives[i].d_instrument,
          'd_size' :  derivatives[i].d_size,
          'd_entry_price' : derivatives[i].d_entry_price,
          'fmv_value': derivatives[i].fmv_value,
          'balance': derivatives[i].balance,
          'walletType': this.walletTypes[ derivatives[i].wallet_type ],
          'fiat_value': derivatives[i].fiat_value,
          'is_pegged': derivatives[i].is_pegged,
          'pegged_crypto_info': peggedCryptoInfo,
          'total':  (derivatives[i].d_entry_price * derivatives[i].d_size) * derivatives[i].fmv_value
        };
        derivativesArr.push(obj);

       }

       return derivativesArr;
    }

    public fetchConnectionDetails(details, meta) {
      const detailsArr = [];

      for (const i in details) {
        let cryptoSymbol = null;
        let cryptoName = null;
        let displaySymbol = null;
        let cryptoImage = null;

        if (details[i].crypto_id > 0) {
          cryptoSymbol = meta.cryptoSymbols [ details[i].crypto_id ].symbol;
          cryptoName = meta.cryptoSymbols [ details[i].crypto_id ].name;
          displaySymbol = meta.cryptoSymbols [ details[i].crypto_id ].display_symbol;
          cryptoImage = meta.cryptoSymbols [ details[i].crypto_id ].image;
        } else {
          cryptoSymbol = details[i].temp_symbol
        }
        const source = meta.fmv_sources [ details[i].fmv_source ];

        let peggedCryptoInfo = null;
        if ( details[i].is_pegged == 1 &&  meta.cryptoSymbols [ details[i].pegged_crypto_id ] ) {
          peggedCryptoInfo = {
            'id' : details[i].pegged_crypto_id,
            'name': meta.cryptoSymbols [ details[i].pegged_crypto_id ].name,
            'symbol': meta.cryptoSymbols [ details[i].pegged_crypto_id ].symbol,
            'image': meta.cryptoSymbols [ details[i].pegged_crypto_id ].image
          }
        }

        const obj = {
          'cryptoInfo': {
            'id' : details[i].crypto_id,
            'name': cryptoName,
            'symbol': cryptoSymbol,
            'image': cryptoImage
          },
          'con_name': details[i].connection_name ? details[i].connection_name  : '---',
          'balance': details[i].balance,
          'fiat_value': details[i].fiat_value,
          'is_pegged': details[i].is_pegged,
          'pegged_crypto_info': peggedCryptoInfo,
          'source': source
        };
        detailsArr.push(obj);
      }
      return detailsArr;
    }

    public fetchBalanceHistoryDetails(snapshotDate, details, meta, type= 1) {
      const detailsArr = [];
      console.log(details)
      for (const i in details) {
        let obj = {
            'crypto_id': details[i].crypto_id,
            'temp_symbol': details[i].temp_symbol,
            'snapshotDate': snapshotDate,
            'currency': {
              'fiat_symbol': '-',
              'fiat_code' : '-'
            },
            'cryptoInfo': {
              'id' : '-',
              'name': '-',
              'symbol': '-',
              'image': '-'
            },
          'fmv_value': '-',
          'balance': details[i].balance,
          'fiat_value': '-',
          'is_pegged': details[i].is_pegged,
          'pegged_crypto_info': null
        }
        if (details[i].crypto_id <= 0) {

          const symbol = details[i].temp_symbol

          obj['cryptoInfo']['id'] = details[i].crypto_id,
          obj['cryptoInfo']['name'] = symbol
          obj['cryptoInfo']['symbol'] = symbol

        } else {
          const currencyInfo = (details[i].fmv_market_code.split('__'));

          // let type =  meta.types [ details[i].type ];
          // let source =  meta.fmv_sources [ details[i].fmv_source ];
          const cryptoSymbol = meta.cryptoSymbols [ details[i].crypto_id ].symbol;
          const cryptoName = meta.cryptoSymbols [ details[i].crypto_id ].name;
          const displaySymbol = meta.cryptoSymbols [ details[i].crypto_id ].display_symbol;
          const cryptoImage: any = meta.cryptoSymbols [ details[i].crypto_id ].image;

          let peggedCryptoInfo = null;
          if ( details[i].is_pegged == 1 &&  meta.cryptoSymbols [ details[i].pegged_crypto_id ] ) {
            peggedCryptoInfo = {
              'id' : details[i].pegged_crypto_id,
              'name': meta.cryptoSymbols [ details[i].pegged_crypto_id ].name,
              'symbol': meta.cryptoSymbols [ details[i].pegged_crypto_id ].symbol,
              'image': meta.cryptoSymbols [ details[i].pegged_crypto_id ].image
            }
          }


          obj = {
            'crypto_id': details[i].crypto_id,
            'temp_symbol': details[i].temp_symbol,
            'snapshotDate': snapshotDate,
            'currency': {
              'fiat_symbol': currencyInfo[0],
              'fiat_code' : currencyInfo[1]
            },
            'cryptoInfo': {
              'id' : details[i].crypto_id,
              'name': cryptoName,
              'symbol': cryptoSymbol,
              'image': cryptoImage
            },
            'fmv_value': details[i].fmv_value,
            'balance': details[i].balance,
            'fiat_value': details[i].fiat_value,
            'is_pegged': details[i].is_pegged,
            'pegged_crypto_info': peggedCryptoInfo
          };

        }

        if (type == 2) {
          const source =  meta.fmv_sources [ details[i].fmv_source ];
          obj['isFetching'] = false;
          obj['isExpanded'] = false;
          obj['fmv_source'] = source
          obj['details'] = [];
        }

        detailsArr.push(obj);
       }

       return detailsArr;
    }

    public fetchBalanceHistoryIssues(meta, details) {
      const detailsArr = [];

      const exchangesMap = {};
      const exchangeConnectionMap = {};
      const walletMap = {};

      for (const i in meta.exchanges) {
        exchangesMap[ meta.exchanges[i].id ] =  meta.exchanges[i];
      }

      for (const i in meta.exchangeConnections) {
        exchangeConnectionMap[ meta.exchangeConnections[i].exchange_connection_id ] =  meta.exchangeConnections[i];
      }

      for (const i in meta.wallets) {
        walletMap[ meta.wallets[i].id ] =  meta.wallets[i];
      }

      for (const i in details) {
        let sourceType = null;
        let name = null;
        let symbol = null;
        let asset_id = null;
        let displaySymbol = '';
        if (details[i].source_type == 2) {
          sourceType = 'exchange';
          name = exchangeConnectionMap[ details[i].source_id].name;
          symbol = exchangesMap[ details[i].source_id].symbol;
          asset_id = exchangesMap[ details[i].source_id].exchage_id;
        } else {
          sourceType = 'wallet';
          name = walletMap[ details[i].source_id].name;
          symbol = walletMap[ details[i].source_id].symbol;
          displaySymbol = walletMap[ details[i].source_id].display_symbol;
          asset_id = walletMap[ details[i].source_id].type_id;
        }

        const obj = {
          'asset_id' : asset_id,
          'source_id': details[i].source_id,
          'source_type_id': details[i].source_type,
          'source_type':  sourceType,
          'name' : name,
          'log' : details[i].error_log,
          'symbol': symbol,
          'displaySymbol': displaySymbol
        }

        detailsArr.push(obj);
      }

      return detailsArr;
    }

    public getReconcilationReportData(portfolioId, reportId, page= 1) {

      return this.global.httpGET('reconciliation_data', {
        portfolioId,
        reportId,
        page
      });
    }

    public reconcilationReportAsset(portfolioId, type, sourceId, startDateTime, endDateTime) {

      return this.global.httpGET('reconciliation_data_assets', {
        portfolioId,
        type,
        sourceId,
        startDateTime,
        endDateTime
      });
    }


    public getTxDetails(portfolioId, startDate, endDate, type, sourceId, assetSymbol, offset = 0) {
      const filters =  JSON.parse(JSON.stringify(this.global.userPortfolioSettings.txtable_filters));

      filters['assetSymbols'] = [assetSymbol];
      filters['assetsAll'] = false;
      filters['allWalletsConnect'] = false;
      filters['allExchangesConnect'] = false;
      filters['classifications'] = false;
      filters['outputAddress'] = false;
      filters['tagsAll'] = true;
      filters['exchangeConnected'] = [];
      filters['walletsConnected'] = [];
      filters['virtualWalletsConnected']  = [];
      filters['txTags'] = [];
      filters['type'] = ['all', 'send', 'receive', 'buy', 'sell'];
      filters['deviations'] = null;
      filters['taxability'] = null;

      if (type == 2) {
        filters['exchangeConnected']  = [sourceId];
      } else if (type == 1 || type == 3) {
        filters['walletsConnected']  = [sourceId];
      } else {
        filters['virtualWalletsConnected']  = [sourceId];
      }

      filters['startDate'] = startDate;
      filters['endDate'] = endDate;


      // get_portfolio_transactions_count
      const count = this.global.httpPOST('get_portfolio_transactions_count', {
        portfolioId,
        offset,
        txSort: {
          'date': 'DESC'
        },
        filters: JSON.stringify(filters)
      })
      const txs = this.global.httpPOST('get_portfolio_transactions', {
        portfolioId,
        offset,
        txSort: {
          'date': 'DESC'
        },
        filters: JSON.stringify(filters)
      });

      return forkJoin(count, txs);

    }

    public createReconcilationReport(portfolioId, startDateTime, endDateTime) {

      return this.global.httpPOST('reconciliation_report_create', {
        portfolioId,
        startDateTime,
        endDateTime
      });
    }

    public getReconcilationReports(portfolioId, page= 1) {

      return this.global.httpGET('reconciliation_report', {
        portfolioId,
        page
      });
    }

    public countAssetFlow(portfolioId, startDate, endDate, sourceId, type) {

      const  assetFlowCountDetails = {
        startDate, endDate
      };

      const taxabilityIdentificationDetails = {
        accountsSelected: [],
        walletsSelected: [],
        virtualWalletsSelected: []
      };

      if (type == 1) {
        taxabilityIdentificationDetails.walletsSelected = [sourceId]
      }

      if (type == 2) {
        taxabilityIdentificationDetails.accountsSelected = [sourceId]
      }

      if (type == 3) {
        taxabilityIdentificationDetails.virtualWalletsSelected = [sourceId]
      }

      return this.global.httpPOST('count_asset_flows', {
        portfolioId: portfolioId,
        assetFlowCountDetails: assetFlowCountDetails,
        selectedSources: taxabilityIdentificationDetails
      })

    }


    public resyncFmvData(portfolio_id, snapshot_date, snapshot_time) {

      const params = {
        portfolio_id: portfolio_id,
        snapshot_date: snapshot_date,
        snapshot_time: snapshot_time,
      }

      return this.global.httpPOST('resync_fmv_data', params);
    }

    public getFmvResyncStatus(portfolio_id, snapshot_date, snapshot_time) {
      const params = {
        portfolio_id: portfolio_id,
        snapshot_date: snapshot_date,
        snapshot_time: snapshot_time,
      }

      return this.global.httpGET('resync_fmv_data', params);
    }

    stopSnapstopSync(portfolio_id, id) {
      const params = {
        'id': id,
        portfolio_id: portfolio_id
      }
      return this.global.httpPOST('stop_snapshot_sync', params);
    }

}
