

<div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
        <form class="col-12">
                <div *ngIf="status==0">
                    <div class="row">
                        <div class="col-md-6 offset-md-3 text-center">
                            <br/><br/>
                            <br/>
                            <img class="img-fluid" width="200px" src="/assets/img/logo/logo.png"  alt="">
                            <br/>
                            <br/>
                            <br/>
                            <img class=" d-block img-fluid" src="/assets/img/qb.png"  alt="">
                            <h3 *ngIf="showError">
                                <span class="text-danger">Error! Redirecting...</span> 
                            </h3>
                            <h3 *ngIf="!showError">
                                <img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="30"  class="img-fluid d-inline-block align-top text-center" alt=""> 
                                Finalizing Connection
                            </h3>
                        </div>
                    </div>
                </div>
            
        </form>   
       
    </div>  
</div>