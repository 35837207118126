
<!-- <app-header-guest *ngIf="!global._authStatus"></app-header-guest> -->
<div *ngIf="global.loginDivState==0">
	<div class="">
		<div class="no-gutter">

			<!-- The content half -->
			<div class="">
				<div class=" d-flex align-items-center">

					<!-- Demo content-->
					<div class="container form-signin" (keyup.enter)="login()">
						<div class="row pl-3 pr-4">
							<div class="col">

								<div class="div-signin text-center pt-4 mt-3">
									<a href="#" [routerLink]="['/']">
										<img src='{{global.FE_IMG_URL}}img/logo/logo.png' width="175"  class="d-inline-block align-top text-center mb-0 pb-0" alt="">
									</a>
									<br/>
      								<!-- <a href="https://coinmarketcap.com/currencies/bitcoin/" target="_blank"> <img src="https://cw-images-all.s3.us-east-2.amazonaws.com/img_general/btc100k.png" class="fade-in mt-2" width="150px"></a> -->
								</div>

								<p class="my-3 lead text-center">Sign In</p>

								<div *ngIf="global.appData != null && global.appData.scheduledMaintenance != null" class="alert alert-warning" role="alert">
									Cryptoworth will perform a scheduled maintenance on <span class="text-bold">{{ global.appData.scheduledMaintenance.dateTime }}</span>.
									<br/><br/>
									The system will not be accessible within the time window. Sorry for any inconvenience caused.
								</div>

								<div  *ngIf="errorStatus==401" class="alert alert-danger" role="alert">
									You do not have a registered account in Cryptoworth with this email. Please contact the support team to get access.
								</div>
								<div *ngIf="errorStatus==201" class="alert alert-danger" role="alert">
									Invalid username, password combination. Please check your email and password again.
								</div>
								<div *ngIf="errorStatus==404" class="alert alert-danger" role="alert">
									The username was not found. Please register.
								</div>
								<div *ngIf="errorStatus==211" class="alert alert-danger" role="alert">
									Please enter an username and a password to login.
								</div>
								<div *ngIf="errorStatus==206" class="alert alert-danger" role="alert">
										<b>Account disabled!</b> <br/>
										Common reasons include but not limited to:
										<ul>
											<li>
												Three or more invalid login attempts.
											</li>
											<li>
												Ending of trial period.
											</li>
											<li>
												Inactive payment method.
											</li>
										</ul>
										Please contact the support team to resolve the issue (replies with in 10-15 minutes).
								</div>
								<div *ngIf="errorStatus==100" class="alert alert-danger" role="alert">
									You do not have access to this application.
								</div>

								<div *ngIf="errorStatus==212" class="alert alert-danger" role="alert">
									Unable to login at the moment. please try later
								</div>
								<div class="input-group">
									<div class="input-group-prepend">
									  <span class="input-group-text"><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
									</div>
									<input maxlength="100" type="email" [(ngModel)]="loginDetails.email" (ngModelChange)="loginFormModalChange()" name="loginEmail" class="form-control bl-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email">
								</div>

								<br/>
								<div class="input-group">
									<div class="input-group-prepend">
									  <span class="input-group-text"><i class="fa fa-lock pl-1" aria-hidden="true"></i></span>
									</div>
									<input maxlength="100" type="password" [(ngModel)]="loginDetails.password"  (ngModelChange)="loginFormModalChange()" name="loginPassword" class="form-control bl-0" id="exampleInputPassword1" placeholder="Password">
								</div>

								<div class=" d-flex" *ngIf="errorStatus==201">
									<span class="text-primary pointer" [routerLink]="['/recover']" >Forgot password?</span>
									<!-- <span class="text-primary pointer ml-auto" [routerLink]="['/register']">Create an account</span> -->
								</div>
								<br/>
								<div class="form-group">
									<input type="checkbox" (click)="makeUserLoggedIn()" [(ngModel)]="keepLoggedIn" name="keepLoggedIn" /> &nbsp;Keep me logged-in in this computer
								</div>
								<button type="submit" class="btn btn-primary btn-lg btn-block btn-tall btn-tall" type="button" *ngIf="formState==0"  (click)="login()">Continue</button>
								<button class="btn btn-primary btn-lg btn-block btn-tall btn-tall disabled" type="button" *ngIf="formState==1" >
									<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Continue
								</button>


								<button class="btn btn-lg btn-light btn-block border bg-white" type="submit" (click)="loginWithSocialite(loginProviders.GOOGLE)" *ngIf="formState == 0">
									<img width="40" src="/assets/img/social/google.jpeg">
									Sign in with Google
								</button>

								<button class="btn btn-lg btn-light btn-block border bg-white" type="submit" (click)="loginWithXero()" *ngIf="formState == 0">
									<img width="30" class="m-1 mr-2" src="{{global.IMG_URL}}gateways/xero.png">
									Sign in with Xero
								</button>

								<br/>
								<div class="row">
									<div class="col-md-12">
										<p class="small text-center">By using this application you are agreeing to the <a class="text-primary pointer" href="https://cryptoworth.com/legal/terms" target="_blank">Terms</a>, <a class="text-primary pointer" href="https://cryptoworth.com/legal/disclaimer" target="_blank">Disclaimer</a> and our <a class="text-primary pointer" href="https://cryptoworth.com/legal/privacy" target="_blank">Privacy</a> policy.</p>

									</div>
								</div>
								<br/>
							</div>
						</div>
						<!-- <div class="row">
							<div class="col-lg-10 col-xl-7 mx-auto">

								<hr/>
								<footer-mini></footer-mini>

							</div>
						</div> -->
					</div><!-- End -->

				</div>
			</div><!-- End -->

		</div>
	</div>
</div>

<div *ngIf="global.loginDivState==201">

	<br/><br/><br/><br/><br/>
	<div class="">
		<div class="no-gutter">

			<!-- The content half -->
			<div class="col-md-4 offset-md-4 ">
				<div class=" d-flex align-items-center py-5">

					<!-- Demo content-->
					<div class="container form-signin">
						<div class="row">
							<div class="col">
								<div class="div-signin text-center pt-4">
									<a href="#" [routerLink]="['/']">
										<img src='{{global.FE_IMG_URL}}img/logo/logo.png' width="175"  class="d-inline-block align-top text-center" alt="">
									</a>
								</div>

								<br/>
								<h5 class="mb-4 text-center">Change your password</h5>
								<p> For security reasons, please change the password to access your account. </p>

								<br/>
								<div *ngIf="errorStatus==201" class="alert alert-danger" role="alert">
									Invalid username, password combination. Please check your email and password again.
								</div>

								<label for="inputPassword" class="sr-only">New password</label>
								<input type="password" [(ngModel)]="reLoginDetails.newPassword"  (ngModelChange)="loginFormModalChange()" name="loginPassword" class="form-control" id="exampleInputPassword1" placeholder="Password">
								<br/>

								<label for="inputPassword" class="sr-only">Re-enter new password</label>
								<input type="password" [(ngModel)]="reLoginDetails.newPasswordAgain"  (ngModelChange)="loginFormModalChange()" name="loginPassword" class="form-control" id="exampleInputPassword1" placeholder="Re-enter password">
								<br/>

								<button type="submit" class="btn btn-primary btn-lg btn-block btn-tall btn-tall" type="button" *ngIf="formState==0"  (click)="reLoginWithPasswordChange()">Change Password</button>
								<button class="btn btn-primary btn-lg btn-block btn-tall btn-tall disabled" type="button" *ngIf="formState==1" >
									<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Change Password
								</button>
							</div>
						</div>
						<br/><br/>
						<div class="row">
							<div class="col text-center">
								<a class="text-primary pointer" (click)="global.loginDivState = 0">Go Back</a>
							</div>
						</div>
					</div><!-- End -->

				</div>
			</div><!-- End -->

		</div>
	</div>
</div>

<!-- 2 Factor Auth -->
<div class="text-center"  *ngIf="global.loginDivState==202">
		<br/><br/><br/><br/><br/>
			<form class="form-signin" (keyup.enter)="login()" style="background:#fff">

			<!-- <div class="text-center" >
				<a href="#" [routerLink]="['/']">
					<img src='{{global.FE_IMG_URL}}img/logo_inv.png' width="75" height="75" class="d-inline-block align-top text-center" alt="">
				</a>
			</div> -->
			<br/>
			<div  *ngIf="errorStatus!=206">
				<h1 class="h5 mb-3 font-weight-normal">
					2 Factor Authentication</h1>
					Enter the 6-digit code we sent to your email.
				<br/>
			</div>

			<br/>
			<div *ngIf="errorStatus==203" class="alert alert-danger" role="alert">
				Invalid security code.
			</div>


			<div *ngIf="errorStatus==206" class="alert alert-danger" role="alert">
					<b>Account disabled!</b> <br/>
					We noticed that you attempted to login to your account with the incorrect security code many times. For security reasons, we have disabled your account. To recover your account again, please check your
					mail as we have sent instructions on recovering the account. If you didnt receive any email, please go to <a href="#" [routerLink]="['/recover']" class="" >Forgot password?</a> to recover your account.
			</div>

			<div *ngIf="errorStatus==204" class="alert alert-success" role="alert">
				Security code resent
			</div>

			<div *ngIf="errorStatus==205" class="alert alert-success" role="alert">
				Security code sent to your email address
			</div>

			<div *ngIf="errorStatus!=206">
					<label for="inputEmail" class="sr-only">Security Code</label>
					<input type="number" [(ngModel)]="loginDetails.twofa_code" (ngModelChange)="loginFormModalChange()" name="login2FA" class="form-control" id="example2FA" aria-describedby="emailHelp" placeholder="Security Code">
					<br/>

					<button type="submit" class="btn btn-primary btn-lg btn-block btn-tall" type="button" *ngIf="formState==0" (click)="login()">Submit</button>
					<button class="btn btn-primary btn-lg btn-block btn-tall disabled" type="button" *ngIf="formState==1" >
						<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Submit
					</button>
					<br/>
					<div class="text-center" *ngIf="twoFACounter < 2">
						<a class="text-center" [routerLink]="" (click)="TwoFAResend('email')" *ngIf="!twoFAresend">Send Again</a>
						<span class="text-center"  *ngIf="twoFAresend">Sending...</span>
						<!-- <a [routerLink]="" (click)="TwoFAResend('phone')" class="" >Send Again</a>
						<a [routerLink]="" (click)="TwoFAResend('email')" class=" ml-auto">Send Via Email</a> -->
					</div>
					<div class="text-center" *ngIf="twoFACounter >= 2">
						<span class="text-center" >
							Please check your email for the 2FA Code.
						</span>
					</div>
			</div>

		</form>
		<br/><br/>
</div>

<!-- recover password -->
<div class="text-center"  *ngIf="global.loginDivState==3">
	<br/><br/><br/><br/><br/>
	<div class="container text-center">
		<form class="form-signin" (keyup.enter)="doRecoverEmail()" style="background:#fff">
			<!-- <a href="#" [routerLink]="['/']">
				<img src='{{global.FE_IMG_URL}}img/logo/logo.png' width="175"  class="d-inline-block align-top text-center" alt="">
			</a>
			<br/> -->
			<form *ngIf="!recoverState">
				<br/>
				<h4 class="text-center">Recover Password</h4>
				<br/>
				<div *ngIf="errorStatus==204" class="alert alert-danger" role="alert">
					Invalid email, Please enter your email and try again.
				</div>
				<div *ngIf="errorStatus==205" class="alert alert-danger" role="alert">
					That email was not found in our system. Please check your email or again or register as a new user.
				</div>
				<div *ngIf="errorStatus==206" class="alert alert-danger" role="alert">
					Unable to send 2FA code to your email. Please contact support.
				</div>
				<div class="form-group">
					<input maxlength="100" type="email" [(ngModel)]="recoverEmail" name="recoverEmail" class="form-control" id="recoverEmail" aria-describedby="emailHelp" placeholder="Enter your email">
					<!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
				</div>

				<button type="button" class="btn btn-primary btn-lg btn-block btn-tall" (click)="doRecoverEmail()"  *ngIf="!recovering">Recover</button>
				<button type="button" class="btn btn-primary btn-lg btn-block btn-tall disabled" (click)="doRecoverEmail()"  *ngIf="recovering">Recovering...</button>

			</form>
			<br/>

				<div *ngIf="recoverState">
					<br/>
					<p> We've sent an email to {{recoverEmail}}. Please check your inbox and follow the instructions to reset your password.</p>
				</div>
		</form>
	</div>
</div>


<!-- reset password -->
<div class="container" *ngIf="global.loginDivState==4">
	<br/><br/><br/><br/><br/>
	<div class="row">
		<div class="col-md-4">
		</div>
		<div class="col-md-4">
			<form class="form-signin pt-4 mt-2" (keyup.enter)="doRecoverEmail()" style="background:#fff">

				<div class="text-center" >
					<a href="#" [routerLink]="['/']">
						<img src='{{global.FE_IMG_URL}}img/logo/logo.png' width="175"  class="d-inline-block align-top text-center" alt="">
					</a>
				</div>
				<br/>
				<form *ngIf="!recoverState"  >

					<p *ngIf="recoverURLState==1"> <strong>Invalid URL</strong> The link you are tyring to access is not valid.</p>

					<div *ngIf="recoverURLState==2">
						<br/>
						<h4 class="text-center">Recover Password</h4>
						<br/>
						<div *ngIf="errorStatus==207" class="alert alert-danger" role="alert">
							Please fill all the details below.
						</div>
						<div *ngIf="errorStatus==208" class="alert alert-danger" role="alert">
							The password do not match. Reenter the password again.
						</div>

						<div class="form-group">
							<input type="password" [(ngModel)]="newPassword" name="newPassword" class="form-control" id="newPassword" aria-describedby="emailHelp" placeholder="Enter a new password">
							<!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
						</div>
						<div class="form-group">
							<input type="password" [(ngModel)]="newPasswordAgain" name="newPasswordAgain" class="form-control" id="newPasswordAgain" aria-describedby="emailHelp" placeholder="Enter the password again">
							<!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
						</div>

						<button type="button" class="btn btn-primary btn-lg btn-block btn-tall" (click)="resetPassword()" *ngIf="!resetting">Reset Password</button>
						<button type="button" class="btn btn-primary btn-lg btn-block btn-tall" (click)="resetPassword()" *ngIf="resetting">Resetting...</button>
					</div>

					<p *ngIf="recoverURLState==3">
						<strong>Success</strong> Your password is successfully changed. Please login using your new password.
						<br/>
						<a href="#" [routerLink]="['/login']">Click here to login</a>
					</p>

				</form>

				<div *ngIf="recoverState">
					<p> We've sent an email to {{recoverEmail}}. Please check your inbox and follow instructions to reset your password.</p>
				</div>
			</form>
		</div>
		<div class="col-md-4">
		</div>
	</div>
</div>

<!-- account ban handling-->
<div class="container" *ngIf="global.loginDivState==666 && global._packagePurchaseDetails['status']!=-1">
	<br/><br/>
	<div class="text-center" >
		<a href="#" [routerLink]="['/']">

			<img src='{{global.FE_IMG_URL}}img/logo/logo.png' width="175"  class="d-inline-block align-top text-center" alt="">
		</a>
	</div>
	<br/>
	<div class="container" style="color:#fff; background-color:#d64343;font-size: 15px;" *ngIf="global._isOwner">
		<div class="row d-flex align-items-center">
			<div class="col-md-12 text-md-left mb-4 mb-md-0">
				<div class="p-4">
					<p class="text-white">
						<b><i class="fa fa-exclamation-circle text-white" aria-hidden="true"></i> Account Suspended Temporary.</b> <br/><br/>
						Payment attempt was made on <b><u>{{ global._packagePurchaseDetails['expires_at'] | amDateFormat:'MMM D, Y'}}</u> </b> but it was declined.
						No payments were made with in the grace period:
						<b><u>{{ global._packagePurchaseDetails['expires_at'] | amDateFormat:'MMM D, Y'}}</u> </b>  to <b><u>{{ global._packagePurchaseDetails['grace_expiration_at'] | amDateFormat:'MMM D, Y' }}</u></b>. <br/><br/>

						Hence your account is on a temporary ban untill the payment is made. Your data is <b><u>not lost</u></b> and you will be able to login to the account after the payment is made.
						<span *ngIf="global._packagePolicies['business']['status']"> <b><u>The ban is active for you and all the other company accounts.</u></b></span>
					</p>
				</div>

			</div>
		</div>
	</div>

	<div class="container" style="color:#fff; background-color:#d64343;font-size: 15px;" *ngIf="!global._isOwner">
		<div class="row d-flex align-items-center">
			<div class="col-md-12 text-md-left mb-4 mb-md-0">
				<div class="p-4">
					<p class="text-white">
						<b><i class="fa fa-exclamation-circle text-white" aria-hidden="true"></i> Account Inactive.</b> <br/><br/>
						The account is inactive temporary. Please contact your company administration for more information.
						Your data is <b><u>not lost</u></b> and you will be able to login to the account after the account is active again.
					</p>
				</div>
			</div>
		</div>
	</div>

	<br/>
	<app-re-payment *ngIf="global._isOwner" [login]="true" [loginDetails]="loginDetails"></app-re-payment>
</div>


<!-- account ban handling-->
<div *ngIf="global.loginDivState==666 && global._packagePurchaseDetails['status']==-1">
	<app-restart-membership></app-restart-membership>
</div>

<!-- <footer-mini></footer-mini> -->

<footer class="footer-fixed mt-auto py-3 fade-in-late">
	<div class="text-center">
		<p class="text-center mb-1">Join more industry leaders who streamline accounting with Cryptoworth </p>
		<img style="-webkit-filter: grayscale(100%);opacity: 0.7;" src="/assets/img/customer_logos/customers.png">
		<br/>
	</div>
</footer>


<form [formGroup]="aFormGroup" style="visibility: hidden;">
	<ngx-invisible-recaptcha #captchaElem
		[siteKey]="global.recaptchaSiteKey"
		(reset)="global.handleReset()"
		(ready)="global.handleCaptchaReady()"
		(load)="global.handleCaptchaLoad()"
		(success)="executeAction($event)"
		[useGlobalDomain]="false"
		[ngModel]="global.recaptcha"
		[ngModelOptions]="{ standalone: true }">
	</ngx-invisible-recaptcha>
</form>





