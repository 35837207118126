import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import { PolicyService } from '../../_services/policy.service';
import { AddressBookService } from '../../_services/addressbook.service'

import { FormGroup, FormControl, Validators } from '@angular/forms';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

const addressDetect = require('cryptocurrency-address-detector');

@Component({
  selector: 'app-address-book-view',
  templateUrl: './address-book-view.component.html'
})

export class AddressBookViewComponent implements OnInit {

	@Output() selectedGroupEmit = new EventEmitter();
	@Output() closeModalRequestEvent = new EventEmitter();
	@Input() viewMode = 'full'

	objectKeys = Object.keys

	public modalRef

	public addressBookModState = 0
	public addressDetails = {
		type:1
	}
	public addressValidation = null
	public errorCode = null

	public viewingGroup = false

	public addingAddress = false
	public editingAddress = false

	public editingGroup = false

	public importAddressGroup = {
		uploadEvent: null,
		importState: 0,
		walletsImported: false,
		importFileTypeError: null,
		errorList: []
	};

	public addressToDelete = null
	public dataloading = false
	public viewingAddress = null
	public selectedAddress = null

	public addressTooltipText = "Copy Address"
	public currentAddressViewIndex = null
	public foundResults = []
	public searchMode = false
	public search = null

	public formState = false

	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});

	constructor(public global: GlobalService, public coinService:CoinService, public policyService:PolicyService, public modalService: NgbModal, public addressBookService:AddressBookService, private changeDetectorRef: ChangeDetectorRef) {

	}

	ngOnInit() {
		this.global.PAGE_INDEX = 2
		console.log(this.global.PAGE_INDEX);
		window.scroll(0, 0);
		this.global.titleService.setTitle(this.global.appName +" - Address Book")
		this.global.addressBookTab = 0

		this.getUserPortfolios()
	}

	openFullModal(content) {
		this.modalRef = this.modalService.open(content, { windowClass: 'gr-modal-full', backdrop: false });
	}



	checkAddress(address){

		if(address!=null){
			let crypto = null
			let splitted = null
			addressDetect(address).then(cryptocurrency => {
				crypto = cryptocurrency
				console.log('detected crypto: ' + crypto)

				if(cryptocurrency == 'Cryptocurrency could not be detected'){
					this.addressValidation = 0
				} else {

					splitted = crypto.split("/", 3);
					console.log('detected crypto: ' + splitted)

					if(this.addressDetails['crypto'] != null || this.addressDetails['crypto'] != ''){
						if(splitted.length>1){
							if(
								splitted[0] == this.addressDetails['crypto']['symbol'] ||
								splitted[1] == this.addressDetails['crypto']['symbol'] ||
								splitted[2] == this.addressDetails['crypto']['symbol']
							){
								this.addressValidation = 1
							} else {
								this.addressValidation = 2
							}
						} else {
							if(splitted == this.addressDetails['crypto']['symbol']){
								this.addressValidation = 1
							} else {
								this.addressValidation = 2
							}
						}
					}
				}
			});
		}

	}

	searchAddress(search){
		this.foundResults = []

		search = search.toUpperCase()

		if(search != "" || search != null){
			this.searchMode = true
			for(var address in this.global.addresses){
				let upperCased = this.global.addresses[address].name.toUpperCase()

				if(upperCased.includes(search)) {
					if(!this.foundResults.includes(this.global.addresses[address].type)){
						this.foundResults.push(this.global.addresses[address])
					}
				}
			}
		} else {
			this.searchMode = false
		}
	}

	openLargeModal(modal){
		this.modalRef = this.modalService.open(modal, {size:'lg'})
	}

	portfolioSelectorModalRef = null

	openPortfolioModal(modal){
		this.portfolioSelectorModalRef = this.modalService.open(modal, {size:'lg'})
	}


	closeModal() {
		this.modalRef.close()
	}

	resetSearch(){
		this.searchMode = false
		this.search = null
	}

	viewAddress(address){
		this.viewingAddress = address
	}

	viewGroup(group){
		this.global.addressDisplayCount = null
		this.viewingGroup = group
		this.changeDetectorRef.detectChanges();
		this.global.addressGroupSelectedEmitter.emit(group)
		this.changeDetectorRef.detectChanges();
	}

	getAddressGroups() {
		let getData = null
		if(this.global.currentPortfolioId) {
			getData = {
				portfolioId: this.global.currentPortfolioId
			}
		}

		this.global.httpGET('get_address_groups', getData).subscribe(response => {
			this.global.addressGroups = response.data
		}, error => {
		});
	}

	isGroupAddressSelected(id){
		let arrIndex = this.viewingGroup['members'].indexOf(id)
		if (arrIndex !== -1) {
			return true
		} else {
			return false
		}
	}

	selectGroupAddress(id){
		let arrIndex = this.viewingGroup['members'].indexOf(id)
		if (arrIndex !== -1) {
			this.viewingGroup['members'].splice(arrIndex, 1)
		} else {
			this.viewingGroup['members'].push(id)
		}

	}

	updateAddressGroup(toDelete) {
		this.editingGroup = true
		if(this.viewingGroup['temp_name'] == '' || this.viewingGroup['temp_name'] == null || this.viewingGroup['temp_name'] == undefined) {
			this.errorCode = 1
			this.editingGroup = false
			return
		}

		this.global.httpPOST('edit_address_group', {
			groupId:		this.viewingGroup['id'],
			groupName:		this.viewingGroup['temp_name'],
			groupDesc:		this.viewingGroup['temp_desc'],
			groupMembers:	this.viewingGroup['members'],
			groupScope:		this.viewingGroup['temp_type'],
			//groupType:		this.viewingGroup['']
			delete:			toDelete
		}).subscribe(response => {
			this.editingGroup = false
			this.global.getAllAddresses()
			this.getAddressGroups()
			this.global.selectedAddressGroup(true)
			this.closeModal()
			this.viewingGroup['group_name'] = this.viewingGroup['temp_name'];
		}, error => {
			this.editingGroup = false
			this.closeModal()
		});
	}

	deleteAddressGroup() {
		this.formState = true
		this.global.httpPOST('delete_address_group', {
			groupId:		this.viewingGroup['id'],

		}).subscribe(response => {
			this.formState = false
			this.global.getAllAddresses()
			this.getAddressGroups()
			this.global.selectedAddressGroup(true)
			this.viewGroup(false)
			this.closeModal()
		}, error => {
			this.formState = false
			this.closeModal()
		});

	}


	getAddressbyId(id){
		console.log(id)
		let addrObj = null
		this.global.addresses.forEach(element => {
			if (element.id == id) {
				console.log('Adding!')
				console.log(element)
				addrObj = element
			}
		});
		return addrObj
	}

	copyAddress(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);

		this.addressTooltipText = "Copied!"
		setTimeout(() => {
			this.addressTooltipText = "Copy Address"
		}, 1000);
	}

	createAddress(){
		this.addingAddress = true
		if(this.validateAddressForm()){
			this.global.httpPOST('add_address',{addressDetails:this.addressDetails, verified:this.addressValidation}).subscribe(response => {
				this.addingAddress = false
				if(response.status==true){
					this.modalRef.close();
					this.resetAdding()
					this.global.getAllAddresses()

				} else {
					this.errorCode = 6
				}
			}, error => {
				this.addingAddress = false
			});
		} else {
			this.addingAddress = false
		}
	}


	setCoinTypeId(crypto){
		this.addressDetails['crypto'] = crypto
		if(this.addressDetails['address']!=null || this.addressDetails['address'] != ''){
			this.checkAddress(this.addressDetails['address'])
		}
	}

	resetEditAddressCoin(){
		this.addressDetails['crypto_id'] = null
	}

	validateAddressForm(){
		if(this.addressDetails['name']==null || this.addressDetails['name'] == ''){
			this.errorCode = 1
			return false
		}

		if(this.addressDetails['crypto']==null || this.addressDetails['crypto'] == ''){
			this.errorCode = 2
			return false
		}

		if(this.addressDetails['address']==null || this.addressDetails['address'] == ''){
			this.errorCode = 3
			return false
		}

		if(this.addressDetails['type']==null){
			this.errorCode = 4
			return false
		}

		if(this.addressDetails['type'] == 3 && (this.addressDetails['portfolioId'] == null || !this.addressDetails['portfolioId'])){
			this.errorCode = 9
			return false
		}

		if(this.addressValidation == 2){
			this.errorCode = 5
			return false
		}

		console.log(this.phoneForm.valid)
		if(this.phoneForm['controls']['phone']['value'] != null && !this.phoneForm.valid){
			//Invalid Phone Number
			this.errorCode = 7;
			return false
		} else {
			if(this.phoneForm['controls']['phone']['value'] != null){
				this.addressDetails['tel'] = this.phoneForm['controls']['phone']['value']['internationalNumber'];
			}
		}

		if(this.addressDetails['email'] != null && !this.global.validateEmail(this.addressDetails['email'])){
			console.log(this.addressDetails)
			this.errorCode = 8;
			return false
		}



		return true;
	}

	deleteAddress(content, address){
		this.addressToDelete = address
		console.log(this.addressToDelete)
		this.openModal(content)
	}

	
	selectAddressPortfolio(id, name) {
			this.viewingGroup['portfolioId'] = id
			this.viewingGroup['portfolioName'] = name
		/*
		let arrIndex = this.addressDetails['portfolioIds'].indexOf(id)
		if (arrIndex !== -1) {
			this.addressDetails['portfolioIds'].splice(arrIndex, 1)
		} else {
			this.addressDetails['portfolioIds'].push(id)
		}
		*/
	}

	openGroupModal(content) {
		console.log(this.viewingGroup)
		this.viewingGroup['temp_name'] = this.viewingGroup['group_name']
		this.viewingGroup['temp_desc'] = this.viewingGroup['group_description']
		this.viewingGroup['temp_type'] = this.viewingGroup['type']
		this.viewingGroup['portfolioId'] = this.viewingGroup['portfolio_id']

		this.viewingGroup['portfolioName'] = this.global.getPortfolioNameById(this.viewingGroup['portfolioId'])

		this.openModal(content)
	}

	deleteAddressConfirm(){
		this.global.httpGET('delete_address',{addressId:this.addressToDelete['id']}).subscribe(response => {
			if(response.status==true){
				this.global.getAllAddresses()
				setTimeout(() => {
					this.modalRef.close();
					this.global.selectedAddressGroup(true)
					//this.addressBookService.addressLinkRefresh.emit(true)
				}, 2000);
				this.viewingAddress = null
				this.addressBookService.addressLinkRefresh.emit(true)
				this.addressToDelete = null
			} else {
			}
		}, error => {
			this.modalRef.close();

			this.viewingAddress = null
			this.addressToDelete = null
		});
	}

	cancelDelete(){
		this.addressToDelete =  null
	}

	saveAddressChanges(){
		this.editingAddress = true
		this.global.httpPOST('edit_address',{addressDetails:this.addressDetails}).subscribe(response => {

			if(response.status==true){
				this.global.getAllAddresses()
					this.modalRef.close();
					this.global.selectedAddressGroup(true)

					//this.addressBookService.addressLinkRefresh.emit(true)
				this.resetAdding()
			}
			this.editingAddress = false
		}, error => {
			this.editingAddress = false
		});
	}

	triggetAddressEditing(content, address){
		this.addressDetails = address
		this.addressBookModState = 1
		this.openModal(content)
	}

	createAddressBookEntry(modal, isNew){

		this.addressDetails = {
			type:1
		}
		this.addressBookModState = 0

		this.modalRef = this.modalService.open(modal)
	}

	resetAdding(){
		this.addressDetails = {
			type:1
		}
		this.addressBookModState = 0
		this.addressValidation = null
		this.addressToDelete =  null
	}

	openModal(content) {
		this.modalRef = this.modalService.open(content);
	}

	closeSubAlert(){
		this.errorCode = 0
		this.currentAddressViewIndex = null
	}

	public isdropdownOpen = false
	dropdownOpenClose(state){
		this.isdropdownOpen = state
	}

	dropDownOpenId = -1
	dropdownOpenCloseSpecific(state){
		this.dropDownOpenId = state
	}


	addressNewAdd($event){
		console.log($event)
		// if($event != false){
		// 	this.viewingAddress['allAddresses'].push($event)
		// }
	}

	public addressOfInterest = null
	public addressOfInterestIndex = null

	removeAddressInit(address, index, modal){
		this.addressOfInterest = address
		this.addressOfInterestIndex = index
		this.modalRef = this.modalService.open(modal)
	}

	confirmRemoveAddress(){
		this.editingAddress = true
		this.global.httpPOST('remove_address',{addressOfInterest:this.addressOfInterest['id'], contactId:this.viewingAddress['id']}).subscribe(response => {
			if(response.status==true){
				this.viewingAddress['allAddresses'].splice(this.addressOfInterestIndex, 1)
				this.global.getAllAddresses()
					this.modalRef.close();
					this.global.selectedAddressGroup(true)
					//this.addressBookService.addressLinkRefresh.emit(true)
				this.addressBookService.addressLinkRefresh.emit(true)
			}
			this.editingAddress = false

		}, error => {
			this.editingAddress = false
		});
	}

	editAddressLabelInit(address, index, modal){
		this.addressOfInterest = address
		this.addressOfInterestIndex = index
		this.modalRef = this.modalService.open(modal)
		this.editAddres['label']=address['label']
	}

	editAddres = {}
	confirmEditContractAddress(){
		this.editingAddress = true
		this.global.httpPOST('edit_contact_address',{addressOfInterest:this.addressOfInterest['id'], contactId:this.viewingAddress['id'], editAddres:this.editAddres}).subscribe(response => {
			if(response.status==true){
				this.viewingAddress['allAddresses'][this.addressOfInterestIndex]['label'] = this.editAddres['label']
				this.modalRef.close();
			}
			this.editingAddress = false
		}, error => {
			this.editingAddress = false
		});
	}


	uploadFile = function (event) {
		this.importAddressGroup.importFileTypeError = false
		this.importAddressGroup.uploadEvent = event
	};

	importAddressGroupEvent() {
		if (this.importAddressGroup.uploadEvent.target.files[0].type == 'text/csv' || this.importAddressGroup.uploadEvent.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			|| this.importAddressGroup.uploadEvent.target.files[0].type == 'application/vnd.ms-excel') {
			this.importAddressGroup.importFileTypeError = false
			this.importAddressGroup.importState = 1


			const desiredGroupID = this.viewingGroup['id']
			console.log('View Group!')
			console.log(desiredGroupID)


			const data = new FormData();
			const files = this.importAddressGroup.uploadEvent.target.files;
			this.global.$.each(files, function (key, value) {
				data.append(key, value);
			});

			data.append('dest', 'address_import');
			data.append('variable', "address_import");

			const tempObj = this;
			this.global.$.ajax({
				xhr() {
					const xhr = new XMLHttpRequest();
					return xhr;
				},
				url: this.global.API_URL + 'upload_file',
				headers: {
					'Authorization': 'Bearer ' + this.global.authService.TOKENS.access_token,
				},
				type: 'POST',
				data,
				cache: false,
				dataType: 'json',
				processData: false, // Don't process the files
				contentType: false, // Set content type to false as jQuery will tell the server its a query string requeste
				success(data: any, textStatus: any, jqXHR: any) {
					let filename = data.fileNameWithExt;
					tempObj.importAddressGroup.importState = 2
					tempObj.global.httpPOST('import_address_book', {
						fileName: filename,
						portfolioId: tempObj.global.currentPortfolioId,
						originalFileName: tempObj.importAddressGroup.uploadEvent.target.files[0].name,
						groupId: desiredGroupID
					})
						.subscribe(response => { //Fail with errors
							// console.log(response);
							if(!response.status && response.data && response.data.length > 0){
								tempObj.importAddressGroup.errorList = response.data;
								tempObj.importAddressGroup.importState = 4
							} else{ //Success
								tempObj.importAddressGroup.importState = 3;
								this.global.getAllAddresses();
							}
						}, error => {

							tempObj.importAddressGroup.importState = 4
						});
				},
				error(jqXHR, textStatus, errorThrown) {
					console.log('File Upload Error!')
					console.log(errorThrown)
					tempObj.importAddressGroup.importState = 4
				}
			})
		} else {
			this.importAddressGroup.importFileTypeError = true
		}
	}

	closeModalRequestEventTrigger(){
		this.closeModalRequestEvent.emit(true)
	}

	getUserPortfolios() {
		console.log('User Entities:');
		console.log(this.global.portfolios)
		
		if(this.global.portfolios == null) {
			this.coinService.getPortfolios(false, null, false, false)
		}
	}

}
