
<!--
    0 = draft,
    1 = pending send,
    2 = sent waiting payment/unpaid,
    3 = failed to send,
    4 = cancelled,
    5 = Paid,
    6 = accepted,
    7 = refunded
-->
<span class="badge-lg text-uppercase badge-light" *ngIf="status==0">
    Draft
</span>
<span class="badge-lg text-uppercase badge-secondary" *ngIf="status==1">
    Queued
</span>
<span class="badge-lg text-uppercase badge-warning" *ngIf="status==2">
    Unpaid
</span>

<span class="badge-lg text-uppercase badge-danger" *ngIf="status==4">
    Canceled
</span>
<span class="badge-lg text-uppercase badge-info" *ngIf="status==5">
    Paid
</span>
<span class="badge-lg text-uppercase badge-info" *ngIf="status==8">
    Paid partially
</span>
<span class="badge-lg text-uppercase badge-success" *ngIf="status==6">
    Accepted
</span>
<span class="badge-lg text-uppercase badge-light" *ngIf="status==7">
    Rejected
</span>

