import { Injectable} from '@angular/core';
import Web3 from 'web3';
import { Http, } from '@angular/http';


import { GlobalService } from '../global.service';
import { CoinService } from '../coin.service';

declare var  window;


@Injectable()
export class HotWalletService {

	private web3Provider: any
	public balance

	public hotWallets = []
	public hotWalletsAdressses = []

	public syncingState = false
	public loadingBrowserHotWallets = false

  	constructor(public http: Http, private global: GlobalService, private coinService: CoinService){

		if(this.global.isAuthenticated()){
			this.loadBrowserHotWallets()
		}
	}

	async loadBrowserHotWallets() {

		this.syncingState = false
		if ((window as any)['ethereum']) {
			this.web3Provider = new Web3((window as any)['ethereum']);
			try {

				this.syncingState = true
				// Request account access if needed
				let res= await (window as any)['ethereum'].enable();
				console.log(res)
				this.global._uiState = 'full'
				this.getEthBalance(res[0])
				setTimeout(() => {
					this.getEThTxHistory(res[0])
				}, 5000);
			} catch (error) {
				// User denied account access...
			}
		}
		// Legacy dapp browsers...
		else if ((window as any)['web3']) {

			this.syncingState = true
			this.web3Provider = new Web3((window as any)['web3'].currentProvider);
			// Acccounts always exposed
		}
		// Non-dapp browsers...
		else {
			console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
		}
	}

	async getAccounts(){
		this,this.web3Provider.e
		this.web3Provider.eth.getAccounts(function(error, accounts) {
			this.getEthBalance(accounts[0])
			this.syncingState = false

		})
	}

	getEthBalance(account){
		this.http.get('https://api.etherscan.io/api?module=account&action=balance&address=' + account + '&apikey=97NGF8W4I9QP6CFMIVK2G2IHECMZU2Z4DN').subscribe(
			(response) => {

				this.syncingState = false
				response = JSON.parse(response['_body'])
				let amount = +response['result']/1000000000000000000

				let hotAccount = {
					name:'Ethereum',
					type:'ETH',
					typeLC:'eth',
					source:'metamask',
					address:account,
					balance:amount
				}

				console.log(hotAccount)
				if(!this.hotWalletsAdressses.hasOwnProperty(account)){

					this.hotWalletsAdressses[account] = hotAccount
					this.hotWallets.push(hotAccount)
				}

				this.fetchExchangeRate(hotAccount.type)


		}, 
		(error) => this.handleError())
	}

	getEThTxHistory(account){
		this.global.httpAPIGET('https://api.etherscan.io/api?module=account&action=txlist&address='+ account + '&startblock=0&endblock=99999999&sort=asc&apikey=97NGF8W4I9QP6CFMIVK2G2IHECMZU2Z4DN').subscribe(
			(response) => {
				console.log(response)
				if(response.status==1){
					console.log(response['result'])
					this.coinService.hotWalletTx[account] = response['result']
				} else {
					if(response.message == 'No transactions found'){
						this.coinService.hotWalletTx[account] = []
					}
				}


		}, 
		(error) => this.handleError())
	}

	fetchExchangeRate(symbol){
		if(!this.coinService.exchangeRates.hasOwnProperty(symbol)){
			this.global.httpAPIGET('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=ETH&tsyms='+ this.global._currency['code'] ).subscribe(priceResponse => {
				this.coinService.exchangeRates[symbol]= priceResponse['RAW'][symbol][this.global._currency['code']]['PRICE'] 
				console.log(this.coinService.exchangeRates)
			})
		}
		
	}

	handleError(){

	}

	

	
}
