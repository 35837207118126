<ng-container class="sticky-top">
	<nav class="navbar navbar-expand-lg navbar-bus-cw bg-white  pl-0 pr-0">
		<div class="container-fluid">

			<a class="navbar-brand pt-2 pb-3" [routerLink]="'/'">
				<img src="{{global.FE_IMG_URL}}img/logo/logo.png" width="170" class="logo-img-header d-inline-block align-top" alt="" style="padding-top: 2%;">  
			</a>
		
			<!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
			<button class="navbar-toggler" type="button" *ngIf="isMenuCollapsed" (click)="isMenuCollapsed = !isMenuCollapsed">
				<i class="fa fa-bars" aria-hidden="true"></i>
			</button>

			<button class="navbar-toggler" type="button" *ngIf="!isMenuCollapsed" (click)="isMenuCollapsed = !isMenuCollapsed">
				<i class="fa fa-times" aria-hidden="true"></i>
			</button>
			<!-- <ul class="navbar-nav mr-auto">
				<li  class="nav-item" ngbTooltip="Marketplace">
					<a class="nav-link pointer menu-pl" href="https://www.cryptoworth.com/marketplace" target="_blank">
						<div>
							<i style="font-size: 19px;" class="fa fa-shopping-basket" aria-hidden="true"></i> Marketplace
						</div>
					</a>
				</li> 
			</ul> -->
			<ul class="nav justify-content-end">
				<li class="nav-item-no-hover py-1 pointer active" ngbTooltip="Login">
					<a class="nav-link pointer menu-pl"  [routerLink]="['/login']" >
						<div>
							Login
						</div>
					</a>
				</li>
				<!-- <li class="nav-item dropdown mr-0 pr-0" ngbTooltip="Register">
					<a class="btn btn-primary btn-lg text-bold text-white" [routerLink]="['/register']">
						Sign Up
					</a>
				</li> -->
				
			  </ul>
		
		</div>

	
	</nav>

</ng-container>

	
<ng-template #contactInfoModal let-c="close" let-d="dismiss">
		<div class="modal-body">
				<div class="jumbotron jumbotron-cw text-center">
						<div class="container">
							<div class="container text-center">
								<h4>Contact Us</h4>
							</div>
						</div>
					</div>
					<div  class="container">
						<br/>
						<div class="row">
							<div class="col">
								<div class="panel-body ">
									<div class="media">
										<div class="media-body">
											<h3>Email</h3>
											<p style="color: rgba(0, 0, 0, 0.9);">support@cryptoworth.app</p>	
										</div>
									</div>
								</div>
							</div>
							<div class="col">
								<div class="panel-body ">
									<div class="media">
										<div class="media-body">
											<h3>Tel</h3>
											<p style="color: rgba(0, 0, 0, 0.9);">+1 438 924 7258</p>	
										</div>
									</div>
								</div>
							</div>
							<div class="col">
								<div class="panel-body ">
									<div class="media">
										<div class="media-body">
											<h3>Mailing Address</h3>
											<p style="color: rgba(0, 0, 0, 0.9);">
												18 King Street East, Suite 1400 <br/>
												Toronto, ON M5C 1C4 <br/>
												Canada
											</p>	
										</div>
									</div>
								</div>
							</div>
						</div>
						<br/>
					</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-main btn-lg " (click)="d('Cross click')">Close</button>
		</div>
</ng-template>
	
	
	
	
	
	
	
	
	
	


