


<div class="modal-header">
    <h4 class="modal-title pull-left text-warning"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning !</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.close(false);">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <p>
        This process will sync <b> {{ txCount | number}} </b>
         transactions to <ng-container *ngTemplateOutlet="erpBadge"></ng-container>. 
        </p>
    <p> Syncing <b> {{ txCount | number}} </b> journals can lead to your <ng-container *ngTemplateOutlet="erpBadge"></ng-container> account inaccessible due to volume limitations of <ng-container *ngTemplateOutlet="erpBadge"></ng-container>. 
        </p>
        <p>Alternatively, 
        you can roll up transactions to reduce this count. <br/>
        We highly recommend that you contact Cryptoworth support to help you with the process if you are doing it for the first time.
    </p>

</div>

<ng-template #erpBadge>
    <b>
        <ng-container *ngIf="gatewayId === 1">
            Quickbook
        </ng-container>

        <ng-container *ngIf="gatewayId === 2">
             Xero
        </ng-container>

        <ng-container *ngIf="gatewayId === 3">
            Oracle Netsuite
        </ng-container>

        <ng-container *ngIf="gatewayId === 6">
             Cryptoworth
        </ng-container>

        <ng-container *ngIf="gatewayId === 8">
             Pennylane
        </ng-container> 
    </b>
</ng-template>