import { Injectable, Output, EventEmitter   } from '@angular/core';
import { GlobalService } from './global.service';


@Injectable()
export class AddressBookService {

	@Output() addressLinkRefresh: EventEmitter<any> = new EventEmitter();

	constructor(private global: GlobalService,
	) {

	}

}
