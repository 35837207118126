
<app-global-loader></app-global-loader>

<div *ngIf="global.serverInfo != null && global.serverInfo['status']==0"> 
	<app-component-models></app-component-models>
</div>

<ng-container *ngIf="global.serverInfo != null && global.serverInfo['status']==1">
	<router-outlet *ngIf="global._appState==1"></router-outlet>
	<div aria-live="polite" aria-atomic="true">

			<!-- Position it -->
		<div style="position: fixed; bottom: 3rem; right: 1rem;">
				<div class="toast show fade-in" role="alert" aria-live="assertive" data-autohide="true" aria-atomic="true" *ngFor="let notification of global._notificationsPopups;let i = index">
						<div *ngIf="global.currentPortfolioId == notification.portfolioId && notification.family != 'deployments'">
								<div class="toast-header">
										<strong class="mr-auto">Notification</strong>
										<!-- <small>11 mins ago</small> -->
										<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="global.autoHideNotificationsForce(i)">
												<span aria-hidden="true">&times;</span>
										</button>

								</div>
								<div *ngIf="notification.family=='syncs'">
									<div class="toast-body" *ngIf="global.currentPortfolioId==0" (click)="coinService.loadPortfolio(notification.portfolioId);hideCurrentPortfolioNotificationsPopups(notification.portfolioId)">
										
										<span *ngIf="notification.source=='wallet'">A wallet</span>
										<span *ngIf="notification.source=='exchange'">An account</span>
										<span> in entity <b>{{ notification.portfolioName }}</b></span>
										<span *ngIf="notification.status==1"> started syncing.</span>
										<span *ngIf="notification.status=='2'"> synced successfully.</span>
										<span *ngIf="notification.status=='3'"> sync failed.</span>
											
									</div>
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
												
										<div *ngIf="notification.source=='wallet'">
											<span *ngIf="notification.status==1"> The wallet started syncing.</span>
											<span *ngIf="notification.status=='2'"> The wallet synced successfully.</span>
											<span *ngIf="notification.status=='3'"> The wallet sync failed.</span>
											<span *ngIf="notification['trueData']==true">
													<br/>
													<img class="img-fluid" src="{{global.COIN_IMG_URL}}{{notification['sourceDetails']['image']}}" width="20" height="20" alt=""> {{ notification['sourceDetails']['type']}}
											</span>
										</div>
										
										<div *ngIf="notification.source=='exchange'">
											<span *ngIf="notification.status==1"> Your account started syncing.</span>
											<span *ngIf="notification.status=='2'"> Your account synced successfully.</span>
											<span *ngIf="notification.status=='3'"> Your account sync failed.</span>
											<span *ngIf="notification['trueData']==true">
												<br/>
												<img class="pr-2"  src="{{global.EXCHANGE_IMG_URL}}{{notification['sourceDetails']['id']}}.png" height="15" alt=""> 
												<small *ngIf="notification['sourceDetails']['name']!=null">({{notification['sourceDetails']['name']}})</small>
											</span>
										</div>
									</div>
								</div>

								<div *ngIf="notification.family ==='disconnect'">
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
																						
										<div *ngIf="notification.source=='wallet'">
											<span *ngIf="notification.status==1"> The connection’s disconnection process started.</span>
											<span *ngIf="notification.status=='2'"> The connection disconnected successfully.</span>
											<span *ngIf="notification.status=='3'">The connection disconnected failed.</span>
											<span *ngIf="notification['trueData']==true">
													<br/>
													<img class="img-fluid" src="{{global.COIN_IMG_URL}}{{notification['sourceDetails']['image']}}" width="20" height="20" alt=""> {{ notification['sourceDetails']['type']}}
											</span>
										</div>

										<div *ngIf="notification.source=='virtual_wallet'">
											<span *ngIf="notification.status==1"> The connection’s disconnection process started.</span>
											<span *ngIf="notification.status=='2'"> The connection disconnected successfully.</span>
											<span *ngIf="notification.status=='3'">The connection disconnected failed.</span>
											<span *ngIf="notification['trueData']==true">
													<br/>
													<img class="img-fluid" src="{{global.COIN_IMG_URL}}{{notification['sourceDetails']['image']}}" width="20" height="20" alt=""> {{ notification['sourceDetails']['type']}}
											</span>
										</div>
										
										<div *ngIf="notification.source=='exchange'">
											<span *ngIf="notification.status==1"> The connection’s disconnection process started.</span>
											<span *ngIf="notification.status=='2'"> The connection disconnected successfully.</span>
											<span *ngIf="notification.status=='3'">The connection disconnected failed.</span>
											<span *ngIf="notification['trueData']==true">
												<br/>
												<img class="pr-2"  src="{{global.EXCHANGE_IMG_URL}}{{notification['sourceDetails']['id']}}.png" height="15" alt=""> 
												<small *ngIf="notification['sourceDetails']['name']!=null">({{notification['sourceDetails']['name']}})</small>
											</span>
										</div>
									</div>
								</div>
								<div *ngIf="notification.family=='gateways'">
									<div class="toast-body" *ngIf="global.currentPortfolioId==0" (click)="coinService.loadPortfolio(notification.portfolioId);hideCurrentPortfolioNotificationsPopups(notification.portfolioId)">
										
										<span *ngIf="notification.source=='tax_calculations'">{{notification.data.name}}</span>
										<span> in entity <b>{{ notification.portfolioName }}</b></span>
										<span *ngIf="notification.status==1 && notification.data.canceled ==0"> started syncing.</span>
										<span *ngIf="notification.status==2 && notification.data.canceled ==0"> synced successfully.</span>
										<span *ngIf="notification.status==3 && notification.data.canceled ==0"> sync failed.</span>
										<span *ngIf="[0, 1].indexOf(notification.status) !== -1 && notification.data.canceled ==1"> canceling.</span>
										<span *ngIf="notification.status==2 && notification.data.canceled ==1"> canceled.</span>
											
									</div>
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
												
										<div *ngIf="notification.source=='tax_calculations'">
											<span *ngIf="notification.status==1 && notification.data.canceled ==0"> The {{notification.data.name}} started syncing.</span>
											<span *ngIf="notification.status==2 && notification.data.canceled ==0"> The {{notification.data.name}} synced successfully.</span>
											<span *ngIf="notification.status==3 && notification.data.canceled ==0"> The {{notification.data.name}} sync failed.</span>
											<span *ngIf="[0, 1].indexOf(notification.status) !== -1 && notification.data.canceled ==1"> The {{notification.data.name}} sync cancellation in progress.</span>
											<span *ngIf="notification.status==2 && notification.data.canceled ==1"> The {{notification.data.name}} sync canceled.</span>
											<span *ngIf="notification['trueData']==true">
													<br/>
													<img class="img-fluid" src="{{notification.data.image}}" width="20" height="20" alt=""> {{notification.data.name}}
											</span>
										</div>
									</div>
								</div>

								<div *ngIf="notification.family=='erp_meta_data_sync'">
									<div class="toast-body" *ngIf="global.currentPortfolioId==0" (click)="coinService.loadPortfolio(notification.portfolioId);hideCurrentPortfolioNotificationsPopups(notification.portfolioId)">
										
										<span *ngIf="notification.data.type==1">{{notification.data.name}}</span>
										<span> in entity <b>{{ notification.portfolioName }}</b></span>
										<span *ngIf="notification.status==1"> started syncing.</span>
										<span *ngIf="notification.status==2"> synced successfully.</span>
										<span *ngIf="notification.status==3"> sync failed.</span>
				
											
									</div>
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
												
										<div *ngIf="notification.data.type==2">
											<span *ngIf="notification.status==1"> The {{notification.data.name}} started syncing.</span>
											<span *ngIf="notification.status==2"> The {{notification.data.name}} synced successfully.</span>
											<span *ngIf="notification.status==3"> The {{notification.data.name}} sync failed.</span>
										
											<span *ngIf="notification['trueData']==true">
													<br/>
													<img class="img-fluid" src="{{notification.data.image}}" width="20" height="20" alt=""> {{notification.data.name}}
											</span>
										</div>
									</div>
								</div>

								<div *ngIf="notification.family=='vendors'">
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
										
										<span>Vendors</span>
									
										<span *ngIf="notification.status==1"> started syncing.</span>
										<span *ngIf="notification.status==2">  synced successfully.</span>
										<span *ngIf="notification.status==3">  sync failed.</span>
										<span *ngIf="notification['trueData']==true">
											<br/>
											<img class="img-fluid" src="{{notification.data.image}}" width="20" height="20" alt=""> {{notification.data.name}}
									</span>
									</div>
								</div>

								<div *ngIf="notification.family=='invoices'">
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
										
										<span>Invoices</span>
									
										<span *ngIf="notification.status==1"> started syncing.</span>
										<span *ngIf="notification.status==2">  synced successfully.</span>
										<span *ngIf="notification.status==3">  sync failed.</span>
										<span *ngIf="notification['trueData']==true">
											<br/>
											<img class="img-fluid" src="{{notification.data.image}}" width="20" height="20" alt=""> {{notification.data.name}}
									</span>
									</div>
								</div>

								<div *ngIf="notification.family=='rollup'">
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
										Roll-up Report	
										<div *ngIf="notification.source=='tax_calculations'">
											<span *ngIf="notification.status==1"> The {{notification.data.name}} started.</span>
											<span *ngIf="notification.status=='2'"> The {{notification.data.name}}completed successfully. </span>
											<span *ngIf="notification.status=='3'"> The {{notification.data.name}} failed.</span>
											<span *ngIf="notification['trueData']==true">
													<br/>
													{{notification.data.name}}
											</span>
										</div>
									</div>
								</div>

								
								<div *ngIf="notification.family=='nft_resolve'">
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0">
										Auto-Resolve	
										<div *ngIf="notification.source=='nft_resolve'">
											<span *ngIf="notification.status==1"> The Auto-Resolve process started.</span>
											<span *ngIf="notification.status=='2'"> The Auto-Resolve process completed successfully. </span>
											<span *ngIf="notification.status=='3'"> The Auto-Resolve process failed.</span>
										</div>
									</div>
								</div>
								<div class="pointer" *ngIf="notification.family=='ugl_calculation'">
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0">
										Unrealized Gain/Loss Calculation	
										<div *ngIf="notification.source=='ugl_calculation'">
											<span *ngIf="notification.status==1"> Unrealized Gain/Loss Calculation started.</span>
											<span *ngIf="notification.status==2"> Unrealized Gain/Loss Calculation completed successfully. </span>
											<span *ngIf="notification.status==3"> Unrealized Gain/Loss Calculation failed.</span>
										</div>
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='imports'" >
									<div class="toast-body" *ngIf="global.currentPortfolioId==0" (click)="coinService.loadPortfolio(notification.portfolioId);hideCurrentPortfolioNotificationsPopups(notification.portfolioId)">
										Transaction import in a
										<span *ngIf="notification.source=='virtual_wallet'">wallet</span>

										<span *ngIf="notification.source=='virtual_wallet'">in entity <b>{{notification.portfolioName}} </b></span>
										<span *ngIf="notification.status==1"> started.</span>
										<span *ngIf="notification.status=='2'"> completed successfully.</span>
										<span *ngIf="notification.status=='3'"> failed.</span>
											
									</div>
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
												
										<div *ngIf="notification.source=='virtual_wallet'">
											Transaction import in your wallet
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status=='2'"> completed successfully.</span>
											<span *ngIf="notification.status=='3'"> failed.</span>
											<span *ngIf="notification['trueData']==true">
													<br/><br/>
													<img class="img-fluid" src="{{global.COIN_IMG_URL}}{{notification['sourceDetails']['image']}}" width="20" height="20" alt="">  <a href="javascript.void(0)" *ngIf="notification['sourceDetails'].name!=null"><span> {{notification['sourceDetails'].name}} </span></a>
													| 
													<span class="vw-transaction">&nbsp;V&nbsp;</span> <img class="pl-2 pr-2" src="{{global.HS_WALLET_IMG_URL}}{{notification['sourceDetails']['hs_wallet']}}.png" height="20" alt="" *ngIf="notification['sourceDetails']['hs_wallet']!=null"> 
											</span>
										</div>
										<div *ngIf="notification.source=='exchange'">
											Transaction import in your exchange
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status=='2'"> completed successfully.</span>
											<span *ngIf="notification.status=='3'"> failed.</span>
											<span *ngIf="notification['trueData']==true">
												<br/>
												<img class="pr-2"  src="{{global.EXCHANGE_IMG_URL}}{{notification['sourceDetails']['id']}}.png" height="15" alt=""> 
												<small *ngIf="notification['sourceDetails']['name']!=null">({{notification['sourceDetails']['name']}})</small>
											</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='import_details'" >
									<div class="toast-body" *ngIf="global.currentPortfolioId==0" (click)="coinService.loadPortfolio(notification.portfolioId);hideCurrentPortfolioNotificationsPopups(notification.portfolioId)">
										Transaction details import

										<span *ngIf="notification.source=='virtual_wallet'">in entity <b>{{notification.portfolioName}} </b></span>
										<span *ngIf="notification.status==1"> started.</span>
										<span *ngIf="notification.status=='2'"> completed successfully.</span>
										<span *ngIf="notification.status=='3'"> failed.</span>
											
									</div>
									<div class="toast-body" *ngIf="global.currentPortfolioId!=0" (click)="global.portfolioViewState(4)">
												
										<div *ngIf="notification.source=='virtual_wallet'">
											Transaction details import in your portfolio
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status=='2'"> completed successfully.</span>
											<span *ngIf="notification.status=='3'"> failed.</span>
											<span *ngIf="notification['trueData']==true">
													<br/><br/>
													<img class="img-fluid" src="{{global.COIN_IMG_URL}}{{notification['sourceDetails']['image']}}" width="20" height="20" alt="">  <a href="javascript.void(0)" *ngIf="notification['sourceDetails'].name!=null"><span> {{notification['sourceDetails'].name}} </span></a>
													| 
													<span class="vw-transaction">&nbsp;V&nbsp;</span> <img class="pl-2 pr-2" src="{{global.HS_WALLET_IMG_URL}}{{notification['sourceDetails']['hs_wallet']}}.png" height="20" alt="" *ngIf="notification['sourceDetails']['hs_wallet']!=null"> 
											</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='sanity_check'" >
									
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Sanity Check Report
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='EVM'" >
									
									<div class="toast-body">
												
										<div *ngIf="notification.source=='wallet'">
											Address EVM Detection Successful!
											<span>Additional address found for</span>
											<span *ngIf="notification.status==2"> Ethereum Wallet </span>
											<span *ngIf="notification.status!=2"> Non-Ethereum Wallet </span>
											<span>{{ notification.data }}</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='classification_summary_report'">
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Report generation
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='summary_report'">
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Report generation
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='running_balance'">
									<div class="toast-body">
												
										<div>
											Running Balance Calculation
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='tax_calculations'" >
									
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Tax calculation
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>
								
								<div class="pointer" *ngIf=" notification.family=='reconciliation_report'">
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Reconcilation Report
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf=" notification.family=='balance_range_report'">
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Ledger Report
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='historical_balance_report'" >
									
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Historical Balance Report
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='portfolio'">
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											NFT Price Sync
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='pivot_table'">
									<div class="toast-body">
												
										<div *ngIf="notification.source=='portfolio'">
											Pivot Table Creation
											<span *ngIf="notification.status==1"> started.</span>
											<span *ngIf="notification.status==2"> completed successfully.</span>
											<span *ngIf="notification.status==3"> failed.</span>
										</div>
										
									</div>
								</div>

								<div class="pointer" *ngIf="notification.family=='global'" >
									Global Notification Emmited
								</div>
						</div>
						
				</div>
		</div>
	</div>
	<div aria-live="polite" aria-atomic="true">
		<!-- Position it -->
		<div style="position: fixed; bottom: 3rem; left: 1rem;z-index: 99;">
			<ng-container *ngFor="let notification of global._notificationsPopups;let i = index">
			<div class="toast show fade-in" role="alert" aria-live="assertive" aria-atomic="true" *ngIf="i == 0">
				<div class="toast-warning" *ngIf="notification.family=='deployments' && (notification.data == null || notification.data > global.serverInfo.build_index) && i == 0">
					<div class="toast-header">
						<strong class="mr-auto">Server Notification</strong>
						<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="global.autoHideNotificationsForce(i)">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="toast-body">
						<p>New Software Version Available</p>
						<p>
							We've updated {{global.appName}}! Please refresh your application to reflect these changes.
						</p>
						<button class="btn btn-primary mb-3 float-right" (click)="reloadDumpCache()">Refresh</button>
					</div>
				</div>
			</div>
			</ng-container>
		</div>
	</div>
	<div aria-live="polite" aria-atomic="true" *ngIf="global.authService.show500Notice">
			<!-- Position it -->
		<div style="position: fixed; bottom: 3rem; left: 1rem;z-index: 99;">
			<div class="toast show fade-in" role="alert" aria-live="assertive" data-autohide="true" aria-atomic="true">
				<div class="toast-danger">
						<div class="toast-header">
							<strong class="mr-auto">Notification</strong>
							<!-- <small>11 mins ago</small> -->
							<!-- <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="global.autoHideNotificationsForce(i)">
									<span aria-hidden="true">&times;</span>
							</button> -->
						</div>
						<div class="toast-body">
							We encountered an unexpected error. Please try refreshing the application. If the problem persists, please contact customer support.
						</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="global._appState==2">
		<div class="row">
			<div class="col">
				<br/>
				<div class="text-center pt-4 mt-4">
					<img width="100" src="{{global.FE_IMG_URL}}img/payment/loader.gif">
					<p class="lead">
						Logging out
					</p>
				</div>
			</div>
		</div>
	</div>

</ng-container>

<ngx-tawk id="5cb3c06dc1fe2560f3fed03d"></ngx-tawk>


<ng-template #addBlockerModal let-c="close" let-d="dismiss">
	
	<!-- <div class="modal-header bb-0">
        <button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-body pt-5">
		<a href="#" [routerLink]="['/']">
		</a>
		<h1 class="text-center mt-2">
			<img src='{{global.FE_IMG_URL}}img/logo/logo.png' height="35" class="d-inline-block align-top" alt="">
			<br/>
			Ad blocker detected
		</h1>
		<p class="text-center lead">
			Ad blockers interfere with normal functioning of the software.
			<br/>
			Please disable ad-blockers in order to use Cryptoworth.  
		</p>
		
		<p class="text-center">
			If you are using the Brave browser, please disable the brave shield. 
		</p>
    </div>

</ng-template>