import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, SimpleChanges } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GatewayServiceService } from 'app/_services/gateways/gateway-service.service';
import { GlobalService } from 'app/_services/global.service';

interface undoOption {
    
		option: number,
		startDate: string | null,
		endDate: string | null,
		all: boolean,
        memo: string | null
	
}
@Component({
	selector: 'app-undo-warn-before-popup',
	templateUrl: './undo-warn-before-popup.component.html'
})


export class UndoWarnBeforePopup implements OnInit {
    @Input() undo : undoOption = {
        option: 1,
		startDate: null,
		endDate: null,
		all: false,
        memo: null
    };
    @Input() gatewayId = null;
    public isDisableUndoButton = false;

    constructor(public activeModal: NgbActiveModal, public global: GlobalService,  public modalService: NgbModal, public gatewayService: GatewayServiceService) {
        
    }

    ngOnInit(): void {
        console.log(this.undo, this.gatewayId)
        //throw new Error('Method not implemented.');
    }

    public undoConfirm(){
		
		this.isDisableUndoButton = true;
		//this.undoResponseState = null;

		let gatewayConnectionId = null;
		for(let i in this.global.connectedGateways){
			
			if(this.global.connectedGateways[i].id == this.gatewayId){
				gatewayConnectionId = this.global.connectedGateways[i].connection.connection_id;
				break;
			}
		}

		this.gatewayService.undoSync(
			this.gatewayId, 
			this.global.currentPortfolioId, 
			0,
			gatewayConnectionId,
			this.undo.startDate,
			this.undo.endDate,
			this.undo.all,
			1
			)
		.subscribe( response=>{

			if(response.code === 200){
                this.activeModal.close(response.data);
				if(response.data.gateway_id === 1){

					if(response.data.sync_mode == 1){
						this.global.gatewaySyncStatus.qb = response.data.status + 4;
					}else if(response.data.sync_mode == 2){
						this.global.gatewayVirtualSyncStatus.qb = response.data.status + 4;
					}else if(response.data.sync_mode == 3){
						this.global.gatewayUGLSyncStatus.qb = response.data.status + 4;
					}
					this.global.latestSyncobj.qb = response.data;

					// this.latestSyncobj = this.global.latestSyncobj.qb;
					// this.syncStatus = this.global.gatewaySyncStatus.qb;
					// this.syncStatusUgl = this.global.gatewayUGLSyncStatus.qb;
					// this.virtualSyncStatus = this.global.gatewayVirtualSyncStatus.qb;
				}
				
				if(response.data.gateway_id === 2){
					
					if(response.data.sync_mode == 1){
						this.global.gatewaySyncStatus.xero = response.data.status + 4;
					}else if(response.data.sync_mode == 2){
						this.global.gatewayVirtualSyncStatus.xero = response.data.status + 4;
					}else if(response.data.sync_mode == 3){
						this.global.gatewayUGLSyncStatus.xero = response.data.status + 4;
					}

					this.global.latestSyncobj.xero = response.data;

					// this.latestSyncobj = this.global.latestSyncobj.xero;
					// this.syncStatus = this.global.gatewaySyncStatus.xero;
					// this.syncStatusUgl = this.global.gatewayUGLSyncStatus.xero;
					// this.virtualSyncStatus = this.global.gatewayVirtualSyncStatus.xero;
				}

				if(response.data.gateway_id === 3){

					if(response.data.sync_mode == 1){
						this.global.gatewaySyncStatus.netSuite = response.data.status + 4;
					}else if(response.data.sync_mode == 2){
						this.global.gatewayVirtualSyncStatus.netSuite = response.data.status + 4;
					}else if(response.data.sync_mode == 3){
						this.global.gatewayUGLSyncStatus.netSuite = response.data.status + 4;
					}

					this.global.latestSyncobj.ons = response.data;

					
					// this.latestSyncobj = this.global.latestSyncobj.ons;
					// this.syncStatus = this.global.gatewaySyncStatus.netSuite;
					// this.syncStatusUgl = this.global.gatewayUGLSyncStatus.netSuite;
					// this.virtualSyncStatus = this.global.gatewayVirtualSyncStatus.netSuite;
				}

				if(response.data.gateway_id === 6){

					if(response.data.sync_mode == 1){
						this.global.gatewaySyncStatus.virtual = response.data.status + 4;
					}else if(response.data.sync_mode == 2){
						this.global.gatewayVirtualSyncStatus.virtual = response.data.status + 4;
					}else if(response.data.sync_mode == 3){
						this.global.gatewayUGLSyncStatus.virtual = response.data.status + 4;
					}

					this.global.latestSyncobj.virtual = response.data;

					// this.latestSyncobj = this.global.latestSyncobj.virtual;
					// this.syncStatus = this.global.gatewaySyncStatus.virtual;
					// this.syncStatusUgl = this.global.gatewayUGLSyncStatus.virtual;
					// this.virtualSyncStatus = this.global.gatewayVirtualSyncStatus.virtual;
				}

				if(response.data.gateway_id === 8){

					if(response.data.sync_mode == 1){
						this.global.gatewaySyncStatus.virtual = response.data.status + 4;
					}else if(response.data.sync_mode == 2){
						this.global.gatewayVirtualSyncStatus.virtual = response.data.status + 4;
					}else if(response.data.sync_mode == 3){
						this.global.gatewayUGLSyncStatus.virtual = response.data.status + 4;
					}

					this.global.latestSyncobj.virtual = response.data;

					// this.latestSyncobj = this.global.latestSyncobj.virtual;
					// this.syncStatus = this.global.gatewaySyncStatus.virtual;
					// this.syncStatusUgl = this.global.gatewayUGLSyncStatus.virtual;
					// this.virtualSyncStatus = this.global.gatewayVirtualSyncStatus.virtual;
				}

				// this.undoResponseState = {
				// 	gatewayId: response.data.gateway_id,
				// 	mode: this.emitterValues.mode,
				// 	type: 2,
				// 	code: 200,
				// 	mesage: "success"
				// };
				// this.undoWarinigRef.close();
                // this.undoPopupRef.close();
			}else if(response.code === 400){

				// this.undoWarinigRef.close();
				// this.undoPopupRef.close();
				// this.undoResponseState = {
				// 	gatewayId: this.gatewayId,
				// 	mode: this.emitterValues.mode,
				// 	type: 2,
				// 	code: 400,
				// 	mesage: "failed"
				// };

				// this.allowUndoSyncRespStatus = response.data;
				// this.undoSyncErrorRef = this.modalService.open(this.undoSyncErrorModalElem, {backdrop: 'static'});
			}else if(response.code === 403){
				// this.undoResponseState = {
				// 	gatewayId: this.gatewayId,
				// 	mode: this.emitterValues.mode,
				// 	type: 2,
				// 	code: 403,
				// 	mesage: "Unsync operation already running."
				// };
				// this.allowUndoSyncRespStatus = {
				// 	isSyncErp: true
				// };
				// this.undoSyncErrorRef = this.modalService.open(this.undoSyncErrorModalElem, {backdrop: 'static'});

			}

	

		}, error=>{
		
			//this.undoPopupRef.close();
		});
	}

}