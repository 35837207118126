import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {GlobalService} from '../../../../../_services/global.service';
import {BalanceHistoryService} from '../../../../../_services/balance-history-service/balance-history.service';

@Component({
  selector: 'app-cancel-snapshot-sync-popup',
  templateUrl: './cancel-snapshot-sync.component.html',
})
export class CancelSnapshotSyncComponent implements OnInit, OnDestroy {

  @Input() job: any = null;
  cancleStatusCode = 0;
  cancelErrorMessage = null;

  constructor(public activeModal: NgbActiveModal, public global: GlobalService, public snapshotService: BalanceHistoryService) {}
  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  confirmSnapshotCancel() {
    this.cancleStatusCode = 1;
     this.snapshotService.stopSnapstopSync(this.global.currentPortfolioId, this.job.id)
       .subscribe( res => {
            this.activeModal.close(res);
         this.cancleStatusCode = 2;
       } , error => {
         this.cancleStatusCode = 3;
         this.cancelErrorMessage = 'Enouner an error. please contact the support';
         console.error(error);
       })

  }
}
