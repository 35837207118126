import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';


@Component({
	selector: 'app-invoice-status',
	templateUrl: './invoice-status.component.html'
})

export class InvoiceStatusComponent implements OnInit {

    @Input() status;

    constructor(){

    }

    ngOnInit(){
        
    }

}