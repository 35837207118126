<div class="modal-header">
    <h4 class="modal-title pull-left">Sync Options</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.close(false);">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <div class="form-group">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="emitterValues.resyncFails" />
                    <label class="form-check-label">
                        Retry Failed Transactions (Highly recommended)
                    </label>

                </div>
                <small>Retry transaction that failed to import previously.</small>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="emitterValues.resyncIgnores" />
                    <label class="form-check-label">
                        Reconsider Ignored Transactions
                    </label>

                </div>
                <small>Reconsider ignored transaction records from Cryptoworth and attempt to re-sync. </small>
            </div>

            <div class="form-group">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="emitterValues.resyncUpdate" />
                    <label class="form-check-label">
                        Update old journals
                    </label>

                </div>
                <small>Re-sync journals that need to be updated.</small>
                <br />
                <small class="btn-outdated" *ngIf="hasUpdated">( There are journals that need to be updated.
                    )</small>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <p>Please enter memo in the text box below.</p>
                    <div class="form-group">
                        <input class="form-control" placeholder="memo" type="text" [(ngModel)]="memo">
                    </div>
                </div>
        
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary btn-lg" (click)="goToSync()" *ngIf="!sendRequest">
        Sync
    </button>
    <button type="button" class="btn btn-primary btn-lg disabled" disabled *ngIf="sendRequest">
        <img class="loader-icon ml-3" src="{{global.FE_IMG_URL}}img/payment/loader.gif"> Sync
    </button>
</div>