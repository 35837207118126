import { Component, OnInit, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { GlobalService } from '../../../_services/global.service';
import { CoinService } from '../../../_services/coin.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
	selector: 'app-onboarding-content',
	templateUrl: './onboarding-content.component.html'
})
export class OnboardingContentComponent implements OnInit  {

	@Input() info = null;
	@Input() mode = 1;
	
	@Output() windowState = new EventEmitter();

	modal = null

	constructor (public global: GlobalService, public coinService:CoinService, public route:ActivatedRoute, public modalService: NgbModal, 
		private renderer: Renderer2) {
	}

	ngOnInit() {
		if(!this.global.skipOnboarding){
			this.openOnboardingPopup()
		}
	}

	openModal(modal){
		this.modal = this.modalService.open(modal)
	}

	modalRef = null

	goToOnboardingStep(step){
		this.global.onboardingMode = 1
		this.global.currentStepInfo = step
		this.global.currentOnboardingStep = step.id
	}

	openOnboardingPopup(){
		if(this.global._userDetails['onboarding_stage'] > -1){
			if(this.global.perSessionShowOnboarding==null || !this.global.perSessionShowOnboarding)
				this.showOnboardingPopup()
		}	
	}

	goToOnboardingStepNext(){
		this.global.onboardingMode = 1
		if(this.global.currentStepInfo.id + 1 < this.global.tutorialSteps.length){
			console.log(this.global.tutorialSteps[this.global.currentStepInfo.id + 1])
			this.goToOnboardingStep(this.global.tutorialSteps[this.global.currentStepInfo.id + 1])
		} else {
			// this.global.showOnboarding = false;
			this.global._userDetails['onboarding_stage'] = -1
		}
	}

	showOnboardingPopup(){
		this.global.skipOnboarding = false
		this.global.showOnboarding = true

	}

	skipOnboardingStep(){
		this.global.showOnboarding = false;
		this.global.skipOnboarding = true
	}

	onboardingMarking = false
	markOnboarding(){
		this.onboardingMarking = true
		this.global.httpGET('onboarding_mark', {
			onboardingStep:this.global.currentStepInfo.id,
			totalStepCount:this.global.tutorialSteps.length
		})
		.subscribe(response => {
			this.onboardingMarking = false
			this.goToOnboardingStepNext()
			if(response.data !== -1)
				this.global._userDetails['onboarding_stage'] = this.global.currentStepInfo.id
			else {
				this.global._userDetails['onboarding_stage'] = -1
				setTimeout(() => {
					this.global.doHeartBeat()
					this.global.showOnboarding = false;
				}, 2000);
			}
		}, error => {
			this.onboardingMarking = true
			
		});
	}

	closeOnboarding(){
		if(this.modalRef != null)
			this.modalRef.close()
		this.skipOnboardingStep()
	}

	minimizeOnboarding(){
		console.log('minimize');
		this.global.onboardingWindowState = 0
		this.windowState.emit(0)
		console.log(this.global.onboardingWindowState)
	}

	sidePopupOnboarding(){
		//this.global.onboardingWindowState = 1
		if(this.global.onboardingWindowState == 0)
			this.global.onboardingWindowState = 1
		else {
			if(this.global.onboardingWindowState == 2){
				this.emitCompressOnboarding()
			} else {
				this.global.onboardingWindowState = 0
			}
		}
	}

	modalRefTwo = null
	skipOnboardingConfirm(modal){
		this.modalRefTwo = this.modalService.open(modal)
	}

	emitMaximizeOnboarding(){
		console.log('maximize emit');
		this.windowState.emit(2)
	}

	emitMiniimizeOnboarding(){
		this.windowState.emit(1)
	}

	emitCompressOnboarding(){
		this.windowState.emit(1)
	}

}
