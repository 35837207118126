import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { Observable, of } from 'rxjs';


@Injectable()
export class NotificationService {;

	constructor(private global: GlobalService
	) {

		
	}

	showNotification(){
		
	}

	

	



	

}
