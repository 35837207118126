import { Component, ViewChild } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CoinService } from '../../_services/coin.service';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html'
})


export class SideNavBarComponent { 

	@ViewChild('onboardingModal') onboardingModal : any;

	public isdropdownOpen = false

	constructor(public global: GlobalService, public route:ActivatedRoute, public router:Router, public modalService: NgbModal, public coinService:CoinService, config: NgbDropdownConfig) {
		config.placement = 'top-center';
	}

	ngOnInit() {
		window.scroll(0, 0);
	}

	dropdownOpenClose(state){
		this.isdropdownOpen = state
	}

	modalRef = null
	openModal(modal){
		this.modalRef = this.modalService.open(modal)
	}

	addressBookVisit(modal){
		console.log(this.global.currentPortfolioId)
		if(this.global.currentPortfolioId != 0 && this.global.currentPortfolioId != null){
			this.modalRef = this.modalService.open(modal, { windowClass: 'gr-modal-wide' })
		} else {
			this.coinService.gotoLink('address-book')
		}
	}
	
	closeModal(){
		this.modalRef.close()
	}
	
	openShareFeedbackModal(modal){
		this.feedbackSent = 0
		this.modalRef = this.modalService.open(modal)
	}

	feedbackDescription = null
	sendingFeedback = false
	feedbackSent = 0
	sendFeedback(){
		this.sendingFeedback = true
		this.global.httpPOST('customer_feedback',{
			portfolioId:this.global.currentPortfolioId,
			feedback:this.feedbackDescription,
			url:window.location.href
		}).subscribe(response => {
			this.sendingFeedback = false
			this.feedbackDescription = null
			this.feedbackSent = 1
			
		}, error => {
			this.feedbackSent = -1
			this.sendingFeedback = false
		});
	}

	openLayoutModal(modal){
		this.modalRef = this.modalService.open(modal, {size:'lg'})
	}

	showOnboardingPopup(){
		this.global.skipOnboarding = false
		this.global.showOnboarding = true
		this.global.onboardingWindowState = 1
		console.log('called')

	}

}

