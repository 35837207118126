import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, throwError} from 'rxjs';
import { GlobalService } from '../../_services/global.service';
import { finalize, share, tap } from 'rxjs/operators';
import { CoinService } from '../coin.service';
@Injectable({
	providedIn: 'root'
})

export class GatewayServiceService {

  private messageSource = new BehaviorSubject(1);
  currentMessage = this.messageSource.asObservable();

  onChangeTab(message: any) {
    this.messageSource.next(message)
  }
	constructor(private global: GlobalService, private coinService: CoinService) {
		this.coinService.onChangePortfolio$.subscribe( res => {
			if (res !== null &&  this.portfolioId !== res) {
				this.classificationMapRes = null;
				this.erpDefaultAccountsRes = null;
				this.classificationList = null;
			}
		})
	 }

	private classificationList = null;
	private readonly VIRTUAL_GATEWAY_ID = 6;
	private portfolioId = null;

	private cachedClassificationMapObservable: Observable<any>;
	private classificationMapRes = null;

	private cachedErpDefaultAccountObservable: Observable<any>;
	private erpDefaultAccountsRes = null;

	private cachedObservable: Observable<any>;

	public duplicateObservable$  = new Subject();

  public erpMetaDataSyncEmitter: EventEmitter<any> = new EventEmitter();

	public initVirtualConnection() {
		// initiate_virtual
		return this.global.httpPOST('initiate_virtual', {
			portfolioId: this.global.currentPortfolioId,
			gatewayId: this.VIRTUAL_GATEWAY_ID
		});
	}

	public getUglAccountMap(gatewayid: number, portfolioId: number) {
		return this.global.httpPOST('get_ugl_account_map', {
			portfolio_id: portfolioId,
			gateway_id: gatewayid
		});
	}

	public getOutdateErpTxMap(gatewayId, txIds = []) {
		// get_journal_map_outdate

		const options = {
			'portfolio_id': this.global.currentPortfolioId,
			'gateway_id': gatewayId,
			'txIds': txIds

		}

		return this.global.httpPOST('get_journal_map_outdate', options);
	}

	public rollbackTransactions(portfolioId, gatewayid, txIds = [], all = false, dropTxns= [], filters = null, quick = false, mode=1) {

		return this.global.httpPOST('roll_back_transaction', {
			portfolio_id: portfolioId,
			gateway_id: gatewayid,
			txIds,
			all,
			dropTxns,
			filters,
			quick,
      mode
		});
	}

	public getUglConfigs(gatewayid) {
		// get_ugl_map_config
		return this.global.httpPOST('get_ugl_map_config', {
			portfolio_id: this.global.currentPortfolioId,
			gateway_id: gatewayid,
		})
	}

	public saveUglConfigs(gatewayid, configs) {
		// get_ugl_map_config
		return this.global.httpPOST('update_ugl_map_config', {
			portfolio_id: this.global.currentPortfolioId,
			gateway_id: gatewayid,
			configs
		})
	}


	public deleteUglAccountMap(gatewayid: number, portfolioId: number, mapData) {
		return this.global.httpPOST('delete_ugl_account_map', {
			portfolio_id: portfolioId,
			gateway_id: gatewayid,
			map_data: mapData
		});
	}

	public updateUglAccountMap(gatewayid: number, portfolioId: number, mapData) {
		return this.global.httpPOST('update_ugl_account_map', {
			portfolio_id: portfolioId,
			gateway_id: gatewayid,
			map_data: mapData
		});
	}

	// portfolio_rollback_erp_from_history
	public rollbackSyncByCalcId(id, gatewayId, connectionId, mode, taxId) {
		return this.global.httpPOST('portfolio_rollback_erp_from_history', {
			id,
			portfolioId: this.global.currentPortfolioId,
			gatewayId: gatewayId,
			taxId,
			gatewayConnectionId: connectionId,
			syncMode: mode
		});
	}

	public undoSync(gatewayid: number, portfolioId: number, taxId, gatewayConnectionId: number, fromDate: string, toDate: string, all = true, sync_mode, memo= null) {
		return this.global.httpPOST('undo_gateway_sync', {
			portfolioId,
			gateway_id: gatewayid,
			taxId,
			gatewayConnectionId,
			from_date: fromDate,
			to_date: toDate,
			all,
			sync_mode,
			memo
		});
	}

	public getFailedTxCount(portfolioId, portfolio_tax_id, gateway_id) {
		// erp_failed_tx_count

		return this.global.httpGET('erp_failed_tx_count', {
			portfolioId,
			portfolio_tax_id,
			gateway_id
		});
	}

	public getLatestErpStatus() {
		return this.global.httpPOST('portfolio_lastest_erp_status', { portfolioId: this.global.currentPortfolioId });
	}

	public getErpSyncStatus(portfolioId: number, portfolio_tax_id: number | null = null) {

		const options = {
			portfolioId
		};

		if (portfolio_tax_id !== null) {
			options['portfolio_tax_id'] = portfolio_tax_id;
		}
		return this.global.httpGET('gateways_sync_statuses', options);
	}

	public getUndoSyncDetails(portfolioId: number, gatewayid: number, gatewayConnectionId: number) {

		return this.global.httpGET('gateways_undo_sync_statuses', {
			portfolioId,
			gateway_id: gatewayid,
			gatewayConnectionId
		});
	}

	public updateAssetMap(portfolioId, gatewayId, connection, asset, uglMap, defaultAccount, nftAccount, defaultGain, defaultLoss, defaultFee, bankAccount) {

		return this.global.httpPOST('add_asset_map', {
			portfolioId: portfolioId,
			gatewayId: gatewayId,
			connection: connection,
			defaultAccount: defaultAccount,
			defaultNftAccount: nftAccount,
			defaultGainAccount: defaultGain,
			defaultLossAccount: defaultLoss,
			defaultFeeAccount: defaultFee,
      defaultBankAccount: bankAccount,
			map: asset,
			uglMap: uglMap
		});
	}

	public getAssetMap(params: any) {
		return this.global.httpGET('get_asset_map', {
			portfolioId: params.portfolioId,
			gatewayId: params.gatewayId,
		});
	}

	public removeAssetGroup(assetGroupId: number) {
		return this.global.httpPOST('remove_map_group', {
			groupId: assetGroupId
		});
	}

	public removeAsset(assetGroupId: number, assetId: number) {
		return this.global.httpPOST('remove_asset_from_group', {
			groupId: assetGroupId,
			assetId: assetId
		});
	}

	public getClassificationMap(gatewayId: number, portfolioId: number) {
		// get_classification_map
		if (this.portfolioId !== this.global.currentPortfolioId) {
			this.classificationMapRes = null
			this.portfolioId = this.global.currentPortfolioId;
		}

		let observable: Observable<any>;
		if (this.classificationMapRes !== null) {
			observable = of(this.classificationMapRes);
		} else if (this.cachedClassificationMapObservable) {
			observable = this.cachedClassificationMapObservable;
		} else {
			this.cachedClassificationMapObservable = this.global.httpGET('get_classification_map', {
				gateway_id: gatewayId,
				portfolioId: portfolioId,
			}).pipe(
				tap(res => this.classificationMapRes = res),
				share(),
				finalize(() => this.cachedClassificationMapObservable = null)
			  );

			observable = this.cachedClassificationMapObservable;
		}
		return  observable;
	}

	public clearClassificationMapCache() {
		this.classificationMapRes = null;
	}

	public clearDefaultAccountCache() {
		this.erpDefaultAccountsRes = null;
	}
	public getErpDefaultAccounts(gatewayId) {
		let observable: Observable<any>;

		if (this.portfolioId !== this.global.currentPortfolioId) {
			this.erpDefaultAccountsRes = null
			this.portfolioId = this.global.currentPortfolioId;
		}

		if (this.erpDefaultAccountsRes !== null) {
			observable = of(this.erpDefaultAccountsRes);
		} else if (this.cachedErpDefaultAccountObservable) {
			observable = this.cachedErpDefaultAccountObservable;
		} else {
			this.cachedErpDefaultAccountObservable = this.global.httpGET('erp_default_accounts', {
				gateway_id: gatewayId,
				portfolioId: this.global.currentPortfolioId,
			}).pipe(
				tap(res => this.erpDefaultAccountsRes = res),
				share(),
				finalize(() => this.cachedErpDefaultAccountObservable = null)
			  );
			  observable = this.cachedErpDefaultAccountObservable;
		}

		return  observable;
	}
	// onPressResync

	public erpResync(portfolioId, transactionIds, gateway_id, mode = 1, filters = null, quick = false) {
		const options = {
			portfolioId,
			gateway_id,
			mode,
			quick
		};

		if (transactionIds.length > 0) {
			options['transactionIds'] = transactionIds;
		} else {
			options['filters'] = filters;
		}

		return this.global.httpPOST('erp_resync', options);
	}

	public getSubsidiaries(gatewayConnectionId, portfolioId) {
		return this.global.httpGET('ons_subsidiary', {
			gatewayConnectionId,
			portfolioId
		});
	}

	public setSubsidary(gateway_id, portfolioId, subsidiary, push_mode) {
		return this.global.httpPOST('ons_subsidiary', {
			gateway_id,
			portfolioId,
			subsidiary,
			push_mode
		});
	}

	public setDefaultTxStrategy(portfolioId, gateway_id, txStrategy) {
		return this.global.httpPOST('ons_subsidiary', {
			gateway_id,
			portfolioId,
			txStrategy
		});
	}

	public clearClassificationCache() {
		this.classificationList = null;
	}
	public getClassification(portfolioId): Observable<any> {
		let observable: Observable<any>;

		if (this.classificationList !== null) {


			observable = of(this.classificationList);
			// subject.complete();

			// return of(this.classificationList);
		} else if (this.cachedObservable) {
			observable = this.cachedObservable;
		} else {

			this.cachedObservable = this.global.httpGET('get_classifications', { portfolioId })
				.pipe(
					tap(res => this.classificationList = res),
					share(),
					finalize(() => this.cachedObservable = null)
				);
			// .subscribe( res=>{
			//   this.classificationList = res.data;
			//   subject.next(res.data);
			//   subject.complete();
			// }, error=>{
			//   throwError(error);
			// });
			observable = this.cachedObservable;
		}

		return observable;

	}

	public removeTxAccountMap(gatewayId, portfolioId, txId) {
		return this.global.httpPOST('remove_tx_account_map', {
			gatewayId,
			portfolioId,
			txId
		});
	}

	public makeSyncRepuest(portfolioId, portfolio_tax_id, gateway_id, gatewayConnectionId, mode, retry = false) {
		this.global.httpPOST('create_gateway_sync_request', {
			portfolioId,
			portfolio_tax_id,
			gateway_id,
			gatewayConnectionId,
			mode,
			retry
		})
	}

	// this is virtual account mapping
	getTxAccountMap(portfolioId, txId): Observable<any> {
		return this.global.httpGET('get_tx_account_map', {
			gatewayeId: 6,
			portfolioId,
			txId
		});
	}

	addTxAccountMapVirtual(portfolioId, txId, creditAccountId, debitAccountId, creditAmount, debitAmount, gainAccountId, lossAccountId) {

		const body = {
			gatewayeId: 6,
			portfolioId,
			txId
		};

		if (creditAccountId !== null) {
			body['creditAccountId'] = creditAccountId;
			body['creditAmount'] = creditAmount;
		}

		if (debitAccountId !== null) {
			body['debitAccountId'] = debitAccountId;
			body['debitAmount'] = debitAmount;
		}

		if (gainAccountId !== null) {
			body['gainAccountId'] = gainAccountId;
		}


		if (lossAccountId !== null) {
			body['lossAccountId'] = lossAccountId;
		}

		return this.global.httpPOST('add_tx_account_map', body);
	}

	public getErpCurrencies() {
		const body = {
			gatewayeId: 3,
			portfolioId : this.global.currentPortfolioId,
		};
		// get_erp_currencies
		return this.global.httpPOST('get_erp_currencies', body);
	}

	public updateErpCurrency(gatewayId, erpAssetId, erpAsset, cwAssetId, mapId= null) {
		// update_erp_currencies
		const body = {
			gatewayeId: 3,
			portfolioId : this.global.currentPortfolioId,
			erpAssetId,
			erpAsset,
			cwAssetId,
			mapId
		};
		return this.global.httpPOST('update_erp_currencies', body);
	}

	public removeErpCurrencyMap(gatewayId, mapId) {
		const body = {
			gatewayeId: 3,
			portfolioId : this.global.currentPortfolioId,
			mapId
		};
		return this.global.httpPOST('remove_erp_currencies', body);
	}

	public removeMappings(gateway_id, classification_id, remove_type) {
		// un_map_erp_cls_accounts

		// portfolio_id
		// gateway_id
		// classification_id
		// remove_type

		const body = {
			gateway_id,
			portfolioId : this.global.currentPortfolioId,
			classification_id,
			remove_type
		};
		return this.global.httpPOST('un_map_erp_cls_accounts', body);
	}

	public duplicateConnectionLevelmapping(gatewayId, groupId, connections) {
		// duplicate_connection_map
		return this.global.httpPOST('duplicate_connection_map', {
			portfolioId: this.global.currentPortfolioId,
			gatewayId: gatewayId,
			groupId: groupId,
			connections: connections,
		});
	}

	public dataSyncRequest(gatewayId, type, syncoptions) {
		return this.global.httpPOST('erp_data_sync', {
			portfolioId: this.global.currentPortfolioId,
			gatewayId: gatewayId,
			type: type,
			options: syncoptions,
		});
	}

	public getErpDataSyncStatus( gatewayId) {

		// get_erp_data_sync_status
		return this.global.httpPOST('get_erp_data_sync_status', {
			portfolioId: this.global.currentPortfolioId,
			gatewayId: gatewayId,
		});
	}

  public syncErpAccounts(gatewayId) {
    return this.global.httpPOST('xero_sync_erp_accounts', {
      portfolioId: this.global.currentPortfolioId,
      gatewayId: gatewayId,
    });

  }

  emitSyncState(data) {
	// console.log('update emmitted')
    this.erpMetaDataSyncEmitter.emit(data);
  }

  public checkHasConnMapping(gatewayId){
    //erp_has_connection_level_map
    return this.global.httpPOST('erp_has_connection_level_map', {
      portfolioId: this.global.currentPortfolioId,
      gatewayId: gatewayId,
    });
  }

}
