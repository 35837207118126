import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, SimpleChanges } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'app/_services/global.service';

@Component({
	selector: 'app-tx-count-warning-popup',
	templateUrl: './tx-count-warning-popup.component.html'
})
export class TxCountWarningPopup {

    @Input() txCount = 0;
    @Input() gatewayId = null;
    public erp = 'Qbo';
    
    constructor(public activeModal: NgbActiveModal, public global: GlobalService,  public modalService: NgbModal) {
        
    }
    
    ngOnChanges(): void {
        console.log(this.gatewayId)
        //throw new Error('Method not implemented.');
    }

}