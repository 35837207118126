import { Injectable} from '@angular/core';

@Injectable()
export class LoaderService {

	public show = false

	public trueShow = false

	public quotes = [
		{
			quote: "In the balance of life, accounting is the equilibrium that keeps everything in check.",
			author: "Olivia Martinez",
		},
		{
			quote: "Accounting is not just about counting beans; it’s about making every bean count.",
			author: "William Reed",
		},
		{
			quote: "The best accountants are not just number-crunchers; they’re financial storytellers.",
			author: "Jessica Turner",
		},
		{
			quote: "In the world of finance, the best accounting is like poetry—precise, impactful, and timeless.",
			author: "Daniel Adams",
		},
		{
			quote: "The best accountants don’t just see numbers; they see the potential for financial transformation.",
			author: "Samantha Wilson",
		},
		{
			quote: "Accounting is the art of turning chaos into clarity and confusion into financial wisdom.",
			author: "Michael Carter",
		},
		{
			quote: "The best accountants are the architects of financial legacies.",
			author: "Laura Anderson",
		},
		{
			quote: "In the world of finance, the best accountants are the sculptors of economic success.",
			author: "Richard Turner",
		},
		{
			quote: "Accounting excellence is not just a skill; it’s a mindset of financial mastery.",
			author: "Melissa Davis",
		},
		{
			quote: "The best accountants don’t just balance books; they balance the scales of financial justice.",
			author: "Brian Clark",
		},
		{
			quote: "In the symphony of financial excellence, the best accountants are the conductors of prosperity.",
			author: "Jennifer Lewis",
		},
		{
			quote: "To be the best accountant is to be the custodian of financial integrity.",
			author: "Thomas Mitchell",
		},
		{
			quote: "The best accountants don’t just interpret numbers; they decipher the language of financial success.",
			author: "Sarah Brown",
		},
		{
			quote: "Accounting greatness is not just about what you calculate; it’s about the financial stories you unfold.",
			author: "Christopher Miller",
		},
		{
			quote: "The best accountants don’t just see columns and rows; they see the roadmap to financial triumph.",
			author: "Amanda Green",
		},
		{
			quote: "In the world of finance, the best accountants are the architects of economic resilience.",
			author: "Robert Johnson",
		},
		{
			quote: "To be the best accountant is to be the keeper of financial truths.",
			author: "Michael Johnson",
		},
		{
			quote: "Accounting brilliance is not just about accuracy; it’s about infusing financial narratives with wisdom.",
			author: "Emily White",
		},
		{
			quote: "The best accountants don’t just manage money; they mold financial destinies.",
			author: "David Taylor",
		},
		{
			quote: "In the symphony of financial wisdom, the best accountants are the virtuosos of economic insight.",
			author: "Melissa Davis",
		},
		{
			quote: "Accounting is the language of business.",
			author: "Warren Buffett",
		},
	];

  	constructor( ){
	
	}

	showLoader(){
		this.show = true

		setTimeout(() => {
			if(this.show){
				this.trueShow = true
			}
		}, 2000);
	}

	hideLoader(){
		this.show = false
		this.trueShow = false
	}

	
}
