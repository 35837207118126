import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/_services/global.service';
import { ActivatedRoute, Router, Params, RouterStateSnapshot} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CoinService } from 'app/_services/coin.service';

@Component({
  selector: 'app-xero-callback',
  templateUrl: './xero-callback.component.html'
})
export class XeroCallbackComponent implements OnInit {

  public status:number = 0;
  public type = null;
  public users = [];
  public accessToken = null;
  public selectedUser = null;

  private code = null;
	constructor(public global: GlobalService, public modalService: NgbModal, public coinService:CoinService, private router:Router, public route:ActivatedRoute) {
	}

  ngOnInit() : void {

    this.route.queryParams.subscribe(params => {
      this.getCode(params)
    });
  }

  public getCode(params:any){
    this.status = 1;
    let portfolioId = this.global._cookieService.get('current_portfolioId');
    let stateArr = params.state.split(':');
    let typeArr = stateArr[2].split('-');
    this.type = typeArr[1];

    this.code = params.code;
    if(typeArr[1] == 1){
      let portfolioDetails = stateArr[1].split('-');

      if(!this.global.isAuthenticated()){

      	this.global._cookieService.delete('oauth2Data', '/')
      	this.global.logoutFinishStage();

      } else {

        this.global.httpPOST('xero_v_code',{
          'portfolio_id': portfolioDetails[1] !== undefined ? portfolioDetails[1] : portfolioId,
          'code' : params.code
        }).subscribe( response=>{
          if(response.status){
            this.status = 2;
            this.global.connectionDisplay = 6
          
            setTimeout( () => { 
              this.router.navigate(['entity', portfolioId, 'connections']);
            }, 500);
            
          }else{
            this.status = 3;
            setTimeout( () => { 
              this.router.navigate(['entity', portfolioId, 'connections']);
            }, 500);
          }
    
        }, error=>{
          console.error(error);
          this.status = 3;
        });
      }
    }else if(typeArr[1] == 2 ){
      console.log("get access token")
      //get_xero_access_token
      this.global.httpPOSTNoAuth('get_xero_access_token',{
        'code' : params.code
      }).subscribe( res=>{
         if( res.status === true){
            this.status = 2;
            this.users = res.data.users;
            this.accessToken = res.data.access_token;
         }else{
            this.status = 3;
         }
      }, error=>{
        this.status = 3;
        setTimeout( () => { 
          this.router.navigate(['entity', portfolioId, 'gateways']);
        }, 500);
      });
    }
  }

  public onSend = false;
  public onSelectUser(){
    this.onSend = true;
    this.global.httpPOSTNoAuth('login/social',{
      'authToken' : this.accessToken,
      'email' : this.selectedUser.EmailAddress,
      'name' : this.selectedUser.FirstName+" "+this.selectedUser.LastName,
      'idToken' : this.code,
    }).subscribe ( res=>{
 
      this.global.doLoginPreRequisites(res.data);
      this.onSend = false;
      this.global.loginDivState = 202;
    }, error=>{
      this.onSend = false;
    });
  }
}
