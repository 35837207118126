import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, SimpleChanges } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'app/_services/global.service';

@Component({
	selector: 'app-gateway-sync-warning-popup',
	templateUrl: './gateway-sync-warning-popup.component.html'
})
export class SyncWarningPopup implements OnInit {

    @Input() gatewayId;
    @Input() txCount = 0;
    
    constructor(public activeModal: NgbActiveModal, public global: GlobalService,  public modalService: NgbModal) {
        
    }

    ngOnInit(): void {
        //throw new Error('Method not implemented.');
    }

}