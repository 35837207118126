<div class="modal-header">
    <h4 class="modal-title pull-left">App Error</h4>
    <button type="button" class="close pull-right" aria-label="Close"
        (click)="activeModal.close(false);">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h5 class="text-warning"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Error!
    </h5>
    <p>
        Please configure the app before starting the syncronization. Make sure default account, default
        capital gain and loss accounts are mapped accurately.
    </p>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-primary btn-lg"
        (click)="activeModal.close(false);">
        Go to Apps
    </button>

</div>