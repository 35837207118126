import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address-contact-info',
  templateUrl: './address-contact-info.component.html'
})
export class AddressContactInfoComponent implements OnInit {

	@Input() addressInfo: any = null; 
	
	constructor(public global: GlobalService, public coinService:CoinService, public modalService: NgbModal) {
	}

	ngOnInit(): void {
	}

	

}
