<!-- <div class="row"  *ngIf="global.showLoaderStatus">
	<div class="interceptor-loader">
		<div class="col-md-12">
			<img src='assets/img/logo/emblem2.png' width="140px"  class="img-center" alt="">
			<img class="card-icons" src="{{global.FE_IMG_URL}}img/global_loader_w.gif"  height="50x" width="50"> 
		</div>
	</div>
</div> -->

<div class="inner-application-loading-container" *ngIf="global.showLoaderStatus">
	<img src='assets/img/logo/emblem2.png' width="140px" class="mb-4"  alt="">

	<div class="quote--container fade-in" *ngIf="quoteIndex > -1">
		<p class="quote">
			{{global.loaderService.quotes[quoteIndex]['quote']}}
		</p>
		<p class="quote--author">&ndash; 
			<span class="text-primary">
				{{global.loaderService.quotes[quoteIndex]['author']}} 
			</span>
		</p>
	</div>
	<div class="inner-application-loading-box fade-in-late">
		<div class="interceptor-loader">
		<img class="card-icons" src="{{global.FE_IMG_URL}}img/global_loader_w.gif"  height="50x" width="50">
		</div>
	</div>
</div>