import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {ChangeDetectorRef} from '@angular/core'

import {GlobalService} from '../../../_services/global.service';
import {CoinService} from '../../../_services/coin.service';
import {AddressBookService} from '../../../_services/addressbook.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QbService} from 'app/_services/gateways/qb.service';
import { OracleNetSuiteService } from 'app/_services/gateways/ons.service';
import { TransactionsRollUpComponent } from 'app/_components_crypto_one/transactions-page/transaction-roll-up/transaction-roll-up.component';
import { SortService } from 'app/_services/sort.service';
import {count} from 'rxjs/operators';

enum TableMode {
  DefaultMode = 0,
  IgnoredTx = 1,
  ErrorTx = 2
}

@Component({
  selector: 'app-journal-transaction-table',
  templateUrl: './journal-table.component.html',
})
export class JournalTableComponent implements OnInit {

  objectKeys = Object.keys;

  public modalRef;
  public modalRefBackUp;
  public errorCode = null;
  public formState = false;
  public dataFetch = false;
  public currentTransaction
  public journalId = null

  public viewModeTxInfo = 0

  @Input() mainMode: TableMode = 0;
  @Input() search = true
  @Input() displayLoc = 0
  @Input() inputTransactions = null
  @Input() inputTotalTransactionCount = null
  @Input('loadingFlag') loadingFlag = false
  @Input() showPagination = true;
  @Input('return-val') returnVal: any | null = null;

  @Output('on-page-change') onPageChangeEmiiter = new EventEmitter();

  public searchLoading = false
  public transactionSalePriceDetails;


  public deleteTransactionId = null;
  public pageMessage = 0
  public deleteTransactionIdIndex = null;


  public totalPages: number = 0;
  public calculatePagesSubscription: any = null
  public addressRefreshSubscription: any = null

  public refreshAddressViewVar = 0;
  private userPortfolioStateChange = null;
  private gatewayLoadSubscriber = null;
  public editBlanaceConsolidationCheckbox = null
  public editTxMultilineConsolidation = null
  public journalLineData = null;
  public journalDate = null;
  public journalNo = null;
  public journalTransactionId = null;
  public journalCreditTotal = null;
  public journalDebitTotal = null;
  public journalCurrency = null;


  constructor(
    private ref: ChangeDetectorRef,
    public modalService: NgbModal,
    public coinService: CoinService,
    public global: GlobalService,
    public addressBookService: AddressBookService,
    public qbService: QbService,
    public sortService: SortService,
    public onsService: OracleNetSuiteService) {

  }

  ngOnInit() {
    // this.coinService.tablePageNumber = 1;
    // this.coinService.tableSelectedPageNumber = 1
    if (this.global._transactions != null && this.global._transactions.length > 0) {
      this.loadingFlag = true
      setTimeout(() => {
        this.loadingFlag = false
      }, 500);
    }

    // this.coinService.txTableDeepSearch.mode = 0
    // this.coinService.txTableDeepSearch.query = null;
    this.coinService.userDeepSearched = false

    this.calculatePagesSubscription = this.coinService.recalculatePages.subscribe(isValid => {
      this.getPageCount()
    });

    this.addressRefreshSubscription = this.addressBookService.addressLinkRefresh.subscribe(isValid => {
      this.refreshAddressLink()
    });

    this.global.removeAllLogs()

    this.userPortfolioStateChange = this.global.userPortfolioSettingsStateChange.subscribe(val => {
      // on apply filter
      console.log("on apply filter")
      this.coinService.tablePageNumber = 1;
      this.coinService.tableSelectedPageNumber = 1;
      this.getPageCount();
      //this.coinService.resetQuickSearch()
    });

    this.gatewayLoadSubscriber = this.global.gatewasyLoadingEmitter.subscribe(val => {
      // console.log("gateways load", val);
    });

  }

  ngOnChanges(changes: SimpleChanges) {

    for (let i in changes) {
      if (i == 'inputTotalTransactionCount') {
        console.log('inputTotalTransactionCount', changes[i].currentValue);
        if (changes[i].previousValue !== changes[i].currentValue && changes[i].currentValue !== undefined) {
          this.getPageCount();
        }
      }

      if (i == 'loading-flag') {
        console.log('loading-flag', changes[i])
        this.coinService.bknTxReloading = changes[i].currentValue;
      }
    }
  }

  sortTable(field, order, isCalc = false){
    this.sortService.changeTxSort(field, order);
    if (!isCalc) {
			this.coinService.getPortfolioTransactions()
		} else {
			this.coinService.getPortfolioTransactionsForCalculations()
		}
  }

  afterResync(){
	this.global.refreshTransactionsEmitter.emit(true);
	}

  public onUpdateTx(data) {

    this.inputTransactions[data.index] = data.tx;
  }

  refreshAddressLink() {
    this.refreshAddressViewVar++
  }

  showMissingInventory(transaction) {
    if ((transaction['transaction_type'] == 'send' || transaction['transaction_type'] == 'sell')) {
      if (transaction.fiat_fetched == false && transaction.is_ignored != 1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  showNoMarketData(transaction, atCostBasisColumn = false) {
    if ((transaction['transaction_type'] == 'receive' || transaction['transaction_type'] == 'buy')) {
      if (transaction.fiat_fetched == 0 && transaction.is_ignored != 1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  showNoMarketDataSend(transaction, atCostBasisColumn = false) {
    if ((transaction['transaction_type'] == 'send' || transaction['transaction_type'] == 'sell') && transaction['cost_basis_sale_price'] == null) {
      if (atCostBasisColumn) {
        return false
      }

      if (transaction.fiat_fetched == 0 && transaction.is_ignored != 1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  ngOnDestroy() {
    if (this.calculatePagesSubscription != null) {
      // console.log('emmiter unsub: count pages')
      this.calculatePagesSubscription.unsubscribe()
    }

    if (this.addressRefreshSubscription != null) {
      // console.log('emmiter unsub: caddress refresh')
      this.addressRefreshSubscription.unsubscribe()
    }

    if (this.userPortfolioStateChange != null) {
      this.userPortfolioStateChange.unsubscribe();
    }

    if (this.gatewayLoadSubscriber) {
      this.gatewayLoadSubscriber.unsubscribe();
    }
  }

  onSelectPageSize() {
    this.coinService.tableTotalPages = Math.ceil(this.inputTotalTransactionCount / this.coinService.tablePageSizeNumber);
  }

  getPageCount() {

    if (this.mainMode == TableMode.DefaultMode) {
      // a global tx display
      this.inputTotalTransactionCount = this.global._transactionCount;


    }
    //this.coinService.tableSelectedPageNumber =1;
    this.coinService.tableTotalPages = Math.ceil(this.inputTotalTransactionCount / this.coinService.tablePageSizeNumber);

  }

  subloading = false

  pageNav(direction: number) {
    //this.global.selectedTx = [];
    // console.log(this.coinService.bknTxReloading)
    if (this.coinService.bknTxReloading) {
      return false
    } else {

      console.log('click loading')
      this.global.removeAllLogs()
      this.coinService.tableOffset = this.coinService.pageNavSetup(direction)

      if (this.mainMode == TableMode.IgnoredTx) {
        let emitval = {
          'mode': this.mainMode,
          'page': this.coinService.tablePageNumber,
          'returnval': this.returnVal
        };
        this.onPageChangeEmiiter.emit(emitval);
        this.coinService.bknTxReloading = false;
      } else if (this.mainMode == TableMode.ErrorTx) {
        let emitval = {
          'mode': this.mainMode,
          'page': this.coinService.tablePageNumber,
          'returnval': this.returnVal
        };
        this.onPageChangeEmiiter.emit(emitval);
        this.coinService.bknTxReloading = false;

      } else {
        this.coinService.pageNav(this.coinService.tableOffset);

      }
    }

  }

  onChangePage(changeSize=false) {


    if ( (this.coinService.tableSelectedPageNumber !== null
      && this.coinService.tableSelectedPageNumber <= this.coinService.tableTotalPages
      && this.coinService.tableSelectedPageNumber !== this.coinService.tablePageNumber
      && this.coinService.tablePageNumber > 0) || changeSize) {
      this.coinService.tablePageNumber = this.coinService.tableSelectedPageNumber;

      // console.log('test 3')
      this.coinService.tableOffset = this.coinService.tablePageSizeNumber * (this.coinService.tablePageNumber - 1);

      if (this.mainMode == TableMode.IgnoredTx) {
        let emitval = {
          'mode': this.mainMode,
          'page': this.coinService.tablePageNumber,
          'returnval': this.returnVal
        };
        this.onPageChangeEmiiter.emit(emitval);
      } else {
        this.coinService.journalPageNav(this.coinService.tableOffset)
      }

    } else {
       console.log('test')
    }
  }

  public onLoadingClasses = false;
  public qboClasses = [];
  public gatewayId = null;
  ngAfterContentInit() {

    this.global.checkGatewayConnections()
		.subscribe( response=>{

			this.global.connectedGateways = response.data;
      for (let i in this.global.connectedGateways) {

        if (this.global.connectedGateways[i].id == 1) {
          if (this.global.connectedGateways[i].connection) {
            this.gatewayId = 1;
            this.onLoadingClasses = true;
            let timeout = setTimeout(() => {
              this.loadQbClasses(this.global.connectedGateways[i].connection.connection_id);
              this.loadQBDepartments();
              clearTimeout(timeout);
            }, 2000)
            break;
          }

        }else if(this.global.connectedGateways[i].id == 3){

          if (this.global.connectedGateways[i].connection) {

            this.gatewayId = 3;
            let timeout = setTimeout(() => {
              this.getONSClasses();
              this.getONSDepartments();
              clearTimeout(timeout);
            }, 2000);
            break;
          }
        }
      }

		}, error=>{

		});

  }

  public getONSClasses(){
    this.onLoadingClasses = true;
		this.onsService.getOnsClasses(this.global.currentPortfolioId)
		.subscribe( resp=>{
      let list = [];
      for(let cl of resp.data){
          list.push({
          'Id': cl.class_id.toString(),
          'FullyQualifiedName': cl.class_name
          })
      }
      this.qboClasses = list;
		}, error=>{

		}, ()=>{
      this.onLoadingClasses = false;
      this.coinService.fetchingQBOClasses = false;
		})
	}

	public getONSDepartments(){
    this.coinService.fetchingQBODepartments = true;

		this.onsService.getDepartments(this.global.currentPortfolioId)
		.subscribe( res=>{
      let list = [];
      for(let dep of res.data){
          list.push({
          'Id': dep.department_id.toString(),
          'FullyQualifiedName': dep.department_name
          })
      }
      this.qbDepartments = list;
		}, error=>{}, ()=>{
			this.coinService.fetchingQBODepartments = false;
		})
	}

  public classificationChangeDetection(value, transactionId) {
    // console.log(value)
    // this.global.appSettingsStateChange.emit(true)

    if(value !== null){
      this.inputTransactions.forEach(element => {
        if(element.id == transactionId){
          element.classification_id = value['id']
          element.is_ignored = value['is_ignored']
          element.non_taxable = (value['non_taxable'] == 0)?null:1
        }
      });
    }


    //this.global.refreshTransactionsEmitter.emit(true);
  }

  private loadQbClasses(connectionid) {
    this.onLoadingClasses = true;
    this.coinService.fetchingQBOClasses = true;
    this.qbService.getQbClasses(this.global.currentPortfolioId, connectionid)
      .subscribe(resp => {

        this.qboClasses = resp.data;
        this.onLoadingClasses = false;
      }, error => {
        this.onLoadingClasses = false;
      }, ()=>{
        this.coinService.fetchingQBOClasses = false;
      });
  }

  public qbDepartments = [];

  private loadQBDepartments(){
    this.coinService.fetchingQBODepartments = true;

    this.qbService.getQbDepartments(1)
    .subscribe( res=>{

        this.qbDepartments = res.data;
    }, error=>{},
    ()=>{
      this.coinService.fetchingQBODepartments = false;
    });
  }

  selectAllFilteredTx() {

    if (!this.coinService.quickSearchMode) {
      if (this.global.selectFullTx) {
        this.global.selectFullTx = false
      } else {
        this.global.selectFullTx = true
      }
      this.global.selectFullDropTxns = []
      this.global.selectedTx = []
    }
    //console.log(this.global.selectFullTx)

  }

  backUpFilters = null

  applyDeepSearch(loadMore = false) {

    this.global.removeAllLogs()
    // clone the filters object and get transactions via the modified filters object
    let cloneFilterObj = {...this.global.defaultTxFilter}
    this.coinService.backUpFilters = this.global.userPortfolioSettings.txtable_filters

    if (this.coinService.txTableDeepSearch.mode == 0) {
      let getObj = {
        portfolioId: this.global.currentPortfolioId,
        q: this.coinService.txTableDeepSearch.query,
        sm: this.coinService.txTableDeepSearch.mode,
        viewSettings: this.global.appSettings.txtable_columns
      }

      if (loadMore) {
        this.searchModeLoading = true
        getObj['skip'] = this.global._transactions.length
      } else {
        this.noMoreSearch = false
        this.coinService.quickSearchMode = true
        this.searchLoading = true
      }

      this.global.httpGET('search_transactions', getObj).subscribe(response => {

        this.coinService.userDeepSearched = true
        if (response.status == true) {
          if (response.data.totalCount != null)
            this.coinService.userDeepSearchTotalResultCount = response.data.totalCount

          if (loadMore) {
            this.searchModeLoading = false

            if (response.data.transactions.length < 100) {
              this.noMoreSearch = true
            }
            // append to existing
            Array.prototype.push.apply(this.global._transactions, response.data.transactions);
          } else {
            this.global._transactions = response.data.transactions

          }
        }

        this.searchLoading = false
      }, error => {
        this.searchLoading = false
      });

    } else {
      if (this.coinService.txTableDeepSearch.mode == 1) {
        // txId Address
        cloneFilterObj.transactionId = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 2) {
        // From Address
        cloneFilterObj.inputAddress = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 3) {
        // To Address
        cloneFilterObj.outputAddress = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 4) {
        // Contract Address
        cloneFilterObj.contractAddress = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 5) {
        // NFT name
        cloneFilterObj.NFTName = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 6) {
        // NFT symbol
        cloneFilterObj.NFTSymbol = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 7) {
        // crypto symbol
        cloneFilterObj.cryptoSymbolText = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 8) {
        // trade id
        cloneFilterObj.tradeId = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 9) {
        // fiat symbol
        cloneFilterObj.assetSymbols = [this.coinService.txTableDeepSearch.query]

      } else if (this.coinService.txTableDeepSearch.mode == 10) {
        // crypto id
        cloneFilterObj.cryptoId = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 11) {
        // ledger id
        cloneFilterObj.ledgerId = this.coinService.txTableDeepSearch.query

      } else if (this.coinService.txTableDeepSearch.mode == 12) {
        // memo
        cloneFilterObj.memo = this.coinService.txTableDeepSearch.query
      }

      this.global.userPortfolioSettings.txtable_filters = cloneFilterObj
      this.coinService.txFilterApplied = true
      this.coinService.getPortfolioTransactions()

    }


  }


  public searchModeLoading = false
  public noMoreSearch = false
  public selectedTxId = [];
  public hoverTxId = null;


  applyQuickSearch() {

    if (!this.coinService.userDeepSearched) {
      this.global.removeAllLogs()
      let filterValue = this.coinService.txTableDeepSearch.query;

      if (this.coinService.txTableDeepSearch.query == '' || this.coinService.txTableDeepSearch.query == " " || this.coinService.txTableDeepSearch.query == null) {
        this.coinService.quickSearchMode = false
        this.global._transactions = this.coinService.quickSearchTransactionBackup
      } else {
        //if(this.coinService.quickSearchTransactionBackup == null){
        //this.coinService.quickSearchTransactionBackup =  this.global._transactions
        //}
        this.coinService.quickSearchMode = true

        this.global._transactions = this.global._transactions.filter(item =>

          Object.keys(item).some(k =>
            (
              k == 'id' ||
              k == 'transaction_type' ||
              k == 'amount' ||
              k == 'cost_basis' ||
              k == 'transaction_amount' ||
              k == 'transaction_fee' ||
              k == 'txId' ||
              k == 'crypto_type' ||
              k == 'crypto_type_2' ||
              k == 'label' ||
              k == 'desc' ||
              k == 'label' ||
              k == 'from_dest' ||
              k == 'to_dest' ||
              k == 'method_id' ||
              k == 'contract_address_executor' ||
              k == 'classification_name'
            ) &&
            item[k] != null &&
            item[k].toString().toLowerCase()
              .includes(filterValue.toLowerCase())
          )
        );
      }
    }
  }

  openCBMismtachWarningModal(modal, transaction) {
    this.currentTransaction = transaction
    this.openModal(modal);
  }

  hoverCostBasis(txId) {
    this.hoverTxId = txId
  }

  deleteTransaction(id, index, modal) {
    this.deleteTransactionId = id
    this.deleteTransactionIdIndex = index
    this.modalRef = this.modalService.open(modal)
  }

  confirmTransactionDelete() {
    this.formState = true
    this.global.httpPOST('delete_transaction', {
      transactionId: this.deleteTransactionId,
      portfolioId: this.global.currentPortfolioId,
    })
      .subscribe(response => {
        this.formState = false
        this.deleteTransactionId = null
        this.modalRef.close();
        this.pageMessage = 1

        this.global._transactions.splice(this.deleteTransactionIdIndex, 1)
        this.global.dropdownOpenClose(0)

        setTimeout(() => {
          this.pageMessage = null
        }, 5000);

      }, error => {
        this.pageMessage = 2
        setTimeout(() => {
          this.pageMessage = null
        }, 5000);
        this.modalRef.close();
        this.formState = false
        this.deleteTransactionId = null
      });
  }

  closeModal(backup) {
    if (backup) {
      this.modalRefBackUp.close()
    } else {
      this.modalRef.close();
      this.deleteTransactionId = null
    }

    this.formState = false
  }

  selectTransaction(tx, force = false) {
    let txId = tx.id
    let index = this.global.selectedTx.indexOf(txId)
    let dropIndex = this.global.selectFullDropTxns.indexOf(txId)

    console.log('Selecting!')
    console.log(tx)
    if (tx.is_locked == 1) {
      return
    }

    if (index == -1) {
      if (this.global.selectFullTx) {
        if (dropIndex == -1) {
          this.global.selectedTx.splice(index, 1)
          this.global.selectedTxFull.splice(index, 1)
          this.global.selectFullDropTxns.push(txId)
        } else {
          this.global.selectedTx.push(txId)
          this.global.selectedTxFull.push(tx)
          this.global.selectFullDropTxns.splice(dropIndex, 1)
        }
      } else {
        this.global.selectedTx.push(txId)
        this.global.selectedTxFull.push(tx)
        this.global.selectFullDropTxns.splice(dropIndex, 1)
      }
    } else {
      if (force == false) {
        this.global.selectedTx.splice(index, 1)
        this.global.selectedTxFull.splice(index, 1)

        if (dropIndex == -1) {
          this.global.selectFullDropTxns.push(txId)
        }
      }
    }


    console.log('Selected!')
    console.log(this.global.selectedTxFull[this.global.selectedTxFull.length - 1])

  }

  public selectedAll = false

  selectTransactionAll() {
    if (!this.selectedAll) {
      this.global._transactions.forEach(element => {
        if (element['is_locked'] != 1) {
          this.selectTransaction(element, true)
        }
      });
      this.selectedAll = true
    } else {
      this.selectedAll = false
      this.global._transactions.forEach(element => {
        if (element['is_locked'] != 1) {
          this.selectTransaction(element, false)
        }
      });
    }
  }

  openModal(content, data = null) {
    this.modalRef = this.modalService.open(content, data);
  }

  currentFMVTransactionInView = null
  openModalFMVIssues(modal, transaction){
    this.currentFMVTransactionInView = transaction
    this.modalRef = this.modalService.open(modal);
  }

  duplicatingTransaction = false

  duplicateTransaction(transactionId, index) {
    this.duplicatingTransaction = true
    this.global.httpPOST('duplicate_transaction', {
      transactionId: transactionId,
      portfolioId: this.global.currentPortfolioId,
    })
      .subscribe(response => {
        this.duplicatingTransaction = false
        this.global.dropdownOpenClose(0)

        if (response.data != null) {
          this.global._transactions.splice(index, 0, response.data)
        }


      }, error => {
        this.pageMessage = 2
        setTimeout(() => {
          this.pageMessage = null
        }, 5000);
        this.modalRef.close();
        this.duplicatingTransaction = false
        this.deleteTransactionId = null
      });
  }

  changeFilterConfigViewMode(mode) {
    this.txSettingsViewMode = mode
  }

  saveTxViewSettings() {
    console.log(this.global.appSettings.txtable_row_expand)
    console.log(this.editBlanaceConsolidationCheckbox)
    this.global.appSettings.txtable_columns = {...this.txColums};
    this.global.appSettings.txtable_row_expand = this.editBlanaceConsolidationCheckbox;
    this.global.appSettings.txtable_multi_line_consolidation = this.editTxMultilineConsolidation;
    this.global.appSettingsStateChange.emit(true)
    this.formState = true
    setTimeout(() => {
      this.formState = false
      this.modalRef.close()
    }, 500);
  }


  txSettingsViewMode = 0
  public txColums = null;

  openTransactionSettingsModal(modal) {
    this.modalRef = this.modalService.open(modal, {size: 'lg'})

    // if array has an object that is not in default, add that to the app settings
    // console.log(this.global.txSettingsDefaultView)
    // console.log(this.global.appSettings.txtable_columns)

    let cloneObj = {}

    this.editBlanaceConsolidationCheckbox = this.global.appSettings.txtable_row_expand
    this.editTxMultilineConsolidation = this.global.appSettings.txtable_multi_line_consolidation

    for (const key in this.global.txSettingsDefaultView) {
      if (!this.global.appSettings.txtable_columns.hasOwnProperty(key)) {
        cloneObj[key] = this.global.txSettingsDefaultView[key];
      } else {
        cloneObj[key] = this.global.appSettings.txtable_columns[key]
      }
      cloneObj[key]['description'] = this.global.txSettingsDefaultView[key]['description']
      cloneObj[key]['display'] = this.global.txSettingsDefaultView[key]['display']
    }

    // this.global.appSettings.txtable_columns = { ...cloneObj };
    console.log(cloneObj);

    this.txColums = JSON.parse(JSON.stringify(cloneObj));

  }

  applyTransactionSelect(state) {
    if (state === 0) { //Select All txs
      this.selectAllFilteredTx()
    } else if (state === 1) { // Select txs in current page
      this.global.selectFullTx = false
      this.global._transactions.forEach(element => {
        if (element['is_locked'] != 1) {
          this.global.selectedTx.push(element.id)
        }
      });
    }
  }


  returnTransactionId(compareIndex) {
    if (compareIndex == -1) {
      return 0
    } else if (compareIndex >= this.inputTransactions.length) {
      return 0
    }
    return this.inputTransactions[compareIndex]['id'];
  }

  verfiySplitTransactionMember(compareIndex) {
    if (compareIndex == -1) {
      return 0
    } else if (compareIndex >= this.inputTransactions.length) {
      return 0
    }
    return this.inputTransactions[compareIndex]['split_id'];
  }

  verfiyInternalPairTransactionMember(compareIndex) {
    if (compareIndex == -1) {
      return 0
    } else if (compareIndex >= this.inputTransactions.length) {
      return 0
    }
    return this.inputTransactions[compareIndex]['tx_internal_id'];
  }


  showSubTransactions(transaction, mode) {
    console.log(transaction)

    this.inputTransactions.forEach(element => {
      if (element['txId'] == transaction['txId']) {
        console.log(element)
        if (mode == 1) {
          element['isSubShow'] = true
        } else if (mode == 0) {
          element['isSubShow'] = false
        }

      }
    });

    // this.inputTransactions.forEach(element => {
    //   if (element['id'] == transaction['id']) {
    //     if (mode == 1) {
    //       element['isSubShow'] = false
    //     }

    //     if (mode == 0) {
    //       element['isSubShow'] = false
    //     }
    //   }
    // })

  }

  pendingAIResponse = false
  AIResponse = null

  openAIExplainModal(modal, txLedgerId){
    this.AIResponse = null
    this.modalRef = this.modalService.open(modal, {windowClass: 'gr-modal-right'})
    this.AIExplain(txLedgerId)
  }

  AIExplain(txLedgerId){
    this.pendingAIResponse = true
    this.global.httpPOST('inference/transaction', {
      transactionId: txLedgerId,
      portfolioId: this.global.currentPortfolioId,
    })
    .subscribe(response => {
      this.pendingAIResponse = false
      this.AIResponse = response.data

    }, error => {
      this.pendingAIResponse = false
    });
  }

  transactionToUnlock = null
  tranactionUnlockError = false
  unlockTransactionInit(transaction, modal){
    this.tranactionUnlockError = false
    this.transactionToUnlock = transaction.id
    this.modalRef = this.modalService.open(modal)
  }

  unlockingTransaction = false
  unlockTransaction(){
    this.unlockingTransaction = true
    this.global.httpPOST('unlock_tranasctions', {
      transactionIds: [this.transactionToUnlock],
      portfolioId: this.global.currentPortfolioId,
    })
    .subscribe(response => {
      this.unlockingTransaction = false
      this.transactionToUnlock = null
      this.modalRef.close()
      this.coinService.getPortfolioTransactions()


    }, error => {
      this.unlockingTransaction = false
      this.tranactionUnlockError = true
      this.transactionToUnlock = null

    });

  }

  openSendReceiveNFTTxModal(modal, transaction){
    this.showCreateSendReceiveNFTError = false
    this.NFTSendReceiveTransactionCurrent = transaction
    this.modalRef = this.modalService.open(modal)
    this.NFTSendReceiveTransaction['date'] = this.global.convertToLocalTime(this.global.unixTimestampMainNoTZConvert(this.NFTSendReceiveTransactionCurrent['created_at_unix']), "YYYY-MM-DD");
    // this.NFTSendReceiveTransaction['time'] = this.global.convertToLocalTime(this.global.unixTimestampMainNoTZConvert(this.NFTSendReceiveTransactionCurrent['created_at_unix']), "hh:mm A");

    this.NFTSendReceiveTransaction['time'] = null;

    // console.log(this.NFTSendReceiveTransactionCurrent['created_at_unix'])
    // console.log(this.NFTSendReceiveTransaction['date'])
    // console.log(this.NFTSendReceiveTransaction['time'])

  }

  showCreateSendReceiveNFTError = false;
  creatingNFTSendReceiveTransaction = false
  NFTSendReceiveTransactionCurrent = null
  NFTSendReceiveTransaction = {
    date:null,
    time:null
  }

  createSendReceiveNFTTxModal(){

    if(this.NFTSendReceiveTransaction['time'] == null){
      this.showCreateSendReceiveNFTError = true
      return
    }

    this.creatingNFTSendReceiveTransaction = true
    this.global.httpPOST('send_receive_nft_tx', {
      transactionId: this.NFTSendReceiveTransactionCurrent['id'],
      portfolioId: this.global.currentPortfolioId,
      date: this.NFTSendReceiveTransaction['date'],
      time: this.NFTSendReceiveTransaction['time'],
    })
    .subscribe(response => {
      this.creatingNFTSendReceiveTransaction = false
      this.modalRef.close()
      this.coinService.getPortfolioTransactions()


    }, error => {
      this.creatingNFTSendReceiveTransaction = false

    });

  }

  editAmountDetails = null
  editAmountDetailsInfo = null
  editingTxAmount = 0

  openTxAmountEditModal(modal, transaction){
    this.editAmountDetails = transaction['amount']
    this.editAmountDetailsInfo = transaction
    this.modalRef = this.modalService.open(modal)
  }


  editTxAmount(){

    this.editingTxAmount = 1
    this.global.httpPOST('tx_amount', {
      transactionId: this.editAmountDetailsInfo['id'],
      portfolioId: this.global.currentPortfolioId,
      amount: this.editAmountDetails,
    })
    .subscribe(response => {
      this.editingTxAmount = 2
      this.modalRef.close()
      this.coinService.getPortfolioTransactions()
    }, error => {
      this.editingTxAmount = 3

    });

  }

  showTxAmountEdit(transaction){
    if(
      transaction['sync_source'] == 'public address' &&
      (
        transaction['wallet_type'] == 3 || // ripple test
        transaction['wallet_type'] == 4787 || // osmosis
        transaction['wallet_type'] == 6433 || // kujira
        transaction['wallet_type'] == 6827 || // migaloo
        transaction['wallet_type'] == 5479 || // injective
        transaction['wallet_type'] == 4788 // terra classic
      )
    ){
      this
        return true
    } else {
      return false
    }
  }

  enterKeySearch(){
    // console.log('user hit enter')
    if(this.coinService.txTableDeepSearch.query != null){
      this.applyDeepSearch()
    }
  }

  openJournalModal(modal, journalId) {
    this.dataFetch = true
    this.journalId = journalId
    this.global.httpGET('journals/get_journal_line_record', { portfolioId: this.global.currentPortfolioId, journalId: journalId }).subscribe(response => {

      this.dataFetch = false
      if (response.status == true) {
        this.journalLineData = response.data
        this.journalDate = this.global.convertToLocalTime(this.global.unixTimestampMainNoTZConvert(this.journalLineData[0]['created_at_unix']), "DD/MM/YYYY");
        this.journalNo = this.journalLineData[0]['journal_id'];
        this.journalTransactionId = this.journalLineData[0]['transaction_id'];
        this.journalCreditTotal = this.journalLineData.reduce((total, line) => total + line.credit_amount, 0);
        this.journalDebitTotal = this.journalLineData.reduce((total, line) => total + line.debit_amount, 0);
        this.journalCurrency = this.journalLineData[0]['currency']['symbol'];
      }
    }, error => {
      this.dataFetch = false
    });
    this.modalRef = this.modalService.open(modal, { size: 'lg', backdrop: 'static' })
  }


}
