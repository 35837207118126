import { Component, ViewChild, Renderer2 } from '@angular/core';
import { ViewEncapsulation } from '@angular/core'; //add this
import { GlobalService } from './_services/global.service';
import { CoinService } from './_services/coin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { map } from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None  
  
})

export class AppComponent {
  
  title = 'app';


	@ViewChild('addBlockerModal') addBlockerModal : any;
  
  modalRef = null

  constructor (public global: GlobalService, public coinService:CoinService,public modalService: NgbModal, private renderer: Renderer2) {

  }


  ngAfterViewInit() {
		setTimeout(() => {
      this.hideMainLoader()
		}, 1120);
  }

	hideMainLoader(){
		try {

			let articles = window.location.pathname.split(/[ /]+/)

			if(articles[1] != 'portfolio'){
				let loader = this.renderer.selectRootElement('#loader');
				this.renderer.setStyle(loader, 'display', 'none');
			}
			
		} catch (error) {

			let loader = this.renderer.selectRootElement('#loader');
			this.renderer.setStyle(loader, 'display', 'none');
			
		}
	}
	  
  
	ngOnInit(){
    this.detectAdblock()
  }

	detectAdblock(){
		this.global.http.get('https://extreme-ip-lookup.com/json/67.218.223.54').subscribe(
		(response) => {
		}, error => {
			// console.log(error);// Error getting the data
			//this.modalRef = this.modalService.open(this.addBlockerModal, {size:'lg', backdrop:'static'})
		})
	}

  reloadDumpCache() {

    let cacheKeys = caches.keys()

    for (const cKey in cacheKeys) {
      caches.delete(cKey)
    }

    location.reload()
  }
  
  hideCurrentPortfolioNotificationsPopups(portfolioId){
    this.global._notificationsPopups.forEach(element => {
    });
  }
  


}
