<div class="text-center" >
    <form class="form-signin">
		<br/><br/>
		<a href="#" [routerLink]="['/']">
			<img src='{{global.FE_IMG_URL}}img/logo/logo.png' height="75" class="d-inline-block align-top" alt="">
		</a>
		<br/><br/>
		<h1 class="h5 mb-3 font-weight-normal" *ngIf="pageStatus==0">Activating...</h1>
		<h1 class="h5 mb-3 font-weight-normal" *ngIf="pageStatus==1">Account Successfully Activated.</h1>
		<h1 class="h5 mb-3 font-weight-normal" *ngIf="pageStatus==2">Account is Already Activated.</h1>
      	
    </form>
</div>

	