
<div class="container">
	<div class="row">
		<div class="col-md-6">
			<div class="row">
				<div class="col-md-12" *ngIf="global._packagePurchaseDetails != null">

					<h5>Current Plan Details</h5>
					<div class="card buy-box">
						<p>
							<b>{{global._packageDetails.name}}</b> Plan
					
							<span class="card-title pricing-card-title" *ngIf="global._packagePurchaseDetails.billing_type=='yearly'">${{ global._packagePolicies.billing.anually * 12 }} <small class="text-muted">/ year</small></span>
							<span class="card-title pricing-card-title" *ngIf="global._packagePurchaseDetails.billing_type=='monthly'">${{ global._packagePolicies.billing.monthly }} <small class="text-muted">/ mo</small></span>	
						
						</p>

						<div class="row" *ngIf="global._packageDetails!=null">
							<div class="col-md-12">
								<div class="card-deck mb-3 text-center">
									<div class="card card-features  mb-3 box-shadow" >
										<ul class="list-group">
						
											<li class="list-group-item list-group-item-action" *ngIf="global._packageDetails.policies.business.status=='true'">
												<i class="fa fa-check" aria-hidden="true"></i><span > Company Account</span>
											</li>
											<li class="list-group-item list-group-item-action" *ngIf="global._packageDetails.policies.business.status=='true'">
												<i class="fa fa-check" aria-hidden="true"></i> Upto {{global._packageDetails.policies.business.members}} employee accounts
											</li>
											<li class="list-group-item list-group-item-action">
												<i class="fa fa-check" aria-hidden="true"></i>
												<span *ngIf="global._packageDetails.policies.features.portfolios!=-1">
													{{global._packageDetails.policies.features.portfolios}}
													<span *ngIf="global._packageDetails.policies.features.portfolios==1">Entity</span>
													<span *ngIf="global._packageDetails.policies.features.portfolios!=1">Entities</span>
												</span>
												<span *ngIf="global._packageDetails.policies.features.portfolios==-1">Unlimited Entities</span>
												
											</li>
											<li class="list-group-item list-group-item-action">
												<i class="fa fa-check" aria-hidden="true"></i>
												<span *ngIf="global._packageDetails.policies.features.exchanges!=-1">{{global._packageDetails.policies.features.exchanges}}</span>
												<span *ngIf="global._packageDetails.policies.features.exchanges==-1">Unlimited</span>
												Exchanges
											</li >
											<li class="list-group-item list-group-item-action">
												<i class="fa fa-check" aria-hidden="true" ></i>
												<span> Unlimited AUM</span>
											</li>
											<li class="list-group-item list-group-item-action">
												<i class="fa fa-check" aria-hidden="true" ></i>
												<span> Unlimited Transaction Storages</span>
											</li>
											<li class="list-group-item list-group-item-action">
												<i class="fa fa-check" aria-hidden="true" ></i>
												<span> Transaction Labelling</span>
											</li>
											<li class="list-group-item list-group-item-action">
												<i class="fa fa-check" aria-hidden="true" ></i>
												<span> Entity Analytics</span>
											</li>
											<li class="list-group-item list-group-item-action" *ngIf="global._packageDetails.policies.features.CSV=='true'">
												<i class="fa fa-check" aria-hidden="true"></i><span > CSV/PDF Export </span>
											</li>
											<li class="list-group-item list-group-item-action" >
												<i class="fa fa-check" aria-hidden="true"></i><span > {{global._packageDetails.policies.support}} Support </span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="row">
						<div class="col-md-12">
								<h5>Enter payment details</h5>
								<div id="card-element" class="field"></div>
						</div>
					</div>
					<br/>
					<div class="row">
						<div class="col-md-12">
							<div *ngIf="errorStatus==444" class="alert alert-danger" role="alert">
								Card Declined! Your card is not supported.
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
								<button type="submit" type="button" class="btn btn-primary btn-lg btn-block" *ngIf="formState==0" (click)="makePayment()"> Pay</button>
								<button type="button" class="btn btn-primary btn-lg btn-block disabled" *ngIf="formState==1">Purchasing...</button>
						</div>
					</div>
	
					
				</div>
			</div>
			
		</div>
		<div class="col-md-6">
				<div class="row">
					<div class="col-md-12">
						<div class="card buy-box">
							<div class="row ">
									<div class="col-md-12">
										<p><b> Order Summary</b> </p>
									</div>
							</div>
							<div class="row border-bottom">
								<div class="col-md-6">
									<b>Item</b>
								</div>
								<div class="col-md-6">
									<b>Amount</b>
								</div>
							</div>
							<br/>
							<div class="row">
								<div class="col-md-6">
									<b>{{global._packageDetails.name}}</b> Package
								</div>
								<div class="col-md-6">
									<span *ngIf="global._packagePurchaseDetails.billing_type=='yearly'">${{ global._packageDetails.policies.billing.anually * 12 }} / year </span>
									<span *ngIf="global._packagePurchaseDetails.billing_type=='monthly'">${{ global._packageDetails.policies.billing.monthly }} / month </span>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<span *ngIf="global._packagePurchaseDetails.billing_type=='yearly'" class="fade-in text-success">
										Save ${{(global._packageDetails.policies.billing.monthly * 12) - (global._packageDetails.policies.billing.anually * 12)}} 
										({{ 100 - ((global._packageDetails.policies.billing.anually * 12)/(global._packageDetails.policies.billing.monthly * 12) *100) | number:'1.0-2' }}%) per year.
									</span>
								</div>
							</div>
						</div>
						<br/>
						<div class="row">
							<div class="col-md-12">
								<span><b>Accepted Payments</b></span>
								<br/>
								<img src="{{global.FE_IMG_URL}}img/payment/amex.png" width="40" height="28" class="d-inline-block align-top" alt="amex"> 
								<img src="{{global.FE_IMG_URL}}img/payment/visa.png" width="40" height="28" class="d-inline-block align-top" alt="visa"> 
								<img src="{{global.FE_IMG_URL}}img/payment/master.png" width="40" height="28" class="d-inline-block align-top" alt="mastercard">  <br/><br/>
								<p>We accept other forms of payments including cryptocurrencies. If you don't see the type of payment above, please <a href="#!" [routerLink]="['/contact']">contact</a> our support team.</p>
							</div>
						</div>
					</div>
				</div>
		</div>
	</div>
</div>

<ng-template #paymentProcessingModal let-c="close" let-d="dismiss" >
		<div class="modal-body">
			<div class="row" *ngIf="divState==1">
				<br/>
				<div class="col-md-12 text-center">
					<div class="row text-center">
						<div class="col">
							<img class="card-icons" src="{{global.FE_IMG_URL}}img/payment/loader.gif"  height="50x" width="50"> 
						</div>
					</div>
					<div class="row text-center">
						<div class="col">
							<h1 >
								<span><b> Processing Payment </b></span>
							</h1>
							<p text-center>
								<span>Give us a moment while we process your payment securely...</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="divState==3">
				<br/>
				<div class="col-md-12 text-center">
					<div class="row text-center">
						<div class="col">
							<i class="fa fa-check text-success icon-lg" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row text-center">
						<div class="col">
							<h1 >
								<span><b> Payment Successful </b></span>
							</h1>
							<p text-center>
								<span> 
									Your payment is successfully. You account is active. Please click on the close button to return back to your account.
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="divState==4">
				<br/>
				<div class="col-md-12 text-center">
					<div class="row text-center">
						<div class="col">
							<i class="fa fa-check text-success icon-lg" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row text-center">
						<div class="col">
							<h1 >
								<span><b> Payment Successful </b></span>
							</h1>
							<p text-center>
								<span> 
									Your payment is successfully. You account is active. Please login to your account.now.
								</span>
							</p>
							<button type="submit" class="btn btn-primary" type="button" (click)="modalRef.close()">Close</button>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="divState==2">
				<br/>
				<div class="col-md-12 text-center">
					<div class="row text-center">
						<div class="col">
							<i class="fa fa-exclamation-circle text-danger icon-lg" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row text-center">
						<div class="col">
							<h1 >
								<span><b> Payment Failed </b></span>
							</h1>
							<p text-center>
								<span> We attempted to charge your card but it was not successful. Please review your payment details carefully and try again.
									<br/><br/>
									<div class="buy-box">
										<p><b>{{payErrorMessgae}}</b></p>
									</div>
									<br/>
									If this problem persists, please <a href="#!" [routerLink]="['/contact']" target="_blank">contact</a>  our support team.
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="divState==2 || divState==3">
			<div class="col-md-12 text-center">
				<button type="button" class="btn btn-primary btn-lg " (click)="d('Cross click');resetFormStates()">Close</button>
			</div>
		</div>
		<br/>
	</ng-template>