import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';


@Component({
	selector: 'app-upgrade-plan-ep',
	templateUrl: './upgrade-plan-ep.component.html'
})

export class UpgradePlanEPComponent implements OnInit {

    @Input() status;

    constructor(){

    }

    ngOnInit(){
        
    }

}