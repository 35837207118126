
    <div class="modal-header">
        <h4 class="modal-title pull-left">unsync Confirmation</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.close(false);">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <p *ngIf="undo.option === 1">
                Are you sure you want to remove journal entries from <b>{{undo.startDate}}</b> -
                <b>{{undo.endDate}}</b>?
                This will remove all journals within the date range provided. <br /><br />
                <b>This action is not reversible.</b>
            </p>
            <p *ngIf="undo.option === 2">
                Are you sure you want to remove all journal entries? This will remove all applicable journals from
                your app. <br /><br />
                <b>This action is not reversible.</b>
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" disabled class="btn btn-danger disabled" *ngIf="isDisableUndoButton"><img
                class="img-fluid" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif" width="25" height="25" alt=""> Confirm
        </button>
        <button type="button" class="btn btn-danger" (click)="undoConfirm()"
            *ngIf="!isDisableUndoButton">Confirm</button>
        <button type="button" class="btn btn-hr-min btn-lg " (click)="activeModal.close(false);">No</button>
    </div>
