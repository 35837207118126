import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import { ActivatedRoute, Router, Params, RouterStateSnapshot} from '@angular/router';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'exchanges-api-callback',
  templateUrl: './exchanges-api-callback.component.html',
})
export class ExchangeAPICallback implements OnInit {

	public status = 0
	
	constructor(public global: GlobalService, public modalService: NgbModal, public coinService:CoinService, private router:Router, public route:ActivatedRoute) {
	}

	ngOnInit(): void {
		window.scroll(0, 0);

		
		this.status = 0
		if(this.global.isAuthenticated()){

			this.route.queryParams.subscribe(params => {
				this.doAuthentication(params)
			});
		} else {
			this.global._cookieService.delete('oauth2Data', '/')
			this.global.logoutFinishStage()
		}
		
	}

	doAuthentication(params){
		let oauth2data = this.global._cookieService.get('oauth2Data')
		console.log('cookie')
		console.log(oauth2data)
		oauth2data = JSON.parse(oauth2data)
		oauth2data['params'] = params

		if(oauth2data['key'] == params['state']){
			this.global.httpPOST('exchange_oauth2_connect', oauth2data).subscribe(response => {
				if(response.status==true){
					this.global.addedoAuth2Exchange = true

					if(response.data=='already_connected'){
						this.status = 1 // already connected
						setTimeout( () => { 
							this.global._cookieService.delete('oauth2Data', '/')
							this.router.navigate(['entity', oauth2data['portfolio_id'], 'connections']);
							if(!this.global.keepUserLoggedIn){
								this.global.cookieUnsetForOAuth2ExchangeCons()
							}
						}, 5000 );
					} else {
						this.global._cookieService.delete('oauth2Data', '/')
						this.router.navigate(['entity', oauth2data['portfolio_id'], 'connections']);
						if(!this.global.keepUserLoggedIn){
							this.global.cookieUnsetForOAuth2ExchangeCons()
						}
					}
					
				} 
			}, error => {
			});
		} else {
			if(!this.global.keepUserLoggedIn){
				this.global.cookieUnsetForOAuth2ExchangeCons()
			}
			console.log('error')
		}
		
	}

	


}
