export const environment = {
  production: false,
  title: 'Dev Environment Heading',
  apiURL: 'https://stagingapi.interplay.network/v1/',
  apiUrlPublic: "https://stagingapi.interplay.network/",
  recaptchaSiteKey: "6LcBQIAbAAAAAOWSsTzLmnWPuqQziFPFZjxQxGYy",
  // googleClientID: "119282083993-m27sprmt3n93jn23c9l8ip02h6f092d9.apps.googleusercontent.com",
   googleClientID: "994890070164-5lv5n9ecri8ejrccujmnen757901tmjs.apps.googleusercontent.com",
  xeroClientId : "B67AC4CCD9F5415986727CB0529E7466",
  xeroRedirectUrl: "https://staging.interplay.network/xero_callback",
  qbjournalLink: "https://app.sandbox.qbo.intuit.com/"
};
