<div class="boxes">
		<div class="boxOne">
			<div class="div-signin text-center pt-4">
				<a href="#" [routerLink]="['/']">
					<img src='{{global.FE_IMG_URL}}img/logo/logo.png' width="175"  class="d-inline-block align-top text-center" alt="">
				</a>
			</div>
		  <div style="flex: 1; text-align: center;">
		  <i class="fa fa-exclamation-circle text-primary" style="font-size: 150px; margin: 20px 0 20px 0" aria-hidden="true"></i>
		</div>

		<h1>Server Maintenance</h1>
		<div class="text-center">
			<p class="lead mt-2">We sincerely apologize for the inconvenience. Our site is currently under maintenance and will return  shortly. Please try again later.</p>
			<br/>
			<p>See more information at: <a href="https://status.cryptoworth.app/" target="_blank">https://status.cryptoworth.app/</a> </p>
			<p>Please contact us at: support@cryptoworth.app</p>
		</div>

	</div>
</div>
