import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication/authentication.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Http, Headers, RequestOptions,  } from '@angular/http';
import { map } from "rxjs/operators";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-invoice-pub-page',
	templateUrl: './invoice-pub-page.component.html'
})

export class InvoicePubPageComponent implements OnInit {

	public modalRef

    @Input() currentViewInvoice;

    public paramsFetched
    public invoiceLoading = false
    public paymentState = 0;

    public COIN_IMG_URL = 'https://api.cryptoworth.app/img/coins/'

    constructor(
        public authService:AuthenticationService, 
        public route:ActivatedRoute,
        public http: Http, 
        public modalService: NgbModal
    ){
        this.route.params.subscribe((params: Params) => {
            this.paramsFetched = params
            console.log(this.paramsFetched)
            this.fetchInvoiceInfo()
            
        })
    }

    ngOnInit(){
        
    }

    fetchInvoiceInfo(){
        this.invoiceLoading = true
        this.http.get(this.authService.API_URL + 'payments/get_invoice?invoiceId=' + this.paramsFetched['invoiceId'] + '&by="uid"',).pipe(map(res =>  res.json())).subscribe(
		(response) => {
            this.fetchInvoiceSet(response)
            this.invoiceLoading = false
		}, error => {
			console.log(error);// Error getting the data
			this.invoiceLoading = false
        })
    }

    paymentInitLoading = false
    paymentInitErrors = false
    paymentInitPriceFetch = false
    paymentInitShowCalculations = false
    public paymentConfirmationId = null
    paymentConfirmationLoading = false

    showCalculations(){
        this.paymentInitShowCalculations = true
    }

    fetchInvoiceSet(response){
        this.currentViewInvoice = response.data
        this.confirmationId = this.currentViewInvoice['confirmation_code']
    }

    hideCalculations(){
        this.paymentInitShowCalculations = false
    }

    initPayCryptoSymbol = null
    initiatePay(){
        this.paymentInitLoading = true
        this.paymentState=1
        let symbol = null

        if(this.currentViewInvoice.tokenInfo.length == 0){
            this.initPayCryptoSymbol = this.currentViewInvoice['symbol']
            this.getSpotPrice(this.currentViewInvoice['symbol'], this.currentViewInvoice['fiat']['code'])
        } else {
            this.initPayCryptoSymbol = this.currentViewInvoice.tokenInfo[0]['tokenInfo']['symbol']
            this.getSpotPrice(this.currentViewInvoice.tokenInfo[0]['tokenInfo']['symbol'], this.currentViewInvoice['fiat']['code'])
        }
    }

    initiateConfirm(modal){
        this.modalRef = this.modalService.open(modal)
    }

    public blockchainConfirmationValidation = true
    public confirmationId

    getSpotPrice(fsym, tsym){
        this.http.get(' https://min-api.cryptocompare.com/data/price?fsym='+ fsym +'&tsyms='+  tsym).pipe(map(res =>  res.json())).subscribe(
            (response) => {
                this.paymentInitLoading = false
                this.paymentInitPriceFetch = response
                console.log(response)
            }, error => {
                this.paymentInitLoading = false
                this.paymentInitErrors = true
                
            })
    }

    public confirmationValidation = true

    confirmTransactionNow(){
        if(this.checkConfirmationValidation()){
            this.paymentConfirmationLoading = true
            this.http.post(this.authService.API_URL + 'payments/confirm', {invoiceId:this.paramsFetched['invoiceId'], priceData:this.paymentInitPriceFetch, confirmationId:this.paymentConfirmationId}).pipe(map(res =>  res.json())).subscribe(
            (response) => {
                this.paymentState = 2
                this.paymentConfirmationLoading = false
                this.fetchInvoiceSet(response)
                this.closeModal()
            }, error => {
                this.paymentConfirmationLoading = false
                console.log(error);// Error getting the data
            })
        }
    }

    checkConfirmationValidation(){
        let check = true

        if(this.paymentConfirmationId == null){
            this.confirmationValidation = false
            check = false
        } else {
            this.confirmationValidation = true
        }

        return check
    }

    closeModal(){
        this.modalRef.close()
    }

    public addressTooltipText = "Copy Address"

	copyAddress(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);

		this.addressTooltipText = "Copied!"
		setTimeout(() => {
			this.addressTooltipText = "Copy Address"
		}, 1000);
	}


}