
import { Component,Renderer } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';

@Component({
  moduleId: module.id,
  selector: 'logout',
  templateUrl: './logout.component.html',
})

export class LogoutComponent { 
  constructor(private global: GlobalService, private coinService: CoinService) {}

  ngOnInit() {
    this.coinService.setOnsClasses(null);
    this.coinService.setOnsDepartments(null);
    
    this.coinService.setQbDepartments(null);
    this.coinService.setQbGlClasses(null);
    this.coinService.setQbGlAccounts(null);

    this.coinService.logout();
    this.global.logout();

  }
}

