import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, SimpleChanges } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'app/_services/global.service';
import { UndoWarnBeforePopup } from '../undo-warn-before/undo-warn-before-popup.component';

@Component({
	selector: 'app-undo-sync-popup',
	templateUrl: './undo-sync-popup.component.html'
})
export class UndoSyncPopup implements OnInit {

    @Input() gatewayId = null;
    isDisableUndoButton = false;
    public undoWarinigRef; 
    public undo = {
		option: 1,
		startDate: null,
		endDate: null,
		all: false,
        memo: null
	};
    public undoError = null;

    constructor(public activeModal: NgbActiveModal, public global: GlobalService,  public modalService: NgbModal) {
        
      }

    ngOnInit(): void {
        console.log(this.gatewayId)
        //throw new Error('Method not implemented.');
    }

    public onChangeRollBackOption(){
		this.undoError = null;
		if(this.undo.option === 2){
			this.undo.all = true;
			this.undo.startDate = null;
			this.undo.endDate = null;
		}else{
			this.undo.all = false;
		}
	}
    public onCloseAssetSelectpopup(data) {
        //this.activeModal.close({data: this.selectedConnection, error: this.generalError})
    }

    public undoSync(modal){
		// if(this.timeout){
		// 	this.showAlert = false;
		// 	clearTimeout(this.timeout);
		// }

		this.undoError = null;
		let valid= true;

		if(this.undo.option === 1){
			if(this.undo.startDate === null || this.undo.endDate === null){
				valid = false;
			}
		}

		if(!valid){

			this.undoError = "Start Date and End Date fields are required." 
		}else{
            // this.emitterValues.startDate = this.undo.startDate;
            // this.emitterValues.endDate = this.undo.endDate;
            // this.emitterValues.option = this.undo.option;
            // this.emitterValues.all = this.undo.all;

            this.undoWarinigRef = this.modalService.open(UndoWarnBeforePopup,  {backdrop: "static"});
            this.undoWarinigRef.componentInstance.undo = this.undo;
            this.undoWarinigRef.componentInstance.gatewayId = this.gatewayId;
		}
	}


}