import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { CoinService } from '../coin.service';


@Injectable()
export class ExploreService {

    MARKETPLACE_IMG_URL = null
	tablePageSizeNumber:number = 30

    loadingMarketplaceCategories = 0
    categories = null

    marketPlaceSolutions = null
    selectedCategory = null;
    marketplaceSolutionsLoading = 0

    userDeepSearched = false
	quickSearchMode = false

	tablePageNumber:number = 1
	tableSelectedPageNumber:number = 1
	tableTotalPages:number = 0
	tableOffset = 0
    searchLoading = false
	PAGE_NAV ={
		FIRST_PAGE: 0,
		NEXT_PAGE: 1,
		PREV_PAGE : 2,
		LAST_PAGE : 4
	};
    totalItemCount = 0
    
	marketplaceDeepSearch = {
		mode: 0,
		query: null
	}
    marketplaceSolutionSearching = 0
    searchMode = false

	constructor(
        private global: GlobalService,
		private coinService: CoinService,
	) {

	}


    getIssues(){

    }

    getAssets = null
    getAssetsBackup = null

    getAssetList(){
        this.global.httpGET('wallet_types_all').subscribe(response => {
            if(response.status==true){
                this.getAssets = response.data
                this.getAssetsBackup = response.data
            } 
        }, error => {
        });	
    }

    DeFiList = null
    DeFiListBackup = null

    getDeFi(){
        this.global.httpGET('defi_types').subscribe(response => {
            if(response.status==true){
                this.DeFiList = response.data
                this.DeFiListBackup = response.data
            } 
        }, error => {
        });	
    }

    tagsList = null
    tagsListBackup = null

    getTags(){
        this.coinService.getPortfolioTags(true).subscribe(response => {
            this.tagsList = response.data
            this.tagsListBackup = response.data
            //this.filterTags()
        }, error => {
        });
    }

}
