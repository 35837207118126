

<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
      <form class="col-12">
            <div class="row">
                <div class="col-md-6 offset-md-3 text-center">
                    <br/><br/>
                    <br/>
                    <img class="img-fluid" width="200px" src="/assets/img/logo/logo.png"  alt="">
                    <br/>
                    <br/>
                    <br/>
                    <img width="100px" class="img-fluid" src="/assets/img/xero.svg"  alt="">
                    
                    <div class="mt-4">
                        <div *ngIf="status==0 || status==1">
                            <h3 *ngIf="!showError">
                                <img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="30"  class="img-fluid d-inline-block align-top text-center" alt=""> 
                                Finalizing Connection
                            </h3>
                        </div>
                        <ng-container *ngIf="type==1">                        
                            <div *ngIf="status==2">
                                <h3>
                                    Connection Success
                                </h3>
                            </div>
    
                            <div *ngIf="status==3">
                                <h3>
                                    <span class="text-danger">Error! Redirecting...</span> 
                                </h3>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="type==2"> 
                            <ng-container *ngIf="status==2">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Select User</label>
                                    <select class="form-control" [(ngModel)]="selectedUser" [ngModelOptions]="{standalone: true}">
                                        <option [ngValue]="null"> Select user</option>
                                        <option [ngValue]="user" *ngFor="let user of users; let i = index"> 
                                
                                            {{  user. EmailAddress}} -
                                            <small>{{ user.FirstName }} {{ user.LastName }} </small>
                                        </option>
                                    </select>
                                </div>
                                <button class="btn btn-primary disabled" *ngIf="selectedUser === null" disabled>Continue</button>
                                <button class="btn btn-primary" (click)="onSelectUser()" *ngIf="selectedUser !== null">Continue</button>
                            </ng-container>
                            <ng-container *ngIf="status==3">
                                <h3>
                                    <span class="text-danger">Error! Redirecting...</span> 
                                </h3>
                            </ng-container>
                        </ng-container>   
                    </div>

                </div>
            </div>
      </form>   
     
  </div>  
</div>