

<div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
        <form class="col-12">
                <div *ngIf="status==0">
                        <h3>Connecting...</h3>
                    </div>
                    <div *ngIf="status==1">
                       <h3> This account is already connected to your portfolio. </h3>
                       <p> You will be directed soon...</p>
                    </div>
            
        </form>   
       
    </div>  
</div>