import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortService {
    public txSort = {
		'date': 'DESC',
		'cost': null,
		'sales': null,
		'inAmount': null,
		'outAmount': null,
		'unitPrice': null
	};

  public txSortEnables = {
    date: true,
    cost: false,
    sales: false,
    inAmount: false,
    outAmount: false,
    unitPrice: false
  };

  constructor() {}

  public changeTxSort(field: 'date' | 'cost' | 'sales' | 'inAmount' | 'outAmount' | 'unitPrice', sort: 'ASC' | 'DESC'): void {
    Object.keys(this.txSort).forEach(key => {
      this.txSort[key] = key === field ? sort : null;
    });

    Object.keys(this.txSortEnables).forEach(key => {
      this.txSortEnables[key] = key === field;
    });
  }

}
