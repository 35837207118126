import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { GlobalService } from '../../../_services/global.service';
import { CoinService } from '../../../_services/coin.service';
import { PolicyService } from '../../../_services/policy.service';
import { AddressBookService } from '../../../_services/addressbook.service'

import { FormGroup, FormControl, Validators } from '@angular/forms';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

const addressDetect = require('cryptocurrency-address-detector');

@Component({
  selector: 'app-address-view-info',
  templateUrl: './address-view-info.component.html'
})

export class AddressViewInfoComponent implements OnInit {

	objectKeys = Object.keys
	@Input() viewingAddress = null
	@Input() allowModify = true

	public modalRef

	public addressBookModState = 0
	public addressDetails = {
		type:1
	}
	public addressValidation = null
	public errorCode = null


	public addingAddress = false
	public editingAddress = false

	public addressToDelete = null
	public dataloading = false
	public selectedAddress = null

	public addressTooltipText = "Copy Address"
	public currentAddressViewIndex = null
	public foundResults = []
	public searchMode = false
	public search = null

	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});

	constructor(public global: GlobalService, public coinService:CoinService, public policyService:PolicyService, public modalService: NgbModal, public addressBookService:AddressBookService) {

	}

	ngOnInit() {
		this.global.PAGE_INDEX = 2
		console.log(this.global.PAGE_INDEX);
		window.scroll(0, 0);
		this.global.titleService.setTitle(this.global.appName +" - Address Book")
	}

	

	checkAddress(address){

		if(address!=null){
			let crypto = null
			let splitted = null
			addressDetect(address).then(cryptocurrency => {
				crypto = cryptocurrency
				console.log('detected crypto: ' + crypto)
	
				if(cryptocurrency == 'Cryptocurrency could not be detected'){
					this.addressValidation = 0
				} else {
	
					splitted = crypto.split("/", 3); 
					console.log('detected crypto: ' + splitted)
	
					if(this.addressDetails['crypto'] != null || this.addressDetails['crypto'] != ''){
						if(splitted.length>1){
							if(
								splitted[0] == this.addressDetails['crypto']['symbol'] || 
								splitted[1] == this.addressDetails['crypto']['symbol'] || 
								splitted[2] == this.addressDetails['crypto']['symbol']
							){
								this.addressValidation = 1
							} else {
								this.addressValidation = 2
							}
						} else {
							if(splitted == this.addressDetails['crypto']['symbol']){
								this.addressValidation = 1
							} else {
								this.addressValidation = 2
							}
						}
					}
				}
			});
		}
		
	}

	searchAddress(search){
		this.foundResults = []

		search = search.toUpperCase()

		if(search != "" || search != null){
			this.searchMode = true
			for(var address in this.global.addresses){
				let upperCased = this.global.addresses[address].name.toUpperCase()
				
				if(upperCased.includes(search)) {
					if(!this.foundResults.includes(this.global.addresses[address].type)){
						this.foundResults.push(this.global.addresses[address])
					}
				}
			}
		} else {
			this.searchMode = false
		}
	}

	resetSearch(){
		this.searchMode = false
		this.search = null
	}

	viewAddress(address){
		this.viewingAddress = address
	}

	copyAddress(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);

		this.addressTooltipText = "Copied!"
		setTimeout(() => {
			this.addressTooltipText = "Copy Address"
		}, 1000);
	}

	createAddress(){
		this.addingAddress = true
		if(this.validateAddressForm()){
			this.global.httpPOST('add_address',{addressDetails:this.addressDetails, verified:this.addressValidation}).subscribe(response => {
				if(response.status==true){
					this.resetAdding()
					this.global.getAllAddresses()
	
						this.modalRef.close();
						this.global.selectedAddressGroup(true)
						//this.addressBookService.addressLinkRefresh.emit(true)
						this.addingAddress = false

				} else {
					this.errorCode = 6
				}
			}, error => {
				this.addingAddress = false
			});
		} else {
			this.addingAddress = false
		}
	}


	setCoinTypeId(crypto){
		this.addressDetails['crypto'] = crypto
		if(this.addressDetails['address']!=null || this.addressDetails['address'] != ''){
			this.checkAddress(this.addressDetails['address'])
		}
	}

	resetEditAddressCoin(){
		this.addressDetails['crypto_id'] = null
	}

	validateAddressForm(){
		if(this.addressDetails['name']==null || this.addressDetails['name'] == ''){
			this.errorCode = 1
			return false
		}

		if(this.addressDetails['crypto']==null || this.addressDetails['crypto'] == ''){
			this.errorCode = 2
			return false
		}

		if(this.addressDetails['address']==null || this.addressDetails['address'] == ''){
			this.errorCode = 3
			return false
		}

		if(this.addressDetails['type']==null){
			this.errorCode = 4
			return false
		}


		if(this.addressDetails['type'] == 3 && (this.addressDetails['portfolioId'] == null || !this.addressDetails['portfolioId'])){
			this.errorCode = 9
			return false
		}

		if(this.addressValidation == 2){
			this.errorCode = 5
			return false
		}

		console.log(this.phoneForm.valid)
		if(this.phoneForm['controls']['phone']['value'] != null && !this.phoneForm.valid){
			//Invalid Phone Number
			this.errorCode = 7;
			return false
		} else {
			if(this.phoneForm['controls']['phone']['value'] != null){
				this.addressDetails['tel'] = this.phoneForm['controls']['phone']['value']['internationalNumber'];
			} 
		}

		if(this.addressDetails['email'] != null && !this.global.validateEmail(this.addressDetails['email'])){
			console.log(this.addressDetails)
			this.errorCode = 8;
			return false
		}



		return true;
	}

	deleteAddress(content, address){
		this.addressToDelete = address
		console.log(this.addressToDelete)
		this.openModal(content)
	}

	deletingAddress = false
	deleteAddressConfirm(){
		this.deletingAddress = true
		this.global.httpGET('delete_address',{addressId:this.addressToDelete['id']}).subscribe(response => {
			if(response.status==true){
				this.global.getAllAddresses()

					this.modalRef.close();
					
					this.global.selectedAddressGroup(true)
					//this.addressBookService.addressLinkRefresh.emit(true)

				this.viewingAddress = null
				
			}
			this.deletingAddress = false

		}, error => {
			this.deletingAddress = false
		});
	}

	cancelDelete(){
		this.addressToDelete =  null
	}

	saveAddressChanges(){
		this.editingAddress = true
		this.global.httpPOST('edit_address',{addressDetails:this.addressDetails}).subscribe(response => {
			if(response.status==true){
				this.global.getAllAddresses()
					this.modalRef.close();
					this.global.selectedAddressGroup(true)
					//this.addressBookService.addressLinkRefresh.emit(true)
				this.resetAdding()
			} 
			this.editingAddress = false

		}, error => {
			this.editingAddress = false
		});
	}

	triggetAddressEditing(content, address){
		this.addressDetails = address
		this.addressBookModState = 1
		this.openModal(content)
	}
	
	createAddressBookEntry(modal, isNew){

		this.addressDetails = {
			type:1
		}
		this.addressBookModState = 0

		this.modalRef = this.modalService.open(modal)
	}

	resetAdding(){
		this.addressDetails = {
			type:1
		}
		this.addressBookModState = 0
		this.addressValidation = null
		this.addressToDelete =  null
	}

	openModal(content) {
		this.modalRef = this.modalService.open(content);
	}

	closeSubAlert(){
		this.errorCode = 0
		this.currentAddressViewIndex = null
	}

	public isdropdownOpen = false
	dropdownOpenClose(state){
		this.isdropdownOpen = state
	}

	dropDownOpenId = -1
	dropdownOpenCloseSpecific(state){
		this.dropDownOpenId = state
	}


	addressNewAdd($event){
		console.log($event)
		// if($event != false){
		// 	this.viewingAddress['allAddresses'].push($event)
		// }
	}

	public addressOfInterest = null
	public addressOfInterestIndex = null

	removeAddressInit(address, index, modal){
		console.log(address)

		this.addressOfInterest = address
		this.addressOfInterestIndex = index
		console.log('Here!')
		console.log(this.addressOfInterest)
		this.modalRef = this.modalService.open(modal)
	}

	confirmRemoveAddress(){
		this.editingAddress = true
		this.global.httpPOST('remove_address',{addressOfInterest:this.addressOfInterest['id'], contactId:this.viewingAddress['id']}).subscribe(response => {
			if(response.status==true){
				this.viewingAddress['allAddresses'].splice(this.addressOfInterestIndex, 1)
				this.global.getAllAddresses()
					this.modalRef.close();
					this.global.selectedAddressGroup(true)
					//this.addressBookService.addressLinkRefresh.emit(true)
				//this.addressBookService.addressLinkRefresh.emit(true)
			} 
			this.editingAddress = false

		}, error => {
			this.editingAddress = false
		});
	}

	editAddressLabelInit(address, index, modal){
		this.addressOfInterest = address
		this.addressOfInterestIndex = index
		this.modalRef = this.modalService.open(modal)
		this.editAddres['label']=address['label']
	}

	editAddres = {}
	confirmEditContractAddress(){
		this.editingAddress = true
		this.global.httpPOST('edit_contact_address',{addressOfInterest:this.addressOfInterest['id'], contactId:this.viewingAddress['id'], editAddres:this.editAddres}).subscribe(response => {
			if(response.status==true){
				this.viewingAddress['allAddresses'][this.addressOfInterestIndex]['label'] = this.editAddres['label']
				this.global.getAllAddresses()
					this.modalRef.close();
					this.global.selectedAddressGroup(true)
					//this.addressBookService.addressLinkRefresh.emit(true)

			} 
			this.editingAddress = false

		}, error => {
			this.editingAddress = false
		});
	}


}
