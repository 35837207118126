<div *ngIf="coinSelected==null" class="search-selector" #searchComp>
    <input type="text" autoComplete="off"
     [(ngModel)]="coinSearch" 
     (keyup)="doSearch($event)"
     (focus)="onFocus()"
     (blur)="onBlur()"
      name="coinSearch" 
      class="form-control" id="coinSearch" aria-describedby="coinSearch" 
      placeholder="Search for assets" />
    <div class="coin-search-div" [hidden]="!foundResults.length>0">
        <ul class="list-group coin-search-list" >
            <li class="list-group-item pointer" *ngFor="let result of foundResults" (click)="selectCoin(result)">
                <img src="{{global.COIN_IMG_URL}}{{result.image}}" width="25" height="25" class="d-inline-block align-top mr-2 rounded" alt="">
                <span *ngIf="!showSymbolOnly" class="text-bold">{{result.type}} ({{result.display_symbol}})</span>
                <span *ngIf="showSymbolOnly" class="pl-1 text-bold">{{result.type}}</span>
                <span *ngIf="showTokenType && result.token_type != null"> - {{ result.token_type }}</span>
                <span *ngIf="showId" class="pull-right">#{{ result.id }}</span>
                <br/>
                <span *ngIf="showId" class="pl-4 ml-2 small text-muted"> 
                    <span *ngIf="result.contract_address != null">
                        Contract: {{ result.contract_address }} <span *ngIf="result.token_decimals != null" class="pl-2 pr-2">|</span>
                    </span>
                    <span *ngIf="result.token_decimals != null">
                        Decimals: {{ result.token_decimals }}
                    </span>
                </span>

            </li>
        </ul>
        <div class="more-results-div" *ngIf="foundResults.length>6">
            <i class="fa fa-caret-down text-center" aria-hidden="true"></i>
        </div>
    </div>        
</div>

<div *ngIf="coinSelected!=null" class="after-coin-select">

    <div class="selected-coin" *ngIf="!defaultSelectLoader">
        <img src="{{global.COIN_IMG_URL}}{{coinSelected.image}}" width="25" height="25" class="d-inline-block align-top mr-2 rounded" alt="">
        
        <span *ngIf="!showSymbolOnly">{{coinSelected.type}} ({{coinSelected.display_symbol}})</span>
        <span *ngIf="showSymbolOnly" class="pl-1">{{coinSelected.type}}</span>
        <span *ngIf="showTokenType && coinSelected.token_type != null"> - {{ coinSelected.token_type }}</span>
        <div class="float-right pointer" (click)="unselectCoin()" *ngIf="allowSelect">
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
    </div>

    <div class="selected-coin" *ngIf="defaultSelectLoader">
        <img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader.gif"> Loading
    </div>
    
</div>
