import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'app/_services/global.service';
import { SyncWarningPopup } from '../gateway-sync-warning-popup/gateway-sync-warning-popup.component';
import { TxCountWarningPopup } from '../tx-count-warinig-popup/tx-count-warning-popup.component';
import { concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
	selector: 'app-sync-option-popup',
	templateUrl: './sync-option-popup.component.html'
})
export class SyncOptionPopup implements OnInit {

    @Input() gatewayId = null;
    @Input() mode = null;
    @Input() type = 1;
    @Input() hasUpdated = false;

    public emitterValues = {
        gatewayId : null,
        mode : null,
        type : 1,
        retry: null,
		resyncFails: false,
		resyncIgnores:false,
		resyncUpdate: true,
        option: 1,
		startDate: null,
		endDate: null,
		all: false
    }

    public memo = null;
	public sendRequest = false;
    public txCountWarningRef;

    constructor(public activeModal: NgbActiveModal, public global: GlobalService,  public modalService: NgbModal) {
        
    }

    ngOnInit(): void {
        //throw new Error('Method not implemented.');
    }

    public goToSync(){
		this.syncqbo();
	}

    public syncqbo(){
		// this.defaultErpAccountRequired = false;
		if(this.global.canEditCurrentPortfolio()){

			// this.syncCount = null;
			// this.sync_ptr = null;

			// this.qbButtonStatus = -2;
			let gateway_id = this.gatewayId;
			let gatewayConnectionId = null;
			for(let i in this.global.connectedGateways){
				
				if(this.global.connectedGateways[i].id == this.gatewayId){
					gatewayConnectionId = this.global.connectedGateways[i].connection.connection_id;
					break;
				}
			}
            this.sendRequest = true;
				this.global.httpPOST('get_to_sync_tx_count',{
                    portfolioId: this.global.currentPortfolioId,
                    gatewayId: this.gatewayId,
                    calculation_id: this.global.taxId,
                    type: this.type,
                    reSyncUpdated: this.emitterValues.resyncUpdate,
                    resyncIgnored: this.emitterValues.resyncIgnores,
				    resyncFailed: this.emitterValues.resyncFails,

			}).pipe( concatMap(res=>{
                console.log( res);
                if(res.data >= 30000){
                    this.txCountWarningRef = this.modalService.open(TxCountWarningPopup);
                    this.txCountWarningRef.componentInstance.gatewayId = this.gatewayId;
                    this.txCountWarningRef.componentInstance.txCount = res.data;
                    this.txCountWarningRef.result.then( res => {
                        this.activeModal.close(false);
                
                    });
                    return of(false);
                }else{
                    return this.global.httpPOST('create_gateway_sync_request',{
                        portfolioId : this.global.currentPortfolioId,
                        portfolio_tax_id: this.global.taxId,
                        gateway_id,
                        gatewayConnectionId,
                        type: this.type,
                        mode:this.mode,
                        memo: this.memo,
                        resyncIgnored: this.emitterValues.resyncIgnores,
                        resyncFailed: this.emitterValues.resyncFails,
                        reSyncUpdates: this.emitterValues.resyncUpdate,
                        retry: this.emitterValues.retry,
                    });
                }
  
            })).subscribe( res=>{
                //console.log(res);
                if(res !== false){
                    this.activeModal.close({'success':true, data: res});
                }else{
                    this.activeModal.close(false);
                }
                
      
            } ,error=>{
                if(gateway_id == 1){
                    this.global.gatewaySyncStatus.qb = 4;
                }else if(gateway_id == 2){
                    this.global.gatewaySyncStatus.xero = 4;
                }else if(gateway_id == 3){
                    this.global.gatewaySyncStatus.netSuite = 4;
                }

                let undoResponseState = {
                    gatewayId: gateway_id,
                    mode: this.emitterValues.mode,
                    type: 1,
                    code: 500,
                    mesage: "failed"
                };

                this.activeModal.close({'success':false, data: undoResponseState});
            } ,()=>{
                this.sendRequest = false;
            })
		}
	}
    
}