

<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
      <form class="col-12">
            <div class="row">
                <div class="col-md-6 offset-md-3 text-center">
                    <br/><br/>
                    <br/>
                    <img class="img-fluid" width="200px" src="/assets/img/logo/logo.png"  alt="">
                    <br/>
                    <br/>
                    <br/>
                    <img width="100px" class="img-fluid" src="/assets/img/ons.png"  alt="">
                    
                    <div class="mt-4">
                        <div *ngIf="status==0 || status==1">
                            <h3 *ngIf="!showError">
                                <img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="30"  class="img-fluid d-inline-block align-top text-center" alt=""> 
                                Finalizing Connection
                            </h3>
                        </div>
    
                        <div *ngIf="status==2">
                            <h3>
                                Connection Success
                            </h3>
                        </div>
    
                        <div *ngIf="status==3">
                            <h3>
                                <span class="text-danger">Error! Redirecting...</span> 
                            </h3>
                        </div>
                    </div>

                </div>
            </div>
      </form>   
     
  </div>  
</div>