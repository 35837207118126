<span style="float:right" ngbDropdown class="nav-item active float_right pl-2 nodropcaret ml-1" placement="bottom-right" *ngIf="createDisplayMode == 0">
	<div class="modal-backdrop fade show" *ngIf="global.isdropdownOpen==4"
		(click)="changeBulkActions(0);global.dropdownOpenClose(0);"></div>
		<button (click)="global.dropdownOpenClose(4)" [class.upfront]="global.isdropdownOpen==4" type="button"
			class="btn btn-hr-min btn-sm ml-0" style="float:right" ngbDropdownToggle placement="bottom">
			<i class="fa fa-ellipsis-v" aria-hidden="true"></i>
		</button>
	<ng-container>
		<ul aria-labelledby="dropdownMenu2" ngbDropdownMenu [class.upfront]="global.isdropdownOpen==4">
			<li class="dropdown-item pointer"
				(click)="global.dropdownOpenClose(0);openFullModal(bulkImportAddressBooks)"> Bulk Import
			</li>
			<li class="dropdown-item pointer"
				(click)="global.dropdownOpenClose(0);openModal(bulkdoExportHistory)"> Bulk Export
			</li>
			<hr class="m-0"/>
			<li class="dropdown-item pointer"
				(click)="global.dropdownOpenClose(0);openFullModal(bulkImportHistory);loadImportHistory()">
				Import History
			</li>
			<li class="dropdown-item pointer"
				(click)="global.dropdownOpenClose(0);openFullModal(bulkExportHistory);loadExportHistory()">
				Export History
			</li>
			<hr class="m-0"/>
		</ul>
	</ng-container>

</span>


<div class="btn-group pull-right nodropcaret" ngbDropdown placement="bottom-right">
	<button type="button" class="btn btn-primary" style="float:right" (click)="createAddressBookEntry(addContactInfo)" *ngIf="createDisplayMode == 0"><i class="fa fa-plus"></i> 
		Add Address
	</button>
	<div class="modal-backdrop fade show nodropcaret" *ngIf="global.isdropdownOpen==5"
		(click)="global.dropdownOpenClose(0);"></div>
		<button *ngIf="createDisplayMode == 0" class="btn btn-primary nodropcaret" type="button" (click)="global.dropdownOpenClose(5)" ngbDropdownToggle placement="bottom-right">
			<i aria-hidden="true" class="fa fa-solid fa-chevron-down"></i>
		</button>
	<ng-container>
		<ul aria-labelledby="dropdownMenu5" ngbDropdownMenu [class.upfront]="global.isdropdownOpen==5">
			<li class="dropdown-item pointer"
				(click)="global.dropdownOpenClose(0);openAddGroupModal(addAddressGroup)">
				<i class="mr-2 fa fa-plus text-primary"></i>Add Group
			</li>
		</ul>
	</ng-container>
</div>

<li class="dropdown-item pointer" (click)="triggetAddressEditing(addContactInfo)" *ngIf="createDisplayMode == 1">
	<i class="mr-2 fa fa-pencil text-primary"></i>Edit
</li>
<li class="dropdown-item pointer" (click)="triggerAddressAdding(addAddressModal)" *ngIf="createDisplayMode == 1">
	<i class="mr-2 fa fa-plus text-primary"></i>Add Address
</li>

<button class="btn btn-block btn-hr-min" (click)="createAddressBookEntry(addContactInfo, false)"
	*ngIf="createDisplayMode == 2"> Create New Contact </button>
<button class="btn btn-block btn-hr-min" (click)="updateAddressBookEntry(updateContactModal)"
	*ngIf="createDisplayMode == 3"> Update Existing Contact </button>

<ng-template #addContactInfo let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title pull-left" *ngIf="addressBookModState==0">Add Address</h4>
		<h4 class="modal-title pull-left" *ngIf="addressBookModState==1">Edit Address</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" *ngIf="(addressBookModState==0) || addressBookModState==1">

		<ngb-alert *ngIf="errorCode==1" [type]="'warning'" (close)="closeSubAlert()">
			Please enter a name.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==2" [type]="'warning'" (close)="closeSubAlert()">
			Please select cryptocurrency.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==3" [type]="'warning'" (close)="closeSubAlert()">
			Please enter an address.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==4" [type]="'warning'" (close)="closeSubAlert()">
			Please enter the type of this entry.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==5" [type]="'danger'" (close)="closeSubAlert()">
			Please re-check the cryptocurrency and the address. The address doesn't seem to match the selected
			cryptocurrency.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==6" [type]="'warning'" (close)="closeSubAlert()">
			You already have this address in your address book.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==8" [type]="'warning'" (close)="closeSubAlert()">
			Invalid email address.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==7" [type]="'warning'" (close)="closeSubAlert()">
			Invalid telephone number.
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==9" [type]="'warning'" (close)="closeSubAlert()">
			Invalid entity selection.
		</ngb-alert>

		<div class="row">
			<div class="col">
				<div class="form-group">
					<label for="sel1">Contact Name <small>*</small></label>
					<input type="text" [(ngModel)]="addressDetails['name']" name="addressName" class="form-control"
						id="addressName" placeholder="Contact Name (eg: XYZ Payment, John Smith etc.)">
				</div>		
			</div>
			<div class="col">
				<div class="form-group" *ngIf="cryptoAddState">
					<label for="sel1">Select the cryptocurrency</label>
					<app-coin-search [type]="1" [emmiterType]="2" (coinTypeIdSet)="setCoinTypeId($event)"></app-coin-search>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<div class="form-group " *ngIf="addressBookModState==0">
					<label for="sel1">Enter the address <small>*</small></label>
					<input type="text" [(ngModel)]="addressDetails['address']" name="cryptoAddress" class="form-control"
						id="cryptoAddress" placeholder="1F5ZSFA....">
	
				</div>
			</div>
			<div class="col">
				<div class="form-group" *ngIf="global._PRODUCT_ID == 'EP'">
					<label for="sel1">Address Type</label>
						<select class="custom-select" [(ngModel)]="addressDetails['type']">
							<option value="null" disabled>Select...</option>
							<option value="1">Personal</option>
							<option value="2">Organizational</option>
							<option value="3">Entity</option>
						</select>
				</div>
				<div class="form-group" *ngIf="addressDetails['type'] == 3 && addressDetails['portfolioId'] == null">
					<input type="text" name="label" class="form-control"
						id="label" placeholder="Choose a portfolio"
						(click)="openPortfolioModal(selectPortfolioModal, false)">
				</div>
				<div class="form-group" *ngIf="addressDetails['type'] == 3 && addressDetails['portfolioId'] != null">
					<div class="after-coin-select mb-2">
						<div class="selected-coin">
							<table class="table table-tx mb-0 table-nowrap card-table pt-2">
								<tbody>
									<tr class="pointer">
										<td class="border-right">
											{{ addressDetails['portfolioName'] }}
										</td>
										<td class="padding:7px !important">
											<div class="float-right pointer"
												(click)="addressDetails['portfolioId']=null;addressDetails['portfolioName']=null;">
												<i class="fa fa-times" aria-hidden="true"></i>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			
		</div>

		<ng-container *ngIf="global.addressGroups.length > 0">
			<hr />
			<div class="card bg-light">
				<div class="card-body">
					<div class="form-group" *ngIf="global.addressGroups.length > 0">
						<label for="sel1">Address Groups <small>(optional)</small></label>
							<div class="input-group my-2">
								<div class="input-group-prepend ">
									<span class="input-group-text bg-white fc-mini pr-0" id=""><i class="fa fa-search" aria-hidden="true"></i></span>
								</div>
								<input class="form-control bl-0 br-0 fc-mini" matInput [(ngModel)]="groupSearchValue" (ngModelChange)="applyQuickSearchGroups()" placeholder="Quick search">
				
								<div class="input-group-append">
									<span class="input-group-text bg-white fc-mini">
										<i class="fa fa-times  pointer" (click)="resetQuickSearchGroups()" [class.text-primary]="groupSearchValue != null" [class.text-white]="groupSearchValue == null" aria-hidden="true" ></i>
									</span>
								</div>
							</div>
							<div class="table-scrollable h-25">
								<table class="table card-table pt-2">
									<thead>
										<tr>
											<th>Name</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr class="pointer bg-white" *ngFor="let group of searchResults; let i = index" [class.tr-select]="group.members.includes(addressDetails.id)" (click)="assignGroupToAddress(group.id, i, addressDetails.id)">
											<td>
												<a class="" *ngIf="group.group_name!=null"><span>{{group.group_name}} </span></a>
												<a *ngIf="group.group_name==null"><small><i>not named</i></small></a>
											</td>
											<td class="padding:7px !important">
												<span *ngIf="!group.members.includes(addressDetails.id)" class="text-white"><i class="fa fa-check" aria-hidden="true"></i></span>
												<span *ngIf="group.members.includes(addressDetails.id)" class="text-primary"><i class="fa fa-check" aria-hidden="true"></i></span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
					</div>
				</div>
			</div>
		</ng-container>
		<hr />

		<div class="row m-2">
		<a class="text-primary pointer" *ngIf="!showAdvancedContactOptions" (click)="showAdvancedContactOptions = true">Show Advanced Options</a>
		<a class="text-primary pointer" *ngIf="showAdvancedContactOptions" (click)="showAdvancedContactOptions = false">Hide Advanced Options</a>
		</div>
		<ng-container *ngIf="showAdvancedContactOptions">
			<div class="row">
				<div class="col">
					<div class="form-group">
						<label for="sel1">Email</label>
						<input type="text" [(ngModel)]="addressDetails['email']" name="emailAddress" class="form-control"
							id="emailAddress" placeholder="name@domain.com">
					</div>
				</div>
				<div class="col">
					<div class="form-group">
						<label for="sel1">Tel</label>
						<form #f="ngForm" [formGroup]="phoneForm">
							<ngx-intl-tel-input [cssClass]="'form-control'"
								[preferredCountries]="global.allowedTelCountries" [enablePlaceholder]="true"
								[enableAutoCountrySelect]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
						</form>
						<span></span>
					</div>
				</div>
			</div>


			<div class="form-group">
				<textarea type="text" maxlength="5000" [(ngModel)]="addressDetails['notes']" name="addressNotes"
					class="form-control" id="addressNotes" aria-describedby="Contact Notes" placeholder="Notes">
				</textarea>
			</div>
		</ng-container>
	</div>
	

	<div class="modal-footer" *ngIf="addressBookModState==0">
		<button *ngIf="addingAddress==false" type="button" class="btn btn-primary btn-lg btn-block"
			(click)="createAddress()">
			Add Address
		</button>
		<button *ngIf="addingAddress==true" type="button" class="btn btn-primary btn-lg btn-block disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Add Address
		</button>
	</div>

	<div class="modal-footer" *ngIf="addressBookModState==1">
		<button *ngIf="editingAddress==false" type="button" class="btn btn-primary btn-lg btn-block"
			(click)="saveAddressChanges()">Save Changes</button>
		<button *ngIf="editingAddress==true" type="button" class="btn btn-primary btn-lg btn-block" [disabled]>
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Save Changes
		</button>
	</div>

</ng-template>

<ng-template #addAddressModal let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left">Add Address</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">

		<ngb-alert *ngIf="errorCode==3" [type]="'warning'" (close)="closeSubAlert()">
			Please enter an address
		</ngb-alert>

		<ngb-alert *ngIf="errorCode==6" [type]="'warning'" (close)="closeSubAlert()">
			You already have this address in this contact.
		</ngb-alert>

		<div class="form-group" *ngIf="cryptoInfo == null">
			<label for="sel1">Select the cryptocurrency <small>(optional)</small></label>
			<app-coin-search [type]="1" [emmiterType]="2" (coinTypeIdSet)="setCoinTypeIdForAddress($event)">
			</app-coin-search>
			<small>Cryptoworth does not check for the validy of the address you will enter below.</small>
		</div>
		<ng-container *ngIf="cryptoInfo != null">

			<app-address-contact-info [addressInfo]="addressDetails"></app-address-contact-info>
			<app-coin-search [defaultSelect]="global.addressBookInfoCryptoView[0]" [allowSelect]="false">
			</app-coin-search>
		</ng-container>

		<div class="form-group mt-4">
			<label for="sel1">Label</label>
			<input type="text" [(ngModel)]="additionalAddress['label']" name="addressName" class="form-control"
				id="addressName" placeholder="Address label">
		</div>

		<div class="form-group ">
			<label for="sel1">Enter the address <small>*</small></label>
			<input type="text" [(ngModel)]="additionalAddress['address']"
				[class.is-invalid]="!additionalAddressValidation" (ngModelChange)="additionalAddressValidation = true"
				name="cryptoAddress" class="form-control" id="cryptoAddress" placeholder="1F5ZSFA....">
			<p *ngIf="!additionalAddressValidation" class="text-danger">Please enter an address</p>
		</div>

	</div>

	<div class="modal-footer">
		<button *ngIf="!addingAdditionalAddress" type="button" class="btn btn-primary btn-lg btn-block"
			(click)="addAddress()">
			Add Address
		</button>
		<button *ngIf="addingAdditionalAddress" type="button" class="btn btn-primary btn-lg btn-block disabled"
			disabled>
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Add Address
		</button>
	</div>

</ng-template>

<ng-template #updateContactModal let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left">Update Existing Contact Address</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">

		<ng-container *ngIf="updateContactStatus == 0">
			<app-address-list (selectedAddressEmit)="createAddressBookEntryPreSetup($event, addAddressModal)"></app-address-list>
		</ng-container>

	</div>

	<div class="modal-footer">
		<button *ngIf="!addingAdditionalAddress" type="button" class="btn btn-primary btn-lg btn-block"
			(click)="addAddress()">
			Add Address
		</button>
		<button *ngIf="addingAdditionalAddress" type="button" class="btn btn-primary btn-lg btn-block disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader.gif"> Add Address
		</button>
	</div>

</ng-template>

<ng-template #bulkImportAddressBooks let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left">Bulk Address Import</h4>
		<button type="button" class="close pull-right" aria-label="Close"
			(click)="d('Cross click');closeImportAddressModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">

		<div class="container">
			<div class="row pt-4 mt-2">
				<div class="col-md-6 border-right">
					<div class="" *ngIf="importAddress.importState==0">
						<div class="form-group">
							<label for="sel1">
								Attach CSV
								<span *ngIf="importAddress.importFileTypeError" class="text-danger"><i
										class="fa fa-times" aria-hidden="true"></i> Invalid CSV</span>
							</label>
							<div class="form-group">
								<!-- <input class="btn btn-primary" type="file"  /> -->
								<div class="input-group pointer">
									<div class="custom-file pointer">
										<input class="pointer" style="cursor: pointer !important;" type="file"
											value="Attach CSV" #fileUpload id="fileUpload" name="fileUpload"
											(change)="uploadFile($event)" class="custom-file-input"
											id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
										<label class="custom-file-label pointer" for="inputGroupFile01"
											[class.rebd]="importAddress.importFileTypeError">
											<span *ngIf="importAddress.uploadEvent==null">Choose your CSV file</span>
											<span *ngIf="importAddress.uploadEvent!=null">
												<span *ngIf="!importAddress.importFileTypeError"><i
														class="fa fa-file-excel-o" aria-hidden="true"></i>
													{{importAddress.uploadEvent.target.files[0].name}}</span>
												<span *ngIf="importAddress.importFileTypeError" class="text-danger"><i
														class="fa fa-times" aria-hidden="true"></i>
													{{importAddress.uploadEvent.target.files[0].name}}</span>
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center" *ngIf="importAddress.importState==1">
						<img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="150"
							class="img-fluid d-inline-block align-top text-center" alt="">
						<h3>Uploading CSV File...</h3>
					</div>
					<div class="text-center" *ngIf="importAddress.importState==2">
						<img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="150"
							class="img-fluid d-inline-block align-top text-center" alt="">
						<h5 class="mb-0 pb-0 text-success">Uploading Complete <i class="fa fa-check"
								aria-hidden="true"></i></h5>
						<h3>Submitting Import...</h3>
					</div>
					<div class="text-center" *ngIf="importAddress.importState==3">
						<h2 class="fade-in text-success"><i class="fa fa-check" aria-hidden="true"></i> Upload Complete</h2>
						<p>Your CSV was uploaded successfully. <br />
							We are syncing the wallets into your portfolio. <br />
						</p>
					</div>
					<div class="text-center" *ngIf="importAddress.importState==4">
						<h2 class="text-danger"><i class="fa fa-times" aria-hidden="true"></i> Upload Failed</h2>
						<p>This operation failed. <br />
							Please check if your using a valid CSV file. <br />
							Retry importing with a valid CSV file
						</p>
						<br />
						<div *ngFor="let error of importAddress.errorList">
							<p class="text-danger mb 0" style="font-size: 1.1em; font-weight: 500; text-align: left; margin-bottom: 0;">
								<i class="fa fa-times" aria-hidden="true"></i> {{error.key}}</p>
							<p class="text-danger ml 2" style="font-size: 1em; font-weight: 300; text-align: left; margin-left: 1.1em;">
								{{error.description}}</p>
						</div>
					</div>
					<br />


					<button
						*ngIf="!importAddress.walletsImported && importAddress.importState==0 && this.importAddress.uploadEvent != null"
						type="button" class="float-right btn btn-primary btn-lg" (click)="importAddressBook()">
						Import
					</button>
					<button *ngIf="this.importAddress.uploadEvent == null" type="button"
						class="float-right btn btn-primary btn-lg disabled" [disabled]>
						Import
					</button>
					<button *ngIf="importAddress.importState==1" type="button"
						class="float-right btn btn-primary btn-lg disabled" [disabled]>
						<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Uploading CSV File
					</button>

					<button *ngIf="importAddress.importState==2" type="button"
						class="float-right btn btn-primary btn-lg disabled" [disabled]>
						<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Submitting Import
					</button>

					<button *ngIf="importAddress.importState==3 || importAddress.importState==4" type="button"
						class="float-right btn btn-primary btn-lg text-center"
						(click)="d('Cross click'); closeImportAddressModal()">
						Close
					</button>
					<a *ngIf="global.appData != null && global.appSettings.assistance_links" class="mr-3 float-right mt-1" href="{{global.appData.tutorials.bulk_contact_import.link}}" target="_blank">Need Assistance?</a>
				</div>

				<div class="col-md-6">
					<button class="btn btn-primary mb-4" (click)="openLargeModal(searchCurrencyModal, false)">
						<i class="fa fa-search ml-2 mr-2" aria-hidden="true" style="font-size: 18px;"></i> Search for assets
					</button>
					<p>Please note that data should be imported using a CSV file.</p>
					<p>Download the template: <a class="text-primary"
							href="https://cw-templates-all.s3.us-east-2.amazonaws.com/address_book_import_template.csv">address_book_import_template.csv</a>

					<p class="text-bold">Instructions</p>
					<ol>
						<li>
							<p>
								Download the <a class="text-primary"
									href="https://cw-templates-all.s3.us-east-2.amazonaws.com/address_book_import_template.csv">address_book_import_template.csv</a>
								file.
							</p>
						</li>
						<li>
							<p>
								Add the addresses in the given format.
							</p>
						</li>
						<li>
							<p>
								Upload the file.
							</p>
						</li>
					</ol>
				</div>
			</div>
		</div>

	</div>


</ng-template>


<ng-template #addAddressGroup let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title pull-left" >Add Group</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">

		<ngb-alert *ngIf="errorCode==1" [type]="'warning'" (close)="closeSubAlert()">
			Please enter a group name
		</ngb-alert>

		<div class="form-group">
			<label for="sel1">Group Name <small>*</small></label>
			<input type="text" [(ngModel)]="addressGroupDetails['name']" name="groupName" class="form-control"
				id="groupName" placeholder="Group Name (e.g. Invoice Addresses)">
		</div>

		<div class="form-group">
			<label for="sel1">Group Description</label>
			<textarea type="text" maxlength="5000" [(ngModel)]="addressGroupDetails['desc']" name="groupDescription"
				class="form-control" id="groupDescription" aria-describedby="Group Notes" placeholder="Description">
			</textarea>
		</div>

		<div class="form-group" *ngIf="global._PRODUCT_ID == 'EP'">
			<label for="sel1">Group Type</label>
				<select class="custom-select" [(ngModel)]="addressGroupDetails['type']">
					<option value="null" disabled>Select...</option>
					<option value="0">Personal</option>
					<option value="1">Organizational</option>
					<option value="3">Entity</option>
				</select>
		</div>
		<div class="form-group" *ngIf="addressGroupDetails['type'] == 3 && addressGroupDetails['portfolioId'] == null">
			<input type="text" name="label" class="form-control"
				id="label" placeholder="Choose a portfolio"
				(click)="openPortfolioModal(selectPortfolioModal, true)">
		</div>
		<div class="form-group" *ngIf="addressGroupDetails['type'] == 3 && addressGroupDetails['portfolioId'] != null">
			<div class="after-coin-select mb-2">
				<div class="selected-coin">
					<table class="table table-tx mb-0 table-nowrap card-table pt-2">
						<tbody>
							<tr class="pointer">
								<td class="border-right">
									{{ addressGroupDetails['portfolioName'] }}
								</td>
								<td class="padding:7px !important">
									<div class="float-right pointer"
										(click)="addressGroupDetails['portfolioId']=null;addressGroupDetails['portfolioName']=null;">
										<i class="fa fa-times" aria-hidden="true"></i>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<hr/>

		<div class="card  bg-light">
			<div class="card-body">
				<div class="form-group">
					<label for="sel1">Group Member Addresses</label>
						<div class="input-group my-2">
							<div class="input-group-prepend ">
								<span class="input-group-text bg-white fc-mini pr-0" id=""><i class="fa fa-search" aria-hidden="true"></i></span>
							</div>
							<input class="form-control bl-0 br-0 fc-mini" matInput [(ngModel)]="addressSearchValue" (ngModelChange)="applyQuickSearchAddress()" placeholder="Quick search">
			
							<div class="input-group-append">
								<span class="input-group-text bg-white fc-mini">
									<i class="fa fa-times  pointer" (click)="resetQuickSearchAddress()" [class.text-primary]="addressSearchValue != null && addressSearchValue != ''" [class.text-white]="addressSearchValue == null || addressSearchValue == ''" aria-hidden="true" ></i>
								</span>
							</div>
						</div>
						<div class="table-scrollable">
							
							<table class="table card-table pt-2" *ngIf="global.addresses != null">
								<thead>
									<tr>
										<th>Name</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<tr class="pointer bg-white" *ngFor="let address of global.searchResultsAddress; let i = index" [class.tr-select]="isGroupAddressSelected(address.id)" (click)="selectGroupAddress(address.id)">
										<td>
											<a class="" *ngIf="address.name!=null"><span>{{address.name}}</span></a>
											<a *ngIf="address.name==null"><small><i>not named</i></small></a>
										</td>
										<td class="padding:7px !important">
											<span *ngIf="!isGroupAddressSelected(address.id)" class="text-white"><i class="fa fa-check" aria-hidden="true"></i></span>
											<span *ngIf="isGroupAddressSelected(address.id)" class="text-primary"><i class="fa fa-check" aria-hidden="true"></i></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
				</div>

			</div>
		</div>

	</div>

	<div class="modal-footer">
		<ng-container>
			<button *ngIf="dataloading == false" type="button" class="btn btn-primary btn-lg btn-block"
				(click)="createAddressGroup()">
				Add Group
			</button>
			<button *ngIf="dataloading == true" type="button" class="btn btn-primary btn-lg btn-block disabled">
				<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Add Group
			</button>
		</ng-container>
	</div>

</ng-template>

<ng-template #rollbackMessageModal let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left">Rollback Address Book Import</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click');closeModal();">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">

		<p> Are you sure you want to rollback this address book import?</p>
		<p>Rolling back this import will delete all the adress book records for this import.</p>

		<div>
			<span>
				{{ currentRollbackLot.created_at | amDateFormat:'MMM D, Y' }}
			</span>
		</div>
		<div>
			<span>
				{{currentRollbackLot.user.first_name}}
				{{currentRollbackLot.user.last_name}}
				<small>({{currentRollbackLot.user.email}})</small>
			</span>
		</div>
		<div>
			<span>{{currentRollbackLot.original_file_name}}</span>
		</div>

	</div>

	<div class="modal-body" *ngIf="rollbackState==2">

		<p> Import successfully rolled back.</p>

		<div>
			<span>
				{{ currentRollbackLot.created_at | amDateFormat:'MMM D, Y' }}
			</span>
		</div>
		<div>
			<span>
				{{currentRollbackLot.user.first_name}}
				{{currentRollbackLot.user.last_name}}
				<small>({{currentRollbackLot.user.email}})</small>
			</span>
		</div>
		<div>
			<span>{{currentRollbackLot.file_name}}</span>
		</div>

	</div>

	<div class="modal-body" *ngIf="rollbackState==3">

		<p> Address book import rolledback failed. Please contact the administrators.</p>

		<div>
			<span>
				{{ currentRollbackLot.created_at | amDateFormat:'MMM D, Y' }}
			</span>
		</div>
		<div>
			<span>
				{{currentRollbackLot.user.first_name}}
				{{currentRollbackLot.user.last_name}}
				<small>({{currentRollbackLot.user.email}})</small>
			</span>
		</div>
		<div>
			<span>{{currentRollbackLot.file_name}}</span>
		</div>

	</div>

	<div class="modal-footer">

		<button *ngIf="rollbackState == 0" type="button" class="btn btn-danger btn-lg" (click)="confirmRollBack()">
			<i class="fa fa-undo" aria-hidden="true"></i> Rollback
		</button>
		<button *ngIf="rollbackState == 1" type="button" class="btn btn-danger btn-lg disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Rollback
		</button>
		<button *ngIf="rollbackState == 2 || rollback == 3" type="button" class="btn btn-hr-min btn-lg"
			(click)="closeModal();">
			Close
		</button>
	</div>

</ng-template>

<ng-template #searchCurrencyModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<app-coin-search [type]="1" [showId]="true" [allowSelect]="false"></app-coin-search>

	</div>

</ng-template>

<ng-template #selectPortfolioModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="list-group" id="list-tab" role="tablist">
			<table class="table table-tx table-nowrap card-table pt-2"
				*ngIf="global.portfolios.length>=0">
				<thead>
					<tr>
						<th>Name</th>
						<th *ngIf="global.isBusinessAccount()">Type</th>
						<th>Currency</th>
						<th>Date Created</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr class="pointer"
						*ngFor="let portfolio of global.portfolios;let i = index"
						(click)="selectAddressPortfolio(portfolio.id, portfolio.name);d('Cross click')">
						<td>
							<span class="text-primary" *ngIf="portfolio.name!=null"
								ngbTooltip="{{portfolio.name}}"><span>{{ global.shortenNameDisplay(portfolio.name) }}</span></span>
							<span *ngIf="portfolio.name==null"><small><i>not named</i></small></span>
						</td>
						<td *ngIf="global.isBusinessAccount()">
							<span *ngIf="portfolio.company_id==0">Private</span>
							<span *ngIf="portfolio.company_id!=0">Company</span>
						</td>
						<td>
							<span>
								<img src="{{global.CDN_IMG_URL}}countries/c_flags/{{portfolio['portfolio_currency']['code']}}_r.png" width="16" height="16" class="d-inline-block align-top" alt="" style="margin-top: 5px; "> 
								<span> ({{portfolio['portfolio_currency']['code']}})</span>
							</span>
						</td>
						<td>
							<span *ngIf="portfolio.created_at!=null">{{ portfolio.created_at | amDateFormat:'LL' }}</span>
							<span *ngIf="portfolio.created_at==null"><small><i>--</i></small></span>
						</td>
						<td class="padding:7px !important" *ngIf="!addingPortfolioToGroup">
							<span *ngIf="addressDetails.portfolioId != portfolio.id" class="text-white"><i class="fa fa-check" aria-hidden="true"></i></span>
							<span *ngIf="addressDetails.portfolioId == portfolio.id" class="text-primary"><i class="fa fa-check" aria-hidden="true"></i></span>
						</td>
						<td class="padding:7px !important" *ngIf="addingPortfolioToGroup">
							<span *ngIf="addressGroupDetails.portfolioId != portfolio.id" class="text-white"><i class="fa fa-check" aria-hidden="true"></i></span>
							<span *ngIf="addressGroupDetails.portfolioId == portfolio.id" class="text-primary"><i class="fa fa-check" aria-hidden="true"></i></span>
						</td>
					</tr>
					
				</tbody>
			</table>

		</div>

	</div>

</ng-template>

<ng-template #bulkImportHistory let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left">Address Import History</h4>
		<button type="button" class="close pull-right" aria-label="Close"
			(click)="d('Cross click');closeImportAddressModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">

		<div class="container">
			<div class="card">
				<table class="table table-tx table-nowrap card-table pt-2">
					<thead>
						<tr>
							<th>Date</th>
							<th>User</th>
							<th>File</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody *ngIf="importHistory!=null && importHistory.length > 0">
						<tr class="pointer" *ngFor="let history of importHistory;let i = index">
							<td>
								<span>
									{{ history.created_at | amDateFormat:'MMM D, Y' }}
								</span>
							</td>
							<td>
								<span ngbTooltip="({{history.user.email}})">
									{{history.user.first_name}}
									{{history.user.last_name}}
								</span>
							</td>
							<td>
								<span ngbTooltip="{{history.original_file_name}}">
									{{global.parseFileName(history.file_name) }}
								</span>
							</td>
							<td>
								<span *ngIf="history['status']==2" class="fade-in text-success"><i class="fa fa-check"
										aria-hidden="true"></i> Success</span>

								<span *ngIf="history['status']==1" class="text-primary">

									<img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="20"
										class="img-fluid d-inline-block align-top text-center" alt="">
									Importing
								</span>

								<span *ngIf="history['status']==0" class="text"><i class="fa fa-clock-o"
										aria-hidden="true"></i> Queued</span>


								<span *ngIf="history['status']==3" class="text-danger"> <i class="fa fa-times"
										aria-hidden="true"></i> Failed</span>


								<span *ngIf="history['status']==-1" class="text-grey"> <i class="fa fa-arrow-left"
										aria-hidden="true"></i> Rolled Back</span>
							</td>
							<td class="padding:7px !important">
								<button *ngIf="history['status']==2 && history.rollback_user_id==null"
									(click)="triggerRollBack(rollbackMessageModal, history)"
									class="btn btn-danger btn-sm"><i class="fa fa-undo" aria-hidden="true"></i>
									Rollback</button>
							</td>
						</tr>
					</tbody>

					<button *ngIf="importHistory!=null && showLoadMore && !dataLoading" class="btn btn-primary ml-2"
						(click)="loadImportHistory()"> Load More</button>
					<button *ngIf="importHistory!=null && dataLoading" class="btn btn-primary ml-2 disabled" disabled>
						<img src='{{global.FE_IMG_URL}}img/payment/loader_w.gif' width="20"
							class="img-fluid d-inline-block align-top text-center" alt=""> Loading More </button>


					<tbody *ngIf="importHistory==null">
						<tr *ngFor="let loader of [1,2,3,4,5]">
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
						</tr>
					</tbody>

				</table>

				<div class="pl-4  pb-2 pt-4 mb-2" *ngIf="importHistory!=null && importHistory.length == 0">
					<div class="row">
						<div class="col-12 text-center">
							You have no imports
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>


</ng-template>


<ng-template #bulkExportHistory let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left">Bulk Export Address Book</h4>
		<button type="button" class="close pull-right" aria-label="Close"
			(click)="d('Cross click');closeImportAddressModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">

		<div class="container">
			<div class="card">
				<table class="table table-tx table-nowrap card-table pt-2">
					<thead>
						<tr>
							<th>Date</th>
							<th>User</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody *ngIf="exportHistory!=null && exportHistory.length > 0">
						<tr class="pointer" *ngFor="let history of exportHistory;let i = index">
							<td>
								<span>
									{{ history.created_at | amDateFormat:'MMM D, Y' }}
								</span>
							</td>
							<td>
								<span ngbTooltip="({{history.user.email}})">
									{{history.user.first_name}}
									{{history.user.last_name}}
								</span>
							</td>
							<td>
								<span *ngIf="history['status']==2" class="fade-in text-success">
									<i class="fa fa-check" aria-hidden="true"></i> Success
								</span>

								<span *ngIf="history['status']==1" class="text-primary">
									<img src='{{global.FE_IMG_URL}}img/payment/loader.gif' width="20" class="img-fluid d-inline-block align-top text-center" alt=""> Exporting
								</span>

								<span *ngIf="history['status']==0" class="text"> 
									<i class="fa fa-clock-o" aria-hidden="true"></i> Queued
								</span>

								<span *ngIf="history['status']==3" class="text-danger"> 
									<i class="fa fa-times"aria-hidden="true"></i> Failed
								</span>

							</td>
						</tr>
					</tbody>

					<button *ngIf="exportHistory!=null && showLoadMore && !dataLoading" class="btn btn-primary ml-2"
						(click)="loadExportHistory()"> Load More</button>
					<button *ngIf="exportHistory!=null && dataLoading" class="btn btn-primary ml-2 disabled" disabled>
						<img src='{{global.FE_IMG_URL}}img/payment/loader_w.gif' width="20"
							class="img-fluid d-inline-block align-top text-center" alt=""> Loading More </button>

					<tbody *ngIf="exportHistory==null">
						<tr *ngFor="let loader of [1,2,3,4,5]">
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
							<td>
								<div class="animated-background-tbl"></div>
							</td>
						</tr>
					</tbody>

				</table>

				<div class="pl-4  pb-2 pt-4 mb-2" *ngIf="exportHistory!=null && exportHistory.length == 0">
					<div class="row">
						<div class="col-12 text-center">
							You have no exports
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>


</ng-template>


<ng-template #bulkdoExportHistory let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h4 class="modal-title pull-left">Bulk Export Address Book</h4>
		<button type="button" class="close pull-right" aria-label="Close"
			(click)="d('Cross click');closeImportAddressModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">

		<ng-container *ngIf="doingAddressBookExport == 0 || doingAddressBookExport == 1">
			<p>
				Are you sure you want to export your address book? Cryptoworth will export all the addresses to a CSV. 
			</p>
		</ng-container>
		<ng-container *ngIf="doingAddressBookExport == 2">
			<p>
				Address book export job is queued. You can view the job status in the Address Book <span class="text-primary pointer" (click)="closeModal();openModal(bulkExportHistory);loadExportHistory()">Export History</span>.
			</p>
		</ng-container>

	</div>
	<div class="modal-footer" *ngIf="doingAddressBookExport != 3 && doingAddressBookExport != 2">
		<button *ngIf="doingAddressBookExport == 0" type="button" class="btn btn-primary btn-lg"
			(click)="doAddressBookExport()">
			Export Address Book
		</button>
		<button *ngIf="doingAddressBookExport == 1" type="button" class="btn btn-primary btn-lg disabled">
			<img class="loader-icon" src="{{global.FE_IMG_URL}}img/payment/loader_w.gif"> Export Address Book
		</button>
	</div>


</ng-template>