import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { GlobalService } from '../../_services/global.service';
import { CoinService } from '../../_services/coin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
	selector: 'app-asset-page',
	templateUrl: './asset-page.component.html'
})
export class AssetPageComponent implements OnInit  {

	constructor (public global: GlobalService, public coinService:CoinService, public route:ActivatedRoute, public modalService: NgbModal, 
		private renderer: Renderer2) {
	}

	ngOnInit() {
		window.scroll(0, 0);
	}


}
