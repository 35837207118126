import { Injectable, Output, EventEmitter   } from '@angular/core';
import { Http, RequestOptions, Headers} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '.././global.service';
import { CoinService } from '.././coin.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common'
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { setTimeout } from 'timers';
import { transcode } from 'buffer';
import { response } from 'express';


@Injectable()
export class ConnectionService {

  	constructor(private global: GlobalService, public coinService: CoinService) {
			
	}
    
    currentViewWalletInfo= null
    walletType= null
    coinDetails= null
	currencyChangeSubscription: any;
	coinCurrencyFetchSubscription: any = null;	

    public getWailletInfoEmtter : EventEmitter<true> = new EventEmitter();
  
	loadingWalletInfo = false
	getWalletInfo(viewWallet = null){
		this.loadingWalletInfo = true
		this.global.currentWalletMode = 1
		let viewWalletNow = null

		if(viewWallet == null){
			viewWalletNow = this.global.currentWalletViewId
		} else {
			viewWalletNow = viewWallet
		}
		
		this.global.httpGET('get_wallet', {id:viewWalletNow, walletType:this.global.currentWalletMode})
		.subscribe(response => {
			this.loadingWalletInfo = false
			this.currentViewWalletInfo = response.data
			this.currentViewWalletInfo['formattedDate'] = moment(this.currentViewWalletInfo['created_at']).format('D MMM, YYYY')
			this.getWailletInfoEmtter.emit(response.data);
			
		}, error => {
			this.loadingWalletInfo = false
			console.log(error);// Error getting the data
		});
	}

    loadWalletDetails(){
		if(this.walletType==0){
			if(this.currentViewWalletInfo['parent_wallet_id']==null){
				this.coinService.getWalletDetails(this.currentViewWalletInfo, false, true)
				this.coinCurrencyFetchSubscription = this.coinService.coinCurrencyPriceFetch.subscribe(response => {
					if(response){
						this.getCoinHistoricalData('hour')
						this.coinService.aggregateCoinDetailedInfo.forEach(element => {
							if(element.symbol == this.currentViewWalletInfo['symbol']){
								this.coinDetails = element
							}
						});
						this.coinCurrencyFetchSubscription.unsubscribe()
					}
				});
			} else {
				this.getCoinHistoricalData('hour')
				this.coinService.getPriceInUserCurrency(this.currentViewWalletInfo)
				this.coinService.aggregateCoinDetailedInfo.forEach(element => {
					if(element.symbol == this.currentViewWalletInfo['symbol']){
						this.coinDetails = element
					}
				});
			}
			

		} else {
			this.getCoinHistoricalData('hour')
			this.coinService.getPriceInUserCurrency(this.currentViewWalletInfo)
			this.coinService.aggregateCoinDetailedInfo.forEach(element => {
				if(element.symbol == this.currentViewWalletInfo['symbol']){
					this.coinDetails = element
				}
			});
		}
	}

    showGraph = false
    lineChartData = null
    lineChartLabels = null

	getCoinHistoricalData(type, bypassHide=false){
		if(!bypassHide)
			this.showGraph = false

		this.coinService.getCoinHistoricalData(type, this.currentViewWalletInfo.symbol).subscribe(data => {
			this.showGraph = true
			let _lineChartData:Array<any> = []
			let _lineChartLabels:Array<any> = []
			let i=1;
			data.Data.forEach(element => {
				
					if(type=='week' || type=='month'){
						_lineChartLabels.push(String(moment.unix(element.time).format("MMM Do YY")))
					} else if(type=='3months' || type=='6months'){
						let conversionTimeStamp = String(moment.unix(element.time).format("MMM"))
						_lineChartLabels.push(conversionTimeStamp)
					} else if(type=='year'){
						let conversionTimeStamp = String(moment.unix(element.time).format("MMM"))
						_lineChartLabels.push(conversionTimeStamp)
					} else {
						_lineChartLabels.push(String(moment.unix(element.time).format("LT")))
					}

					let value = +element.close * +this.currentViewWalletInfo['amount']
					_lineChartData.push(+value)
			});

			this.lineChartData = _lineChartData
			this.lineChartLabels = _lineChartLabels
			
		})
	
	}

}