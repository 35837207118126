import { Injectable } from '@angular/core';
import { BehaviorSubject, of} from 'rxjs';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpUserEvent
    
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take ,map, subscribeOn } from 'rxjs/operators';
import { Http, } from '@angular/http';
import { AuthenticationService } from '../_services/authentication/authentication.service';
import { LoaderService } from '../_services/loader/loader.service';

@Injectable()
export class CWAEInterceptor implements HttpInterceptor {

    public requestsFailed = []
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    
    constructor(
        public authService:AuthenticationService,
        public http:Http,
        public loaderService:LoaderService
        ) {
        //console.log('loaded interceptor')
    }

    //function which will be called for all http calls
    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }})
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        this.loaderService.showLoader();
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.loaderService.hideLoader()
                }
                return event;
            }),
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse) {

                    switch ((<HttpErrorResponse>error).status) {

                        case 400:{
                            //return this.handle401Error(request, next)
                        } case 401:{
                            return this.handle401Error(request, next);
                        } case 500:{
                            //this.authService.show500Notice = true
                        }

                    }
                } else {
                    return Observable.throw(error);
                }
                return throwError(error);
            })
        );
    }


    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            this.tokenSubject.next(null);

            return this.authService.refreshTokens().pipe(switchMap(response => {
                if (response) {
                    response = JSON.parse(response['_body'])
                    this.authService._cookieService.set('tokenData', JSON.stringify(response), null,'/')
                    this.authService.TOKENS['access_token'] = response['access_token']
                    this.authService.TOKENS['refresh_token'] = response['refresh_token']
  

                    this.tokenSubject.next(response['access_token']);
                    request = request.clone({
                        setHeaders: {Authorization: 'Bearer ' + this.authService.TOKENS['access_token']}
                    });

                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                this.loaderService.hideLoader()
                            }
                            return event;
                        }))
                }
            }),
            catchError(err => {
                this.authService.logout();
                this.loaderService.hideLoader()
                return throwError(err.error);
            }),
            finalize(() => {
                this.isRefreshingToken = false;
            }));
        }  else {

                return this.tokenSubject.pipe( filter((token) => token != null),take(1),
                switchMap(token => {
                    request = request.clone({
                        setHeaders: {Authorization: 'Bearer ' + this.authService.TOKENS['access_token']}
                    });
                    
                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                this.loaderService.hideLoader()
                            }
                            return event;
                        }))
                })
                )
        }
    }


}