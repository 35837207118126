import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { GlobalService } from '../_services/global.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params} from '@angular/router';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { RequestsService } from '../_services/requests.service';
import { AuthService, GoogleLoginProvider } from "angularx-social-login";
import { XeroService } from 'app/_services/gateways/xero.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

	public loginDetails={}
	public errorStatus = 0

	private recoverEmail
	private recoverState = false
	public recovering = false
	private recoverURLState = 0
	private recoverHash = null
	private newPassword
	private newPasswordAgain
	public resetting = false
	private formState = 0
	private contact_number_ending;
	public keepLoggedIn = false

	public aFormGroup: FormGroup;
	
	public socialAuthSubscription;
	public loginProviders = {
		'APP': 0,
		'GOOGLE': 1
	};

	@ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;

	constructor(
			public global: GlobalService, 
			private route:ActivatedRoute, 
			private router:Router, 
			private formBuilder: FormBuilder,
			private renderer: Renderer2,
			private authService: AuthService,
			private requestService: RequestsService,
			private xeroService: XeroService
		) {
		this.global.loginDivState = route.snapshot.data['divState'];
	}
	
	ngOnInit() {
		//this.renderer.addClass(document.body, 'bg-bg-light');
		this.aFormGroup = this.formBuilder.group({
			recaptcha: ['', Validators.required]
		});
		this.socialAuthSubscription = this.authService.authState.subscribe((user) => {
			this.global.socialUser = user;
			if(user !== null){
				//console.log(user);
				this.socialLogginEvent();
			}
		});
		// reset global variables
		this.global.guestViewMode = 'main'
		if(this.global.isAuthenticated()){
			console.log('is authenticated')
			//this.router.navigate(['home']);
			this.global.doLoginPreRequisites()
		} else {
			this.global._wallets = null
			this.global._exchangesConnected = null
			this.global._virtualWallets = null
			this.global._companyDetails = null
			this.global._packageDetails = null
			this.global._packagePolicies = null
			this.global.hb_packagePurcahseDetails = null
			this.global.authService.TOKENS = null
			this.global._userDetails = null
			this.global._currency ={
				code:'USD',
				symbol:'$'
			}
			this.global._authStatus = false

			this.global.captchaIsLoaded = true;
			window.scroll(0, 0);
			this.global.titleService.setTitle(this.global.appName + ' - Login');


			this.formState = 0
			if(this.global.isAuthenticated()){
				this.router.navigate(['home']);
				this.global.doLoginPreRequisites()
			} else {
				this.global.logginOutStatus = 0
			}

			
			if(this.global.loginDivState == 4){
				this.route.params.subscribe((params: Params) => {
					this.recoverHash = params['key']
					// check if the hash exists
					this.global.http.post(this.global.API_URL + 'check_recover_hash', {
							hash: params['key']
						}
					)
					.subscribe(response => {
						response = JSON.parse(response['_body'])
						if(response['resp_status']==true){
							this.recoverURLState = 2
						} else if(response['resp_status']==false){
							this.recoverURLState = 1
							this.errorStatus = response['code']
						}

					}, error => {
						console.log(error);// Error getting the data
					});
					//if it exists, show the password reset options, otherwise show an error message.
				});
				
			}
		}
		
		
	}

	async socialLogginEvent(){
		try{
			this.global.socialUser['p'] = this.global._PRODUCT_ID 
			let resp = await this.requestService.socialLogin(this.global.socialUser);
			

			if(resp.resp_status && resp.resp_status !== false){
				if(resp.code==401){
					this.errorStatus = 401;
				}else{
					this.global.keepUserLoggedIn = true;
					this.global.doLoginPreRequisites(resp.data);
				}

			} else {
				if (resp['code']==100){
					// product not accessible
					this.errorStatus = 100
					this.global.logout()
				}
			}
		}catch( err){
			console.error(err);
		}
	}

	public loginWithXero(){
		let url = this.xeroService.getLoginUrl(null, 2);
		window.open(url,"_self");
	}

	loginWithSocialite(provider:number){
		
		try{
			let token = this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
		} catch( error) {
			console.error(error);
		}
		
	}

	public reLoginDetails = {
		email:null,
		oldPassword:null,
		newPassword:null,
		newPasswordAgain:null,
	}

	reLoginWithPasswordChange(){
		this.formState = 1
		this.global.http.post(this.global.API_URL + 'pw_reset_force_login', {
				reLoginDetails: this.reLoginDetails,
				deviceInfo: this.global.deviceInfo,
				ipInfo:this.global.ipInfo,
				p:this.global._PRODUCT_ID
			}
		)
		.subscribe(response => {
			response = JSON.parse(response['_body'])
			if(response['resp_status'] == true){
				this.loginDetails['password'] = this.reLoginDetails['newPassword']
				this.executeLoginAfterCaptcha()
			} else {
				this.formState = 0
			} 

		}, error => {
			this.resetCaptcha()
			this.formState = 0
			console.log(error);// Error getting the data
		});
	}
		
	login(){
		this.errorStatus = 0;
		if(this.global.loginDivState == 202 && (this.loginDetails['twofa_code'] < 100000 || this.loginDetails['twofa_code'] > 999999 || this.loginDetails['twofa_code']==null)) {			
			this.errorStatus = 203;

			console.log('hit 3')
		} else if(
			this.loginDetails['email'] != null && 
			this.loginDetails['password'] != null 
		){
			try{

				if(
					this.global.authService.ENV_SET == 'dev' || (
						this.global.authService.ENV_SET != 'dev' &&
						(
							this.loginDetails['email'] == 'zac.hogg@solana.org' ||
							this.loginDetails['email'] == 'rpasquin@cryptoworth.app'
						)
					)
				){
					this.formState = 1
					this.executeLoginAfterCaptcha()
				} else {
					this.captchaElem.resetCaptcha();
					console.log('hit 2')
					this.formState = 1
					this.captchaElem.execute();

				}

			}catch(error){
				this.errorStatus = 212
			}

		} else {
			console.log('hit')
			this.errorStatus = 211
		}
		console.log("end of loging")
	}

	makeUserLoggedIn(){
		// if(this.keepLoggedIn){
		// 	this.global.keepUserLoggedIn = false
		// } else {
		// 	this.global.keepUserLoggedIn = true
		// }

		this.global.keepUserLoggedIn = true
	}

	executeLoginAfterCaptcha(){
		//this.makeUserLoggedIn()
		this.global.http.post(this.global.API_URL + 'login', {
				loginDetails: this.loginDetails,
				deviceInfo: this.global.deviceInfo,
				ipInfo:this.global.ipInfo,
				p:this.global._PRODUCT_ID
			}
		)
		.subscribe(response => {
			response = JSON.parse(response['_body'])
			if(response['resp_status'] == true){
				if(response['code'] == 202) {
					this.errorStatus = 0
					//2 Factor Authentication
					this.formState = 0;
					this.global.loginDivState = 202;
					this.contact_number_ending = response['contact_number_ending'];
				} else if(response['code'] == 201){
					this.errorStatus = 0
					//2 Factor Authentication
					this.formState = 0;
					this.global.loginDivState = 201;
					this.reLoginDetails.email = this.loginDetails['email']
					this.reLoginDetails.oldPassword = this.loginDetails['password']
				} else
					this.global.doLoginPreRequisites(response['data'])
			} else {
				this.formState = 0
				this.resetCaptcha()
				if(response['code']==201){
					// invalid email password combo
					this.errorStatus = 201
					this.loginDetails['password'] = null
				} else if(response['code'] == 203) {
					// wrong 2 Factor Auth Code
					this.errorStatus = 203;						
				} else if(response['code'] == 206) {
					//Account Disabled
					this.errorStatus = 206;
				}
				else if (response['code']==404){
					// email not found
					this.errorStatus = 404
				} else if (response['code']==666){
					// the account is suspended
					this.errorStatus = 0
					this.global.loginDivState = 666
					this.global._isAdmin = response['data']['isAdmin']
					this.global._isOwner = response['data']['isOwner']
					this.global._packageDetails = response['data']['packageDetails']
					this.global._packagePurchaseDetails = response['data']['packagePurchase']	
					this.global._packagePolicies = response['data']['packageDetails']['policies']
					this.global._userDetails = response['data']['userDetails']

					this.global.loginTempDetails = this.loginDetails;
				} else if (response['code']==100){
					// product not accessible
					this.errorStatus = 100
				}
			} 

		}, error => {
			this.resetCaptcha()
			this.formState = 0
			console.log(error);// Error getting the data
		});
	}
		
	resetCaptcha(): void {
		this.captchaElem.resetCaptcha();
	}

	recoverPasswordState(){
		this.global.loginDivState = 3
	}

	doRecoverEmail(){
		if(
			this.recoverEmail != null 
		){
			console.log('called 1')
			this.captchaElem.execute();
		} else {
			this.errorStatus = 204
			this.captchaElem.resetCaptcha();
		}
	}

	executeAction($event){
		console.log('triggering captcha')
		if(this.global.loginDivState==3){
			console.log('called')
			this.executeRecoverPasswordAfterCaptcha()
		} else if(this.global.loginDivState==0 || this.global.loginDivState==202) {
			this.executeLoginAfterCaptcha()
		}	
	}

	executeRecoverPasswordAfterCaptcha(){
		console.log('execute')
		this.recovering = true
		this.global.http.post(this.global.API_URL + 'recover', {
				email: this.recoverEmail
			}
		)
		.subscribe(response => {
			this.recovering = false
			response = JSON.parse(response['_body'])
			if(response['resp_status']==true){
				this.recoverState = true
			} else if(response['resp_status']==false){
				this.captchaElem.resetCaptcha();
				this.errorStatus = response['code']
			}

		}, error => {
			this.captchaElem.resetCaptcha();
			console.log(error);// Error getting the data
		});
	}

	resetPassword(){
		if(
			this.newPassword != null && 
			this.newPasswordAgain != null 
		){
			if(this.newPassword == this.newPasswordAgain){
				this.resetting = true
				this.global.http.post(this.global.API_URL + 'reset_password', {
						newPassword: this.newPassword,
						hash: this.recoverHash
					}
				)
				.subscribe(response => {
					this.resetting = false
					response = JSON.parse(response['_body'])
					if(response['resp_status']==true){
						this.recoverURLState = 3
						//this.global.doLoginPreRequisites(response['data'])
					} else if(response['resp_status']==false){
						//this.errorStatus = response['code']
					}

				}, error => {
					console.log(error);// Error getting the data
				});
			} else {
				this.errorStatus = 208
				this.newPassword = null 
				this.newPasswordAgain = null 
			}
			
		} else {
			this.errorStatus = 207
		}
	}

	loginFormModalChange(){
		this.errorStatus = 0
	}

	twoFAresend = false
	twoFACounter = 0
	TwoFAResend(send_through) {
		this.twoFAresend = true
		if(this.twoFACounter < 2){
			this.global.http.post(this.global.API_URL + 'two_fa_resend', { email: this.loginDetails['email'], send_through: send_through })
			.subscribe(response => {	
				this.twoFACounter++
				this.twoFAresend = false
	
				if(send_through == 'phone'){
					this.errorStatus = 204;
				} else {
					this.errorStatus = 205;
				}
	
			}, error => {
				this.errorStatus = 206;
				this.twoFAresend = false
			});
		}

	}

	ngOnDestroy(){
		this.socialAuthSubscription.unsubscribe();
		this.renderer.removeClass(document.body, 'bg-bg-light');
	}


}
