import { Injectable } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { GlobalService } from '../global.service';
import { CoinService } from '../coin.service';


@Injectable()
export class PaymentsService {

    supportedCoins = [];
    supportedFiat = [];
	dashboardInfo = null

	invoiceFetchType = 0

	public accountsLoading = false

  public billsEnables = true;
  public invoicesEnables = true;

  public billsAndInvoicesSort ={
    billsDates: 'DESC',
    invoicesDate:'DESC',
  }
  public billsAndInvoicesSortEnables: {
    invoicesDate: boolean,
    billsDate: boolean,
  }


  constructor(
		private global: GlobalService,
		private coinService: CoinService,
		private fb: FormBuilder
	) {

	}


	public invoicesList = []
	public invoicesListLoading = false
	public invoiceLoading = false
	public invoicePaginationSize = 50
	public showLoadMore = false
	public loadingLoadMore = false


	public billsList = []
	public billsListLoading = false
	public billLoading = false
	public billsPaginationSize = 100
	public billsFetchType = 0
	public showLoadMoreBills = false
	public billsLoadingLoadMore = false

	public loadingInvoiceCount = false

	// update invoice
	public loadingUpdateInvoice = false
	public updateInvoiceData = null;
	public updateInvoiceError = false;
	public updateInvoiceSuccess = false;

	updateInvoice(invoice){
		this.loadingUpdateInvoice = true;
		this.updateInvoiceError = false;
		return this.global.httpPOST('payments/update_invoice', {
			invoice
		}).subscribe(res => {
			this.loadingUpdateInvoice = false;
			const response = res.data;
			if(response){
				const index = this.invoicesList.findIndex(inv => inv.id === response.id);
				if (index !== -1) {
					const metaData = JSON.parse(response.meta);
					response.recipient_email = metaData.Contact.EmailAddress;
					this.invoicesList[index] = response;
					this.updateInvoiceSuccess = true;
				} else {
					console.error('Invoice not found in the list');
					this.updateInvoiceError = true;
				}
			}else{
				console.error("No response from update invoice");
				this.updateInvoiceError = true;
			}
		}, err => {
			this.loadingUpdateInvoice = false;
			this.updateInvoiceError = true;
		})
	}

	getInvoiceCount(mode = 0, walletId = 0){
		//get_invoices_count
		let currentTxId = null
		this.loadingInvoiceCount = true


		let currentPortfolioId = null
		if(this.global.currentPortfolioId != null){
			currentPortfolioId = this.global.currentPortfolioId

			if(this.global.currentTransactionDetails != null){
				currentTxId = this.global.currentTransactionDetails.id
			}
		}
		return this.global.httpGET('payments/get_invoices_count',{
			companyId:this.global._portfolioInfo['company_id'],
			walletId:walletId,
			type:this.invoiceFetchType,
			size:this.invoicePaginationSize,
			mode:mode,
			portfolioId: currentPortfolioId,
			transactionId: currentTxId
		})
	}

	getBillCount(mode = 0, walletId = 0){
		//get_invoices_count
		let currentTxId = null
		this.loadingInvoiceCount = true


		let currentPortfolioId = null
		if(this.global.currentPortfolioId != null){
			currentPortfolioId = this.global.currentPortfolioId

			if(this.global.currentTransactionDetails != null){
				currentTxId = this.global.currentTransactionDetails.id
			}
		}
		return this.global.httpGET('payments/get_bills_count',{
			companyId:this.global._portfolioInfo['company_id'],
			walletId:walletId,
			type:this.billsFetchType,
			size:this.billsPaginationSize,
			mode:mode,
			portfolioId: currentPortfolioId,
			transactionId: currentTxId
		})
	}

	public page = 1;

	// no longer used. Backend function may not be working
	searchInvoices(option, keyword){


		return this.global.httpPOST('payments/search_invoices',{
		portfolioId: this.global.currentPortfolioId,
		companyId:this.global._portfolioInfo['company_id'],
		option,
		keyword,
		size:this.invoicePaginationSize,
		page:this.page,
		})


	}
	getInvoices(pagination=false, hard = false, mode = 0, walletId = 0, keyword=null, option =0){

		let offset = this.invoicesList.length
		if(hard){
			offset = 0
		}

		this.loadingLoadMore = true
		if(!pagination)
			this.invoicesListLoading = true

		let currentTxId = null


		let currentPortfolioId = null
		if(this.global.currentPortfolioId != null){
			currentPortfolioId = this.global.currentPortfolioId

			if(this.global.currentTransactionDetails != null){
				currentTxId = this.global.currentTransactionDetails.id
			}
		}

		this.global.httpPOST('payments/get_invoices',{
				companyId:this.global._portfolioInfo['company_id'],
				walletId:walletId,
				type:this.invoiceFetchType,
				size:this.invoicePaginationSize,
				page:this.page,
        		sort:this.billsAndInvoicesSort.invoicesDate,
				mode:mode,
				portfolioId: currentPortfolioId,
				transactionId: currentTxId,
				keyword:keyword,
				option: option
			}).subscribe(response => {

				this.invoicesListLoading = false
				this.loadingLoadMore = false

				if(response.status==true){

					if(response.data.length < this.invoicePaginationSize){
						this.showLoadMore = false
					}

					if(pagination){
						response.data.forEach(element => {
							this.invoicesList.push(element)
						});
					} else {
						this.invoicesList = response.data
					}
				} else {
					this.invoicesListLoading = false
				}

		}, error => {
			this.invoicesListLoading = false
			this.loadingLoadMore = false
		});
	}




  public changeBillsAndInvoicesSortDate(sort, isCalc = false){
    this.billsAndInvoicesSort['billsDates'] = sort;
    this.billsAndInvoicesSort['invoicesDate'] = sort;

    if(!isCalc){
      this.getBills()
    } else {
      this.getBills()
    }
  }

  public changeInvoicesSortDate(sort, isCalc = false){
    this.billsAndInvoicesSort['billsDates'] = sort;
    this.billsAndInvoicesSort['invoicesDate'] = sort;


    if(!isCalc){
      this.getInvoices()
    } else {
      this.getInvoices()
    }
  }

  getBills(pagination=false, hard = false, mode = 0, keyword=null, option=0){
		console.log('bills fetching ' )

		let offset = this.invoicesList.length
		if(hard){
			offset = 0
		}

		this.billsLoadingLoadMore = true
		if(!pagination)
			this.billsListLoading = true

		let currentTxId = null


		let currentPortfolioId = null
		if(this.global.currentPortfolioId != null){
			currentPortfolioId = this.global.currentPortfolioId

			if(this.global.currentTransactionDetails != null){
				currentTxId = this.global.currentTransactionDetails.id
			}
		}

		this.global.httpPOST('payments/get_bills',{
				companyId:this.global._portfolioInfo['company_id'],
				type:this.billsFetchType,
				size:this.billsPaginationSize,
        		sort:this.billsAndInvoicesSort.billsDates,
				page:this.page,
				mode:mode,
				portfolioId: currentPortfolioId,
				transactionId: currentTxId,
        option:option,
        keyword:keyword
			}).subscribe(response => {

				this.billsListLoading = false
				this.billsLoadingLoadMore = false

				if(response.status==true){

					if(response.data.length < this.billsPaginationSize){
						this.showLoadMore = false
					}

					if(pagination){

						response.data.forEach(element => {
              const meta = JSON.parse(element.meta);
              element.meta = meta;
              element['Contact'] = meta.Contact;
							this.billsList.push(element)
						});
					} else {
            const arr = [];
            response.data.forEach(element => {
              const meta = JSON.parse(element.meta);
              element.meta = meta;
              element['Contact'] = meta.Contact
              arr.push(element)
            })
						this.billsList = arr;
					}
				} else {
					this.billsListLoading = false
				}
				console.log(this.billsList)
		}, error => {
			this.billsListLoading = false
			this.billsLoadingLoadMore = false
		});
	}

	resetInvoiceViews(){
		this.invoicesList = []
		this.invoicesListLoading = false
		this.invoiceLoading = false
		this.loadingLoadMore = false
		this.showLoadMore = false
	}


	resetBillsViews(){
		this.billsList = []
		this.billsListLoading = false
		this.billLoading = false
		this.loadingLoadMore = false
		this.showLoadMore = false
	}

	getSupportedCoins(){
        if(this.supportedCoins.length == 0){
			this.global.httpGET('payments/wallet_types').subscribe(response => {
				if(response.status==true){
					this.supportedCoins = response.data
					console.log(this.supportedCoins)
				} else {
				}
			}, error => {
			});
		}
    }

    getSupportedFiat(){
        if(this.supportedFiat.length == 0){
			this.global.httpGET('payments/supported_fiat').subscribe(response => {
				if(response.status==true){
					this.supportedFiat = response.data
					console.log(this.supportedFiat)
				} else {
				}
			}, error => {
			});
		}
    }

    getSupportedWallets(force = false){
        if(this.coinService.supportedWallets.length == 0 || force){
			this.accountsLoading = true
			this.global.httpGET('payments/get_supported_wallets', {companyId:this.global._portfolioInfo.company_id, portfolio_id:this.global.currentPortfolioId}).subscribe(response => {
				this.accountsLoading = false
				if(response.status==true){
					this.coinService.supportedWallets = response.data
					//this.getCCACGPriceResponse(response.data)
				} else {
				}
			}, error => {
				this.accountsLoading = false
			});
		}
	}

	loadWalletDetails(){
		this.coinService.supportedWallets.forEach(element => {
			element.type_id = element.token_type
			this.coinService.getWalletDetails(element, false, false, false, element.contract_address, this.global._currency['code'])
		});
	}

	getCCACGPriceResponse(response){

		let wallets = response['wallets']
		let uniqueString = response['uniqueList']

		this.global.httpAPIGET('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+ uniqueString +'&tsyms='+ this.global._currency['code'] + '&api_key=' + this.global.getCCAPIKey()).subscribe(response => {
			this.coinService.userCoinAssetPriceCCAG = response
			this.loadWalletDetails()
		})
	}


}
