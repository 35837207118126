
import { Component } from '@angular/core';
import { GlobalService } from '../../_services/global.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})

export class ActivateComponent { 

	public key

	public pageStatus = 0

	constructor(public global: GlobalService, public route:ActivatedRoute) {  }

	ngOnInit(){

		this.route.params.subscribe((params: Params) => {
			this.key = params['key']
			console.log(this.key)

			this.global.httpGETNoAuth('activate', {key:this.key})
			.subscribe(response => {

				if(response.resp_status){
					this.pageStatus = 1

				} else {
					this.pageStatus = 2
				}

			})
		})
	}
}

